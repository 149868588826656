import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Link,
  Box,
  CircularProgress,
  IconButton,
  Grid,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PrimarySearchAppBar from "../NavBar2/NavBar2";
import Footer from "../Footer/Footer2";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import servicePath from "../Constant/defaultvalues";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

// Login Component / Webpage

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [error, setError] = useState({ username: "", password: "" });
  const navigate = useNavigate(); // For navigation
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleForgotPass = () => {
    navigate("/forgotpass");
  };

  // const handleLogIn = async () => {
  //   // Clear previous errors
  //   setError({ username: "", password: "" });

  //   // Generate a random tab ID and store it in session storage
  //   const tabId = Math.floor(Math.random() * 1000000);
  //   sessionStorage.setItem("tabId", tabId);
  //   console.log("Session Tab ID:", sessionStorage.getItem("tabId"));

  //   try {
  //     let hasError = false;

  //     // Check if both username and password fields are empty
  //     if (!username) {
  //       setError((prevError) => ({
  //         ...prevError,
  //         username: "Email ID is required",
  //       }));
  //       hasError = true;
  //     }

  //     // Check if password field is empty
  //     if (!password) {
  //       setError((prevError) => ({
  //         ...prevError,
  //         password: "Password is required",
  //       }));
  //       hasError = true;
  //     }

  //     if (hasError) return;

  //     // Set loading states for UI feedback
  //     setIsLoading(true);

  //     // console.log("Sending credentials:", { username, password });

  //     // Send login request to the server with username and password
  //     const response = await axios.post(
  //       `${servicePath}/advoreflogin`,
  //       {
  //         username,
  //         password,
  //       },
  //       {
  //         headers: { tabid: tabId }, // Include the tab ID in the request headers
  //       }
  //     );

  //     // Extract token and user details from the response
  //     const { token, userDetails } = response.data;

  //     // Store the user ID and subscription token in local storage
  //     localStorage.setItem("admin_id", userDetails.id);
  //     localStorage.setItem("subscriptionToken", token);

  //     // Fetch profile details
  //     const profileResponse = await axios.get(`${servicePath}/profiledetails`, {
  //       headers: {
  //         admin_id: userDetails.id,
  //       },
  //     });

  //     const profileData = profileResponse.data;

  //     // Check if the profile data is an empty array
  //     if (Array.isArray(profileData) && profileData.length === 0) {
  //       // Open the Snackbar with the message
  //       setOpenSnackbar(true);

  //       navigate("/profileform");
  //       // Navigate to '/profileform' if the profile data is empty
  //       return;
  //     }

  //     // Reset loading states
  //     setIsLoading(false);

  //     // Show success message and navigate to the main page
  //     navigate("/home");
  //   } catch (error) {
  //     // Log the error message for debugging
  //     console.error("Login fail:", error.response?.data || error.message);

  //     // Get a user-friendly error message from the server response or use a default message
  //     const errorMessage =
  //       error.response?.data?.message ||
  //       "Login failed. Please check your credentials.";

  //     if (errorMessage.toLowerCase().includes("invalid credential")) {
  //       setError((prevError) => ({
  //         username: "",
  //         password: "Invalid Credential",
  //       }));
  //     } else if (errorMessage.includes("Plan has expired")) {
  //       setError((prevError) => ({
  //         password: "Plan has expired. Get a new plan.",
  //       }));
  //     }

  //     // Reset loading states
  //     setIsLoading(false);
  //   }
  // };

  const handleLogIn = async () => {
    // Clear previous errors (if you still want to use error state for any reason)
    setError({ username: "", password: "" });
  
    // Generate a random tab ID and store it in session storage
    const tabId = Math.floor(Math.random() * 1000000);
    sessionStorage.setItem("tabId", tabId);
    console.log("Session Tab ID:", sessionStorage.getItem("tabId"));
  
    try {
      let hasError = false;
  
      // Check if both username and password fields are empty
      if (!username) {
        toast.error("Email ID is required");
        hasError = true;
      }
  
      // Check if password field is empty
      if (!password) {
        toast.error("Password is required");
        hasError = true;
      }
  
      if (hasError) return;
  
      // Set loading states for UI feedback
      setIsLoading(true);
  
      // Send login request to the server with username and password
      const response = await axios.post(
        `${servicePath}/advoreflogin`,
        {
          username,
          password,
        },
        {
          headers: { tabid: tabId }, // Include the tab ID in the request headers
        }
      );
  
      // Extract token and user details from the response
      const { token, userDetails } = response.data;
  
      // Store the user ID and subscription token in local storage
      localStorage.setItem("admin_id", userDetails.id);
      localStorage.setItem("subscriptionToken", token);
  
      // Fetch profile details
      const profileResponse = await axios.get(`${servicePath}/profiledetails`, {
        headers: {
          admin_id: userDetails.id,
        },
      });
  
      const profileData = profileResponse.data;
  
      // Check if the profile data is an empty array
      if (Array.isArray(profileData) && profileData.length === 0) {
        // Open the Snackbar with the message
        setOpenSnackbar(true);
  
        navigate("/profileform");
        // Navigate to '/profileform' if the profile data is empty
        return;
      }
  
      // Reset loading states
      setIsLoading(false);
  
      // Show success message and navigate to the main page
      navigate("/home");
    } catch (error) {
      // Log the error message for debugging
      console.error("Login fail:", error.response?.data || error.message);
  
      // Get a user-friendly error message from the server response or use a default message
      const errorMessage =
        error.response?.data?.message ||
        "Login failed. Please check your credentials.";
  
      if (errorMessage.toLowerCase().includes("invalid credential")) {
        toast.error("Invalid Credential");
      } else if (errorMessage.includes("plan has expired")) {
        toast.error("Plan has expired. Get a new plan.");
      } else if (errorMessage.toLowerCase().includes("email id doesn't exist")) {
        toast.error("Email ID doesn't exist");
      } else {
        toast.error(errorMessage);
      }
  
      // Reset loading states
      setIsLoading(false);
    }
  };

  const inputFieldStyles = {
    width: "80%", // adjust the width as needed
    fontSize: "14px", // smaller font size for input text
    "& .MuiInputBase-root": {
      height: "35px", // adjust the height of the input field
      padding: "0 0px", // adjust padding inside the input field
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)", // default border color
    },
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="info"
          sx={{
            backgroundColor: "blue",
            color: "white",
            fontSize: "18px", // Set font size to large
            width: "100%", // Ensure the alert covers the Snackbar
            textAlign: "center",
          }}
        >
          Please complete your profile
        </Alert>
      </Snackbar>

      <PrimarySearchAppBar />
      {/* <Container component="main" maxWidth="xs" sx={{ flexGrow: 1 }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="60vh"
        >
          <Typography
            sx={{
              fontSize: "25px",
              fontWeight: 600,
              mb: 1,
              lineHeight: "80px",
            }}
          >
            Welcome to Advoref
          </Typography>

          <Box sx={{ width: "100%", mb: 2, marginLeft: "40px" }}>
            <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
              Email Address
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              error={!!error.password && !error.username} // Show red border if there is an error in password and it's an invalid credential case
              sx={inputFieldStyles}
            />
          </Box>

          <Box sx={{ width: "100%", mb: 2, marginLeft: "40px" }}>
            <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
              Password
            </Typography>
            <TextField
              fullWidth
              type={showPassword ? "text" : "password"}
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!error.password}
              helperText={error.password}
              sx={inputFieldStyles}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                    aria-label="toggle password visibility"
                    style={{ marginRight: "2px" }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
          </Box>

          <Box sx={{ width: "100%", textAlign: "left", marginLeft: "40px" }}>
            <Button
              variant="contained"
              sx={{
                width: "140px",
                height: "44px",
                borderRadius: "6px",
                mt: 3,
                mb: 2,
                background: "#0E1030",
                "&:hover": {
                  backgroundColor: "#0E1030",
                },
              }}
              onClick={handleLogIn}
              disabled={isLoadingButton}
            >
              {isLoadingButton ? "Logging in..." : "Login"}
            </Button>
          </Box>

          <Box sx={{ width: "100%", textAlign: "left", marginLeft: "40px" }}>
            <Typography sx={{ fontSize: "12px", fontWeight: 400, mt: 2 }}>
              Forgot Password
              <Link
                // href="/forgotpass"
                onClick={handleForgotPass}
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  ml: 1,
                  cursor: "pointer",
                }}
              >
                Reset Here
              </Link>
            </Typography>
          </Box>
        </Box>
      </Container> */}
      <Container component="main" maxWidth="xs" sx={{ flexGrow: 1 }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="60vh"
        >
          <Typography
            sx={{
              fontSize: "25px",
              fontWeight: 600,
              mb: 1,
              lineHeight: "80px",
              textAlign: "center",
              marginLeft: "-80px",
              "@media (max-width: 425px)": {
                marginLeft: "20px",
              },
            }}
          >
            Welcome to Advoref
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
                Email Address
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                error={!!error.password && !error.username}
                sx={inputFieldStyles}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
                Password
              </Typography>
              <TextField
                fullWidth
                type={showPassword ? "text" : "password"}
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={!!error.password}
                helperText={error.password}
                sx={inputFieldStyles}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                      aria-label="toggle password visibility"
                      style={{ marginRight: "2px" }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Box sx={{ width: "100%", textAlign: "left" }}>
            <Button
              variant="contained"
              sx={{
                width: "140px",
                height: "44px",
                borderRadius: "6px",
                mt: 3,
                mb: 2,
                background: "#0E1030",
                "&:hover": {
                  backgroundColor: "#0E1030",
                },
              }}
              onClick={handleLogIn}
              disabled={isLoadingButton}
            >
              {isLoadingButton ? "Logging in..." : "Login"}
            </Button>
          </Box>

          <Box sx={{ width: "100%", textAlign: "left" }}>
            <Typography sx={{ fontSize: "12px", fontWeight: 400, mt: 2 }}>
              Forgot Password
              <Link
                onClick={handleForgotPass}
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  ml: 1,
                  cursor: "pointer",
                }}
              >
                Reset Here
              </Link>
            </Typography>
          </Box>
        </Box>
      </Container>
      <Footer />

      {isLoading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 1000,
          }}
        >
          <CircularProgress size={80} />
        </Box>
      )}
    </Box>
  );
};

export default Login;



// import React, { useState, useEffect } from "react";
// import {
//   Container,
//   Typography,
//   TextField,
//   Button,
//   Link,
//   Box,
//   CircularProgress,
//   IconButton,
//   Grid,
// } from "@mui/material";
// import { Visibility, VisibilityOff } from "@mui/icons-material";
// import PrimarySearchAppBar from "../NavBar2/NavBar2";
// import Footer from "../Footer/Footer2";
// import axios from "axios";
// import Snackbar from "@mui/material/Snackbar";
// import Alert from "@mui/material/Alert";
// import { useNavigate } from "react-router-dom";
// import servicePath from "../Constant/defaultvalues";
// import { Turnstile } from "@marsidev/react-turnstile";

// const Login = () => {
//   const [username, setUsername] = useState("");
//   const [password, setPassword] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const [isLoadingButton, setIsLoadingButton] = useState(false);
//   const [error, setError] = useState({ username: "", password: "" });
//   const navigate = useNavigate(); // For navigation
//   const [openSnackbar, setOpenSnackbar] = useState(false);

//   const [captchaToken, setCaptchaToken] = useState('');

//   useEffect(() => {
//     // Dynamically load Turnstile script
//     const script = document.createElement('script');
//     script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback';
//     script.async = true;
//     script.defer = true;
//     document.body.appendChild(script);

//     // Define the global onload callback
//     window.onloadTurnstileCallback = () => {
//       console.log('Turnstile loaded successfully');
//     };

//     // Cleanup function to remove the script when the component unmounts
//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   const handleTurnstileCallback = (token) => {
//     setCaptchaToken(token);
//     console.log('CAPTCHA token:', token);
//   };

//   const [showPassword, setShowPassword] = useState(false);

//   const handleTogglePasswordVisibility = () => {
//     setShowPassword((prev) => !prev);
//   };

//   const handleForgotPass = () => {
//     navigate("/forgotpass");
//   };

//   const handleLogIn = async () => {

//     // if (!captchaToken) {
//     //   alert('Please complete the CAPTCHA');
//     //   return;
//     // }

//     // Clear previous errors
//     setError({ username: "", password: "" });

//     // Generate a random tab ID and store it in session storage
//     const tabId = Math.floor(Math.random() * 1000000);
//     sessionStorage.setItem("tabId", tabId);
//     console.log("Session Tab ID:", sessionStorage.getItem("tabId"));

//     try {
//       let hasError = false;

//       // Check if both username and password fields are empty
//       if (!username) {
//         setError((prevError) => ({
//           ...prevError,
//           username: "Email ID is required",
//         }));
//         hasError = true;
//       }

//       // Check if password field is empty
//       if (!password) {
//         setError((prevError) => ({
//           ...prevError,
//           password: "Password is required",
//         }));
//         hasError = true;
//       }

//       if (hasError) return;

//       // Set loading states for UI feedback
//       setIsLoading(true);

//       // console.log("Sending credentials:", { username, password });

//       // Send login request to the server with username and password
//       const response = await axios.post(
//         `${servicePath}/advoreflogin`,
//         {
//           username,
//           password,
//           captchaToken,
//         },
//         {
//           headers: { tabid: tabId }, // Include the tab ID in the request headers
//         }
//       );

//       // Extract token and user details from the response
//       const { token, userDetails } = response.data;

//       // Store the user ID and subscription token in local storage
//       localStorage.setItem("admin_id", userDetails.id);
//       localStorage.setItem("subscriptionToken", token);

//       // Fetch profile details
//       const profileResponse = await axios.get(`${servicePath}/profiledetails`, {
//         headers: {
//           admin_id: userDetails.id,
//         },
//       });

//       const profileData = profileResponse.data;

//       // Check if the profile data is an empty array
//       if (Array.isArray(profileData) && profileData.length === 0) {
//         // Open the Snackbar with the message
//         setOpenSnackbar(true);

//         navigate("/profileform");
//         // Navigate to '/profileform' if the profile data is empty
//         return;
//       }

//       // Reset loading states
//       setIsLoading(false);

//       // Show success message and navigate to the main page
//       navigate("/home");
//     } catch (error) {
//       // Log the error message for debugging
//       console.error("Login fail:", error.response?.data || error.message);

//       // Get a user-friendly error message from the server response or use a default message
//       const errorMessage =
//         error.response?.data?.message ||
//         "Login failed. Please check your credentials.";

//       if (errorMessage.toLowerCase().includes("invalid credential")) {
//         setError((prevError) => ({
//           username: "",
//           password: "Invalid Credential",
//         }));
//       } else if (errorMessage.includes("Plan has expired")) {
//         setError((prevError) => ({
//           password: "Plan has expired. Get a new plan.",
//         }));
//       }

//       // Reset loading states
//       setIsLoading(false);
//     }
//   };

//   const inputFieldStyles = {
//     width: "80%", // adjust the width as needed
//     fontSize: "14px", // smaller font size for input text
//     "& .MuiInputBase-root": {
//       height: "35px", // adjust the height of the input field
//       padding: "0 0px", // adjust padding inside the input field
//     },
//     "& .MuiOutlinedInput-notchedOutline": {
//       borderColor: "rgba(0, 0, 0, 0.23)", // default border color
//     },
//   };

//   return (
//     <Box display="flex" flexDirection="column" minHeight="100vh">
//       <Snackbar
//         open={openSnackbar}
//         autoHideDuration={6000}
//         onClose={() => setOpenSnackbar(false)}
//         anchorOrigin={{ vertical: "top", horizontal: "center" }}
//       >
//         <Alert
//           onClose={() => setOpenSnackbar(false)}
//           severity="info"
//           sx={{
//             backgroundColor: "blue",
//             color: "white",
//             fontSize: "18px", // Set font size to large
//             width: "100%", // Ensure the alert covers the Snackbar
//             textAlign: "center",
//           }}
//         >
//           Please complete your profile
//         </Alert>
//       </Snackbar>

//       <PrimarySearchAppBar />
      
//       <Container component="main" maxWidth="xs" sx={{ flexGrow: 1 }}>
//         <Box
//           display="flex"
//           flexDirection="column"
//           alignItems="center"
//           justifyContent="center"
//           minHeight="60vh"
//         >
//           <Typography
//             sx={{
//               fontSize: "25px",
//               fontWeight: 600,
//               mb: 1,
//               lineHeight: "80px",
//               textAlign: "center",
//               marginLeft: "-80px",
//               "@media (max-width: 425px)": {
//                 marginLeft: "20px",
//               },
//             }}
//           >
//             Welcome to Advoref
//           </Typography>

//           <Grid container spacing={2}>
//             <Grid item xs={12}>
//               <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
//                 Email Address
//               </Typography>
//               <TextField
//                 fullWidth
//                 variant="outlined"
//                 value={username}
//                 onChange={(e) => setUsername(e.target.value)}
//                 error={!!error.password && !error.username}
//                 sx={inputFieldStyles}
//               />
//             </Grid>

//             <Grid item xs={12}>
//               <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
//                 Password
//               </Typography>
//               <TextField
//                 fullWidth
//                 type={showPassword ? "text" : "password"}
//                 variant="outlined"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 error={!!error.password}
//                 helperText={error.password}
//                 sx={inputFieldStyles}
//                 InputProps={{
//                   endAdornment: (
//                     <IconButton
//                       onClick={handleTogglePasswordVisibility}
//                       edge="end"
//                       aria-label="toggle password visibility"
//                       style={{ marginRight: "2px" }}
//                     >
//                       {showPassword ? <VisibilityOff /> : <Visibility />}
//                     </IconButton>
//                   ),
//                 }}
//               />
//             </Grid>
//           </Grid>

          
//           <Box sx={{ width: "100%", textAlign: "left" }}>
//             <div
//               className="cf-turnstile"
//               data-sitekey="0x4AAAAAAAiwfewXK7m2oYyD"
//               data-callback={handleTurnstileCallback}
//               data-expired-callback={() => setCaptchaToken('')}
//               data-theme="light"
//               data-size="normal"
//             ></div>
//             <Button
//               variant="contained"
//               sx={{
//                 width: "140px",
//                 height: "44px",
//                 borderRadius: "6px",
//                 mt: 3,
//                 mb: 2,
//                 background: "#0E1030",
//                 "&:hover": {
//                   backgroundColor: "#0E1030",
//                 },
//               }}
//               onClick={handleLogIn}
//               disabled={isLoadingButton}
//             >
//               {isLoadingButton ? "Logging in..." : "Login"}
//             </Button>
//           </Box>

//           <Box sx={{ width: "100%", textAlign: "left" }}>
//             <Typography sx={{ fontSize: "12px", fontWeight: 400, mt: 2 }}>
//               Forgot Password
//               <Link
//                 onClick={handleForgotPass}
//                 sx={{
//                   fontSize: "12px",
//                   fontWeight: 400,
//                   ml: 1,
//                   cursor: "pointer",
//                 }}
//               >
//                 Reset Here
//               </Link>
//             </Typography>
//           </Box>
//         </Box>
//       </Container>
//       <Footer />

//       {isLoading && (
//         <Box
//           sx={{
//             position: "fixed",
//             top: 0,
//             left: 0,
//             width: "100%",
//             height: "100%",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             backgroundColor: "rgba(255, 255, 255, 0.7)",
//             zIndex: 1000,
//           }}
//         >
//           <CircularProgress size={80} />
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default Login;
