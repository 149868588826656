
import React from "react";
import { Container, Typography, Box } from "@mui/material";
import PrimarySearchAppBar from "../NavBar2/NavBar2";
import Footer2 from "../Footer/Footer2";
import { useNavigate } from "react-router-dom";

// Privacy Policy component / Webpage

function Pricing() {

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <PrimarySearchAppBar />
      <Container
        sx={{
          flexGrow: 1,
          padding: { xs: 2, sm: 4, md: 6 },
          maxWidth: { xs: "100%", sm: "90%", md: "90%" },
          textAlign: "justify",
        }}
      >
        <Typography style={{fontSize:"18px", fontWeight: 700}} gutterBottom>
        Cancellation & Refund Policy
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        At Advoref, we value customer satisfaction and strive to provide a seamless experience with our software for advocates. 
        However, we understand that circumstances may arise where you need to cancel your subscription. 
        Below is our detailed policy on cancellations and refunds.
        </Typography>
        <hr />
        <Typography style={{fontSize:"14px"}} paragraph>
        <b>1. Cancellation Policy</b>
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        You can cancel your subscription at any time by accessing your account settings or contacting our support team. Please review the terms associated with cancellations below:
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          <ul>
          <li><b>During the Free Trial Period: </b> <br /> If you cancel your subscription during the 15-day free trial, you will not be charged, and your access to the software will be terminated immediately.</li>
            <li><b>After the Free Trial Period:</b> <br />If you cancel your subscription after the free trial period, you will continue to have access to the software until the end of your current billing cycle (quarterly or yearly). No further charges will be applied after cancellation, but we do not offer prorated refunds for the remaining time in your billing cycle.</li>
          </ul>
        </Typography>

        <hr />

        <Typography style={{fontSize:"14px"}} paragraph>
        <b>2. Refund Policy</b>
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        We offer a 15-day free trial for all our subscription plans. We encourage you to take full advantage of this trial period to evaluate our software and its features.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          <ul>
          <li><b>No Refunds After Payment: </b> <br /> Once a payment has been processed for a quarterly or yearly subscription, it is considered final, and we do not offer refunds for the remaining time within the billing cycle. This policy applies to both the quarterly and yearly subscription plans.</li>
            <li><b>Exceptions:</b> <br />Refunds may be considered under special circumstances, such as billing errors or technical issues preventing access to the software. These requests will be reviewed on a case-by-case basis, and you can reach out to our support team to discuss any concerns.</li>
          </ul>
        </Typography>

        <hr />

        <Typography style={{fontSize:"14px"}} paragraph>
        <b>3. Automatic Renewals</b>
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        To provide you with peace of mind, we offer a 15-day free trial for all our subscription plans. We encourage you to take full advantage of this trial period to evaluate our software and its features.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          <ul>
          <li>Subscriptions renew automatically at the end of each billing cycle (quarterly or yearly) to ensure uninterrupted access to the software.</li>
            <li>You will receive a renewal reminder 7 Days before the renewal date. If you wish to cancel the automatic renewal, you must do so before the start of the next billing cycle.</li>
            <li>Once the new billing cycle begins, payments are non-refundable.</li>
          </ul>
        </Typography>
        
        <hr />

        <Typography style={{fontSize:"14px"}} paragraph>
        <b>4. Changes to This Policy</b>
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        Altum Infotech reserves the right to update or modify this cancellation and refund policy at any time. We will notify users of any significant changes via email or through our platform.
        </Typography>

        <hr />

        <Typography style={{fontSize:"14px"}} paragraph>
        <b>Need Help?</b>
        </Typography>
        <Typography style={{fontSize:"14px"}}>For any questions or concerns regarding cancellations or refunds, please don’t hesitate to reach out to our support team at support@advoref.com.</Typography>

      </Container>
      <Footer2 />
    </Box>
  );
}

export default Pricing;

