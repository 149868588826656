
import React, { useEffect, useState } from "react";
import PrimarySearchAppBar from "../NavBar2/NavBar2";
import Footer from "../Footer/Footer";
import {
  Container,
  Typography,
  useMediaQuery,
  Box,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import ShimmerLoading from "../../effects/ShimmerLoading";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import servicePath from "../Constant/defaultvalues";
import axios from "axios";
import Grid from '@mui/material/Grid'

function Profile() {
  const [planExpiry, setPlanExpiry] = useState(null);
  const [profileData, setProfileData] = useState({
    poc: "",
    email: "",
    mobile: "",
  });

  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const token = localStorage.getItem('subscriptionToken');
  //   if (!token) {
  //     navigate('/signup');
  //   }
  // }, [navigate]);

  useEffect(() => {
    // Fetch the 'admin_id' from localStorage
    const admin_id = localStorage.getItem("admin_id");
    console.log("admin_id: ", admin_id); // Log the admin_id for debugging purposes
  
    // If 'admin_id' is not found, log an error, stop the loading state, and exit
    if (!admin_id) {
      console.error("admin_id is not found in localStorage.");
      setLoading(false); // Set loading to false as there's no admin_id to proceed
      return;
    }
  
    // Start loading process (set loading state to true)
    setLoading(true);
  
    // Make an API call to get profile details using the 'admin_id'
    axios
      .get(`${servicePath}/profiledetails`, {
        headers: {
          admin_id: admin_id, // Pass admin_id in the request headers
        },
      })
      .then((response) => {
        // Assume the first result contains the profile data
        const data = response.data[0];
        console.log("Profile Data: ", data); // Log the profile data for debugging
  
        // Set the plan expiry date from the response data
        setPlanExpiry(data.plan_expiry);
  
        // Update profile-related data like point of contact (poc), email, and mobile
        setProfileData({
          poc: data.poc,
          email: data.email,
          mobile: data.mobile,
        });
      })
      .catch((error) => {
        // Log any error that occurs during the API call
        console.error("Error fetching profile details:", error);
      })
      .finally(() => {
        // Stop the loading process regardless of whether the request succeeded or failed
        setLoading(false);
      });
  }, []); // Empty dependency array ensures the effect runs only once on component mount
  

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(425));

  const navigate = useNavigate();

  const admin_id = localStorage.getItem("admin_id");
  console.log("admin_id: ", admin_id);

  const handleBack = () => {
    navigate("/home");
  };

  const handleEditProfile = () => {
    navigate("/editprofile");
  };

  const handleContact = () => {
    navigate("/contact");
  };

  return (
//     <Box display="flex" flexDirection="column" minHeight="100vh">
//       <PrimarySearchAppBar />
//       <Container style={{ marginBottom: "50px" }} sx={{ flexGrow: 1 }}>
//   <Box textAlign="left" my={2}>
//     <Button
//       variant="text"
//       style={{
//         textTransform: "none",
//         border: "none",
//         backgroundColor: "transparent",
//         color: "#0E1030",
//         fontSize: "26px",
//         fontWeight: 600,
//         textDecorationColor: "#EE3A78",
//         padding: "8px 16px",
//       }}
//       onClick={handleBack}
//     >
//       <ArrowCircleLeftIcon sx={{ marginRight: "10px" }} /> Profile
//     </Button>
//   </Box>

//   <Grid container spacing={3}>
//   <Grid item xs={12} sm={6} md={4}>
//     {/* Profile Information Box */}
//     <Box
//       sx={{
//         border: "1px solid #ccc",
//         borderRadius: 2,
//         p: 2,
//       }}
//     >
//       {/* Profile Information */}
//       <Box
//         sx={{
//           display: "inline-flex",
//           alignItems: "left",
//           mb: 2,
//         }}
//       >
//         <Avatar
//           sx={{
//             width: 56,
//             height: 56,
//             mr: 2,
//           }}
//         />
//         <Box>
//           <Typography variant="h6">{profileData.poc}</Typography>
//           <Typography variant="body2">{profileData.email}</Typography>
//           <Typography variant="body2">{profileData.mobile}</Typography>
//         </Box>
//       </Box>

//       {/* Menu List */}
//       <List>
//         <ListItem button>
//           <ListItemIcon>
//             <EditIcon sx={{ color: "#0E1030" }} />
//           </ListItemIcon>
//           <ListItemText primary="Edit Profile" onClick={handleEditProfile} />
//         </ListItem>
//         <ListItem button>
//           <ListItemIcon>
//             <ShareIcon sx={{ color: "#0E1030" }} />
//           </ListItemIcon>
//           <ListItemText primary="Referral" />
//         </ListItem>
//         <ListItem button>
//           <ListItemIcon>
//             <HeadsetMicIcon sx={{ color: "#0E1030" }} />
//           </ListItemIcon>
//           <ListItemText primary="Contact Us" onClick={handleContact} />
//         </ListItem>
//         <ListItem button>
//           <ListItemIcon>
//             <SettingsIcon sx={{ color: "#0E1030" }} />
//           </ListItemIcon>
//           <ListItemText primary="General Settings" />
//         </ListItem>
//       </List>
//     </Box>
//   </Grid>

//   <Grid item xs={12} md={8} sm={8}>
//     {/* Welcome and Plan Details Box */}
//     <Box>
//       <Typography sx={{ fontSize:"30px", fontWeight:600, color:"#0E1030"}}>Welcome Back {profileData.poc}</Typography>
//       <Typography sx={{ fontSize:"20px", fontWeight:400, color:"#0E1030"}}>You have no Active Plan</Typography>
//       <Typography sx={{ fontSize:"20px", fontWeight:400, color:"#0E1030"}}>
//         {planExpiry &&
//           `Your Trial period expires on ${new Date(
//             planExpiry
//           ).toLocaleDateString("en-GB", {
//             day: "numeric",
//             month: "long",
//             year: "numeric",
//           })}`}
//       </Typography>
//     </Box>
//   </Grid>
// </Grid>

// </Container>

//       <Footer />
//     </Box>
<Box display="flex" flexDirection="column" minHeight="100vh">
      <PrimarySearchAppBar />
      <Container style={{ marginBottom: "50px" }} sx={{ flexGrow: 1 }}>
        <Box textAlign="left" my={2}>
          <Button
            variant="text"
            style={{
              textTransform: "none",
              border: "none",
              backgroundColor: "transparent",
              color: "#0E1030",
              fontSize: "26px",
              fontWeight: 600,
              textDecorationColor: "#EE3A78",
              padding: "8px 16px",
            }}
            onClick={handleBack}
          >
            <ArrowCircleLeftIcon sx={{ marginRight: "10px" }} /> Profile
          </Button>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            {loading ? (
              <ShimmerLoading />
            ) : (
              <Box
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: 2,
                  p: 2,
                }}
              >
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "left",
                    mb: 2,
                  }}
                >
                  <Avatar
                    sx={{
                      width: 56,
                      height: 56,
                      mr: 2,
                    }}
                  />
                  <Box>
                    <Typography variant="h6">{profileData.poc}</Typography>
                    <Typography variant="body2">{profileData.email}</Typography>
                    <Typography variant="body2">{profileData.mobile}</Typography>
                  </Box>
                </Box>

                <List>
                  <ListItem button>
                    <ListItemIcon>
                      <EditIcon sx={{ color: "#0E1030" }} />
                    </ListItemIcon>
                    <ListItemText primary="Edit Profile" onClick={handleEditProfile} />
                  </ListItem>
                  <ListItem button>
                    <ListItemIcon>
                      <ShareIcon sx={{ color: "#0E1030" }} />
                    </ListItemIcon>
                    <ListItemText primary="Referral" />
                  </ListItem>
                  <ListItem button>
                    <ListItemIcon>
                      <HeadsetMicIcon sx={{ color: "#0E1030" }} />
                    </ListItemIcon>
                    <ListItemText primary="Contact Us" onClick={handleContact} />
                  </ListItem>
                  <ListItem button>
                    <ListItemIcon>
                      <SettingsIcon sx={{ color: "#0E1030" }} />
                    </ListItemIcon>
                    <ListItemText primary="General Settings" />
                  </ListItem>
                </List>
              </Box>
            )}
          </Grid>

          <Grid item xs={12} md={8} sm={8}>
            {loading ? (
              <ShimmerLoading />
            ) : (
              <Box>
                <Typography sx={{ fontSize: "30px", fontWeight: 600, color: "#0E1030" }}>
                  Welcome Back {profileData.poc}
                </Typography>
                <Typography sx={{ fontSize: "20px", fontWeight: 400, color: "#0E1030" }}>
                  You have no Active Plan
                </Typography>
                <Typography sx={{ fontSize: "20px", fontWeight: 400, color: "#0E1030" }}>
                  {planExpiry &&
                    `Your Trial period expires on ${new Date(planExpiry).toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}`}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </Box>
  );
}

export default Profile;

