import React, { useState, useEffect } from 'react';
import PrimarySearchAppBar from '../NavBar2/NavBar2';
import Footer2 from '../Footer/Footer2';
import { Container, Typography, TextField, Button, Grid, Box, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import servicePath from '../Constant/defaultvalues';

function Org() {
  const [formValues, setFormValues] = useState({
    org_name: '',
    poc: '',
    mobile: '',
    address: '',
    state: '',
    city: '',
    pincode: '',
  });

  const [formErrors, setFormErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();

  // useEffect(() => {
  //   const token = localStorage.getItem('subscriptionToken');
  //   if (!token) {
  //     navigate('/login');
  //   }
  // }, [navigate]);

  useEffect(() => {
    if (snackbarOpen && snackbarSeverity === 'success') {
      const timer = setTimeout(() => {
        navigate('/getstarted');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [snackbarOpen, snackbarSeverity, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = () => {
    let errors = {};

    Object.keys(formValues).forEach((key) => {
      if (!formValues[key]) {
        errors[key] = 'This field is required';
      }
    });

    return errors;
  };

  const handleSubmit = async () => {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const no_of_userplan = localStorage.getItem('count');
    const email = localStorage.getItem('email');
    const first_name = localStorage.getItem('firstName');
    const last_name = localStorage.getItem('lastName');
    const password = localStorage.getItem('password');

    const dataToSubmit = {
      ...formValues,
      no_of_userplan,
      email,
      first_name,
      last_name,
      password
    };

    try {
      console.log('Form Values:', dataToSubmit); // line for debugging
      const response = await fetch(`${servicePath}/create-organization`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSubmit),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Organization created:', result);
      setSnackbarSeverity('success');
      setSnackbarMessage('Organization created successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error creating organization:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('Error creating organization');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

//   const no_of_userplan = localStorage.getItem('count');
//   console.log("no_of_userplan: ",no_of_userplan);
//   const email = localStorage.getItem('email');
//   console.log("email: ",email);
//   const first_name = localStorage.getItem('firstName');
//   console.log("first_name: ",first_name);
//   const last_name = localStorage.getItem('lastName');
//   console.log("last_name: ",last_name);
//   const password = localStorage.getItem('password');
//   console.log("password: ",password);


  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <PrimarySearchAppBar />
      <Container maxWidth="lg" style={{ flex: '1' }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="60vh"
        >
          <Typography
            sx={{ fontSize: '25px', fontWeight: 600, mb: 1, lineHeight: '100px' }}
          >
            Create Organization
          </Typography>

          <Grid container spacing={3}>
            {[
              { label: 'Organization Name', name: 'org_name' },
              { label: 'Point of Contact', name: 'poc' },
              { label: 'Mobile Number', name: 'mobile' },
              { label: 'Address', name: 'address', multiline: true, maxRows: 4 },
              { label: 'State', name: 'state' },
              { label: 'City', name: 'city' },
              { label: 'Pincode', name: 'pincode' },
            ].map((field, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Typography sx={{ fontSize: '15px', fontWeight: 500, mb: 1 }}>
                  {field.label}
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name={field.name}
                  value={formValues[field.name]}
                  onChange={handleChange}
                  error={!!formErrors[field.name]}
                  helperText={formErrors[field.name]}
                  multiline={field.multiline || false}
                  maxRows={field.maxRows || 1}
                />
              </Grid>
            ))}
          </Grid>

          <Box sx={{ width: '100%', textAlign: 'center', mt: 5 }}>
            <Button
              variant="contained"
              sx={{ width: '200px', height: '44px', borderRadius: '6px', background: '#0E1030' }}
              onClick={handleSubmit}
            >
              Create
            </Button>
          </Box>
        </Box>
      </Container>
      <Footer2 />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Org;
