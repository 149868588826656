
import React from "react";
import { Container, Typography, Box } from "@mui/material";
import PrimarySearchAppBar from "../NavBar2/NavBar2";
import Footer2 from "../Footer/Footer2";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from "react-router-dom";

// DIscover component / Webpage from landing page in subscription section.
function Discover() {

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <PrimarySearchAppBar />
      <Container
        sx={{
          flexGrow: 1,
          padding: { xs: 2, sm: 4, md: 6 },
          maxWidth: { xs: "100%", sm: "90%", md: "90%" },
          textAlign: "justify",
        }}
      >
        <Typography style={{fontSize:"18px", fontWeight: 700}} gutterBottom>
        Discover Our Subscription Plans
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        At Advoref, we offer flexible subscription plans designed to fit your legal practice's needs. 
        Explore the features of each plan and choose the one that’s right for you. 
        All plans come with a 15-day free trial to get you started.
        </Typography>
        <hr />
        <Typography style={{fontSize:"14px"}} paragraph>
        <b>Standard Plan</b>
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        <b>Yearly Price:</b> ₹6,000
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        <b>Quarterly Price:</b> ₹2,000
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        Perfect for solo practitioners and small law firms. <br />
        Includes essential tools to help you manage your clients and property cases efficiently.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
            Features:
          <ul>
            <li><CheckCircleOutlineIcon/> Manage Clients</li>
            <li><CheckCircleOutlineIcon/> Manage Property</li>
            <li><CheckCircleOutlineIcon/>3 Additional Users (Free)</li>
          </ul>
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        Looking for more advanced capabilities? Start your 15-day free trial or Buy Now for immediate access.
        </Typography>

        <hr />

        <Typography style={{fontSize:"14px"}} paragraph>
        <b>Premium Plan</b>
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        <b>Yearly Price:</b> ₹10,000
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        <b>Quarterly Price:</b> ₹3,000
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        Ideal for growing practices. <br />
        Unlock advanced features to enhance your practice’s efficiency.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
            Features:
          <ul>
            <li><CheckCircleOutlineIcon/> Manage Clients</li>
            <li><CheckCircleOutlineIcon/> Manage Property</li>
            <li><CheckCircleOutlineIcon/>3 Additional Users (Free)</li>
          </ul>
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        Looking for more advanced capabilities? Start your 15-day free trial or Buy Now for immediate access.
        </Typography>

        <hr />

        <Typography style={{fontSize:"14px"}} paragraph>
        <b>Premium Pro Plan</b>
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        <b>Yearly Price:</b> ₹12,000
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        <b>Quarterly Price:</b> ₹4,000
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        The ultimate solution for large firms and power users. <br />
        Gain access to all premium features, including customizable software and document management.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
            Features:
          <ul>
            <li><CheckCircleOutlineIcon/> Manage Clients</li>
            <li><CheckCircleOutlineIcon/> Manage Property</li>
            <li><CheckCircleOutlineIcon/>3 Additional Users (Free)</li>
          </ul>
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        For the full-featured experience, Contact Us for a personalized consultation or Buy Now to enjoy the full range of services.
        </Typography>
        

      </Container>
      <Footer2 />
    </Box>
  );
}

export default Discover;

