
import React from "react";
import { Container, Typography, Box } from "@mui/material";
import PrimarySearchAppBar from "../NavBar2/NavBar2";
import Footer2 from "../Footer/Footer2";
import { useNavigate } from "react-router-dom";


// About Us component / Webpage

function AboutUs() {

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <PrimarySearchAppBar />
      <Container
        sx={{
          flexGrow: 1,
          padding: { xs: 2, sm: 4, md: 6 },
          maxWidth: { xs: "100%", sm: "90%", md: "90%" },
          textAlign: "justify",
        }}
      >
        <Typography style={{fontSize:"18px", fontWeight: 700}} gutterBottom>
        About Us
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        Welcome to Advoref, your trusted partner in legal technology. Designed specifically for advocates and legal professionals, Advoref is a powerful SaaS platform that simplifies the management of property-related legal processes. 
        We are passionate about empowering law firms and individual practitioners with tools that allow them to focus on what truly matters—delivering top-notch legal services to their clients.
        </Typography>
        <hr />
        <Typography style={{fontSize:"14px"}} paragraph>
        At Advoref, we believe that modern legal professionals deserve modern solutions. 
        Our platform helps advocates seamlessly manage, store, and process property documents, 
        eliminating the hassle of traditional paperwork. 
        Whether it’s calculating client charges based on property details or accessing advanced analytics to make informed decisions, 
        Advoref streamlines your workflow and enhances productivity.
        </Typography>

        <hr />

        <Typography style={{fontSize:"14px"}} paragraph>
        <b>What We Offer</b>
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        Advoref is more than just software—it’s a comprehensive solution tailored to meet the evolving needs of legal professionals. Our key features include:
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          <ul>
          <li><b>Document Management:  </b> <br /> Once a payment has been processed for a quarterly or yearly subscription, it is considered final, and we do not offer refunds for the remaining time within the billing cycle. This policy applies to both the quarterly and yearly subscription plans.</li>
            <li><b>Client Management:</b> <br />Keep track of client interactions and case details with ease.</li>
            <li><b>Automated Billing:</b> <br />Calculate fees based on property details, ensuring accurate and transparent invoicing.</li>
            <li><b>Advanced Analytics: </b> <br />Gain insights into client data and make data-driven decisions to optimize your practice.</li>
          </ul>
        </Typography>

        <hr />

        <Typography style={{fontSize:"14px"}} paragraph>
        <b>Our Mission</b>
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        Our mission is simple: to make the lives of legal professionals easier by providing them with intuitive, reliable, and secure software solutions. We strive to eliminate the administrative burdens that come with managing property-related cases, enabling advocates to dedicate more time to their clients and their cases.
        </Typography>
        
      </Container>
      <Footer2 />
    </Box>
  );
}

export default AboutUs;

