
import React from "react";
import { Container, Typography, Box } from "@mui/material";
import PrimarySearchAppBar from "../NavBar2/NavBar2";
import Footer2 from "../Footer/Footer2";
import { useNavigate } from "react-router-dom";

// This component displays all the Privacy Policy statements

function Privacy() {
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <PrimarySearchAppBar />
      <Container
        sx={{
          flexGrow: 1,
          padding: { xs: 2, sm: 4, md: 6 },
          maxWidth: { xs: "100%", sm: "90%", md: "90%" },
          textAlign: "justify",
        }}
      >

        <Typography style={{fontSize:"18px", fontWeight: 700}} gutterBottom mt={0}>
          Privacy Policy
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          We collect information that you provide directly to us, such as when
          you create an account, input client or property data, or communicate
          with us. This information may include personal details, property
          information, and any other data necessary for the operation of the
          software.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          The information collected is used to provide, maintain, and improve
          our services, including processing transactions, sending
          notifications, and generating reports. We may also use your
          information for internal analytics purposes.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          We take reasonable measures to protect your data from unauthorized
          access, use, or disclosure. However, no internet-based service can be
          completely secure, and we cannot guarantee the absolute security of
          your information.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          We do not share your personal information with third parties except as
          necessary to provide our services, comply with the law, or protect our
          rights. We may share non-personally identifiable information with our
          partners for business or research purposes.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          We will retain your personal information for as long as your account
          is active or as needed to provide you with our services. We may retain
          and use your information to comply with our legal obligations, resolve
          disputes, and enforce our agreements.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          You have the right to access, correct, or delete your personal
          information stored in our software. You can do this by contacting our
          support team. Please note that some information may be retained for
          legal or operational reasons.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page, and your continued use of the software after
          the changes are posted will signify your acceptance of the revised
          terms.
        </Typography>

      </Container>
      <Footer2 />
    </Box>
  );
}

export default Privacy;

