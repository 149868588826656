// import React, { useState } from "react";
// import {
//   Container,
//   Typography,
//   TextField,
//   Button,
//   Backdrop,
//   CircularProgress,
//   Box,
// } from "@mui/material";
// import PrimarySearchAppBar from "../NavBar2/NavBar2";
// import Footer from "../Footer/Footer2";
// import servicePath from "../Constant/defaultvalues";
// import { toast } from "react-toastify";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// function ResetPass() {
//   const [newPassword, setNewPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [isPasswordErr, setIsPasswordErr] = useState(false);
//   const [isConfirmPasswordErr, setIsConfirmPasswordErr] = useState(false);
//   const [loading, setLoading] = useState(false); // Loading state
//   const navigate = useNavigate();

//   const email = localStorage.getItem("resetEmail");
//   const code = localStorage.getItem("resetCode");

//   const handleResetPassword = async () => {
//     setIsPasswordErr(false);
//     setIsConfirmPasswordErr(false);

//     if (!newPassword || newPassword.length < 6) {
//       toast.error("Password must be at least 6 characters", {
//         position: "top-right",
//         autoClose: 2000,
//       });
//       setIsPasswordErr(true);
//       return;
//     }

//     if (newPassword !== confirmPassword) {
//       toast.error("Passwords do not match", {
//         position: "top-right",
//         autoClose: 2000,
//       });
//       setIsConfirmPasswordErr(true);
//       return;
//     }

//     setLoading(true); // Show spinner

//     try {
//       const response = await axios.post(`${servicePath}/advorefreset-password`, {
//         email,
//         code,
//         newPassword,
//       });

//       if (response.status === 200) {
//         toast.success("Password updated successfully!", {
//           position: "top-right",
//           autoClose: 2000,
//         });
//         // Clear local storage after successful reset
//         localStorage.removeItem("resetEmail");
//         localStorage.removeItem("resetCode");
//         navigate("/login");
//       }
//     } catch (error) {
//       console.error("Error updating password:", error);
//       toast.error("Failed to update password", {
//         position: "top-right",
//         autoClose: 2000,
//       });
//     } finally {
//       setLoading(false); // Hide spinner
//     }
//   };

//   return (
//     <Box display="flex" flexDirection="column" minHeight="100vh">
//       <PrimarySearchAppBar />
//       <Container maxWidth="xs" sx={{ flexGrow: 1 }}>
//         <Box
//           display="flex"
//           flexDirection="column"
//           alignItems="center"
//           justifyContent="center"
//           minHeight="60vh"
//         >
//           <Typography
//             sx={{
//               fontSize: "25px",
//               fontWeight: 600,
//               mb: 1,
//               lineHeight: "80px",
//             }}
//           >
//             Reset Password
//           </Typography>

//           <Box sx={{ width: "100%", mb: 2 }}>
//             <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
//               New Password
//             </Typography>
//             <TextField
//               fullWidth
//               type="password"
//               variant="outlined"
//               value={newPassword}
//               onChange={(e) => setNewPassword(e.target.value)}
//               error={isPasswordErr}
//             />
//           </Box>

//           <Box sx={{ width: "100%", mb: 2 }}>
//             <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
//               Confirm Password
//             </Typography>
//             <TextField
//               fullWidth
//               type="password"
//               variant="outlined"
//               value={confirmPassword}
//               onChange={(e) => setConfirmPassword(e.target.value)}
//               error={isConfirmPasswordErr}
//             />
//           </Box>

//           <Box sx={{ width: "100%", textAlign: "center" }}>
//             <Button
//               variant="contained"
//               sx={{
//                 width: "200px",
//                 height: "44px",
//                 borderRadius: "6px",
//                 mt: 3,
//                 mb: 2,
//                 backgroundColor: "#0E1030",
//                 color: "white",
//                 textTransform: "none",
//                 "&:hover": {
//                   backgroundColor: "#0E1030",
//                 },
//               }}
//               onClick={handleResetPassword}
//             >
//               Reset Password
//             </Button>
//           </Box>
//         </Box>
//       </Container>

//       <Backdrop
//         sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
//         open={loading}
//       >
//         <CircularProgress color="inherit" />
//       </Backdrop>

//       <Footer />
//     </Box>
//   );
// }

// export default ResetPass;

import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  Box,
} from "@mui/material";
import PrimarySearchAppBar from "../NavBar2/NavBar2";
import Footer from "../Footer/Footer2";
import servicePath from "../Constant/defaultvalues";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function ResetPass() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordErr, setIsPasswordErr] = useState(false);
  const [isConfirmPasswordErr, setIsConfirmPasswordErr] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  const email = localStorage.getItem("resetEmail");
  const code = localStorage.getItem("resetCode");

  const handleResetPassword = async () => {
    // Reset error states for both password fields initially
    setIsPasswordErr(false);
    setIsConfirmPasswordErr(false);
  
    // Check if the new password is not entered or is less than 6 characters
    if (!newPassword || newPassword.length < 6) {
      // Show a toast notification to inform the user about the password length requirement
      toast.error("Password must be at least 6 characters", {
        position: "top-right",
        autoClose: 2000,
      });
  
      // Set the password error state to true to highlight the password field
      setIsPasswordErr(true);
      return; // Stop further execution if the password is invalid
    }
  
    // Check if the new password and confirmation password do not match
    if (newPassword !== confirmPassword) {
      // Show a toast notification to alert the user about the mismatch
      toast.error("Passwords do not match", {
        position: "top-right",
        autoClose: 2000,
      });
  
      // Set the confirm password error state to true to highlight the confirm password field
      setIsConfirmPasswordErr(true);
      return; // Stop further execution if the passwords do not match
    }
  
    // Set loading state to true to show a loading spinner during the password reset process
    setLoading(true);
  
    try {
      // Send a POST request to the server to reset the password, including the email, reset code, and new password
      const response = await axios.post(`${servicePath}/advorefreset-password`, {
        email,       // Email is retrieved from local storage
        code,        // Code is retrieved from local storage
        newPassword, // New password to be set
      });
  
      // If the server responds with a 200 status, the password has been updated successfully
      if (response.status === 200) {
        // Notify the user about the successful password update
        toast.success("Password updated successfully!", {
          position: "top-right",
          autoClose: 2000,
        });
  
        // Clear the email and code from local storage as they are no longer needed after the password reset
        localStorage.removeItem("resetEmail");
        localStorage.removeItem("resetCode");
  
        // Redirect the user to the login page after the password has been successfully reset
        navigate("/login");
      }
    } catch (error) {
      // Log any errors that occur during the password update process
      console.error("Error updating password:", error);
  
      // Notify the user about the failure to update the password
      toast.error("Failed to update password", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      // Set loading state to false to stop showing the loading spinner after the process is complete
      setLoading(false);
    }
  };
  

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <PrimarySearchAppBar />
      <Container maxWidth="xs" sx={{ flexGrow: 1 }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="60vh"
        >
          <Typography
            sx={{
              fontSize: "25px",
              fontWeight: 600,
              mb: 1,
              lineHeight: "80px",
            }}
          >
            Reset Password
          </Typography>

          <Box sx={{ width: "100%", mb: 2 }}>
            <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
              New Password
            </Typography>
            <TextField
              fullWidth
              type="password"
              variant="outlined"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              error={isPasswordErr}
            />
          </Box>

          <Box sx={{ width: "100%", mb: 2 }}>
            <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
              Confirm Password
            </Typography>
            <TextField
              fullWidth
              type="password"
              variant="outlined"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              error={isConfirmPasswordErr}
            />
          </Box>

          <Box sx={{ width: "100%", textAlign: "center" }}>
            <Button
              variant="contained"
              sx={{
                width: "200px",
                height: "44px",
                borderRadius: "6px",
                mt: 3,
                mb: 2,
                backgroundColor: "#0E1030",
                color: "white",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#0E1030",
                },
              }}
              onClick={handleResetPassword}
            >
              Reset Password
            </Button>
          </Box>
        </Box>
      </Container>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Footer />
    </Box>
  );
}

export default ResetPass;

