import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  TextField,
  Button,
  Link,
  Box,
  Modal,
  Grid,
  Backdrop,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PrimarySearchAppBar from "../NavBar2/NavBar2";
import Footer from "../Footer/Footer2";
import servicePath from "../Constant/defaultvalues";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import "react-toastify/dist/ReactToastify.css";

const SignUpPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [otpError, setOtpError] = useState("");
  const [otpAttempts, setOtpAttempts] = useState(0);
  const [countdown, setCountdown] = useState(60);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const otpRefs = useRef([]);

  const [isResendingOtp, setIsResendingOtp] = useState(false);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);



  const handleClose = () => {
    setOtpModalOpen(false); // Close the modal when called
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  useEffect(() => {
    if (otpModalOpen) {
      setLoading(false); // Stop loading when modal is open
      const timer = setInterval(() => {
        setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [otpModalOpen]);

  // Email validator
  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleLogin = () => {
    navigate("/login");
  };

  // Password validator
  const validatePassword = (password) => {
    return password.length >= 6;
  };

  // Checks if the email already exist
  // For new user otp is generated
  const handleSubmit = async (e) => {
    // Generate a random tab ID and store it in session storage
    const tabId = Math.floor(Math.random() * 1000000);
    sessionStorage.setItem("tabId", tabId);
    e.preventDefault();
    setLoading(true); // Start loading

    let valid = true;
    let errors = { email: "", password: "" };

    if (!validateEmail(email)) {
      errors.email = "Invalid email address";
      valid = false;
    }

    if (!validatePassword(password)) {
      errors.password = "Password must be at least 6 characters";
      valid = false;
    }

    setErrors(errors);

    if (valid) {
      try {
        const emailCheckResponse = await axios.post(
          `${servicePath}/check-email`,
          { email }
        );
        if (emailCheckResponse.data.exists) {
          toast.info("Email ID already exists!");
          setLoading(false); // Stop loading if email exists
        } else {
          const response = await axios.post(`${servicePath}/generate-otp`, {
            email,
          });
          console.log("OTP generated:", response.data);
          setOtpModalOpen(true);
          setCountdown(30);
        }
      } catch (error) {
        console.error("Error during email check or OTP generation:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error during email check or OTP generation");
        }
        setLoading(false); // Stop loading on error
      }
    } else {
      setLoading(false); // Stop loading if form is invalid
    }
  };

  // Verify otp
  // const handleVerifyOtp = async () => {
  //   try {
  //     const otpString = otp.join("");
  //     console.log("Sending OTP verification request:", {
  //       email,
  //       otp: otpString,
  //     });

  //     // Retrieve tabId from session storage
  //     const tabId = sessionStorage.getItem("tabId");

  //     // Pass tabId in the headers for the OTP verification
  //     const response = await axios.post(
  //       `${servicePath}/verify-otp`,
  //       { email, otp: otpString },
  //       { headers: { tabId } }
  //     );

  //     console.log("OTP verification response:", response.data);

  //     if (response.data.verified) {
  //       const { token, userDetails } = response.data;

  //       // Optionally save token or user details to localStorage or context
  //       localStorage.setItem("subscriptionToken", token);
  //       localStorage.setItem("userDetails", JSON.stringify(userDetails));

  //       // Proceed with signup or navigate to another page
  //       const signupResponse = await axios.post(
  //         `${servicePath}/advorefsignup`,
  //         { email, password },
  //         { headers: { Authorization: `Bearer ${token}` } } // Send token if required
  //       );

  //       console.log("Signup successful:", signupResponse.data);
  //       // Save admin_id to localStorage
  //       const admin_id = signupResponse.data.signupResponse.admin_id;
  //       console.log("admin_id to store:", admin_id);
  //       localStorage.setItem("admin_id", admin_id);

  //       // toast.success("Signup successful");
  //       setOtpModalOpen(false);
  //       navigate("/profileform");
  //     } else {
  //       setOtpError("Incorrect OTP");
  //       setOtpAttempts(otpAttempts + 1);
  //       if (otpAttempts + 1 >= 3) {
  //         setOtpModalOpen(false);
  //         toast.error("Signup failed. Please try again.");
  //       } else {
  //         toast.error("Incorrect OTP");
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error during OTP verification:", error);
  //     toast.error("Error during OTP verification");
  //   }
  // };

  const handleVerifyOtp = async () => {
    setIsVerifyingOtp(true); // Show full-screen loading spinner for OTP verification
    try {
      const otpString = otp.join("");
      console.log("Sending OTP verification request:", { email, otp: otpString });
  
      // Retrieve tabId from session storage
      const tabId = sessionStorage.getItem("tabId");
  
      // Pass tabId in the headers for the OTP verification
      const response = await axios.post(
        `${servicePath}/verify-otp`,
        { email, otp: otpString },
        { headers: { tabId } }
      );
  
      console.log("OTP verification response:", response.data);
  
      if (response.data.verified) {
        const { token, userDetails } = response.data;
  
        // Optionally save token or user details to localStorage or context
        localStorage.setItem("subscriptionToken", token);
        localStorage.setItem("userDetails", JSON.stringify(userDetails));
  
        // Proceed with signup or navigate to another page
        const signupResponse = await axios.post(
          `${servicePath}/advorefsignup`,
          { email, password },
          { headers: { Authorization: `Bearer ${token}` } }
        );
  
        console.log("Signup successful:", signupResponse.data);
        // Save admin_id to localStorage
        const admin_id = signupResponse.data.signupResponse.admin_id;
        console.log("admin_id to store:", admin_id);
        localStorage.setItem("admin_id", admin_id);
  
        setOtpModalOpen(false);
        navigate("/profileform");
      } else {
        // Display toast error message for incorrect OTP
        setOtpError("Incorrect OTP");
        toast.error("Incorrect OTP"); // Show toast for incorrect OTP
  
        setOtpAttempts(otpAttempts + 1);
        if (otpAttempts + 1 >= 3) {
          setOtpModalOpen(false);
          toast.error("Signup failed. Please try again.");
        }
      }
    } catch (error) {
      console.error("Error during OTP verification:", error);
      toast.error("Error during OTP verification");
    } finally {
      setIsVerifyingOtp(false); // Stop showing the loading spinner
    }
  };
  


  // Resend otp option after 30 seconds
  const handleResendOtp = async () => {
    setIsResendingOtp(true);
    try {
      const response = await axios.post(`${servicePath}/generate-otp`, {
        email,
      });
      console.log("New OTP generated:", response.data);
      toast.success("New OTP sent successfully");
      setCountdown(30);
      setOtp(Array(6).fill("")); // Reset OTP input fields
      otpRefs.current[0].focus(); // Focus on the first OTP input field
      setIsResendingOtp(false); 
    } catch (error) {
      console.error("Error resending OTP:", error);
      toast.error("Error resending OTP");
      setIsResendingOtp(false); 
    }
  };

  const handleOtpChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otpRefs.current.length - 1) {
        otpRefs.current[index + 1].focus();
      }
    }
  };

  // Terms and Condition content for modal
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const handleOpenModal2 = () => setOpen2(true);
  const handleCloseModal2 = () => setOpen2(false);

  const termsAndConditionsContent = `
    Welcome to Advoref! These terms and conditions outline the rules and regulations for the use of Advoref's software.
    By accessing this software, we assume you accept these terms and conditions. Do not continue to use Advoref if you do not agree to all of the terms and conditions stated on this page.<br><br>
    Our software is designed for advocates to manage and store Real
    Estate-related data, including creating client profiles, managing
    properties, calculating charges, and generating reports. You agree to
    use the software only for lawful purposes.<br><br>
    You are responsible for maintaining the confidentiality of your
    account and password and for restricting access to your computer. You
    agree to accept responsibility for all activities that occur under
    your account or password.<br><br>
    You retain all rights to the data you input into the software.
    However, you grant us a license to use, copy, transmit, store, and
    back up your data for the purposes of enabling you to use the software
    and for any other purpose related to the provision of services to you.<br><br>
    All content included in this software, such as text, graphics, logos,
    and software, is the property of Altum Infotech and is protected
    by intellectual property laws.<br><br>
    We shall not be liable for any direct, indirect, incidental, special,
    or consequential damages resulting from the use or inability to use
    the software, including but not limited to damages for loss of
    profits, use, data, or other intangibles.<br><br>
    We reserve the right to terminate or suspend your access to the
    software at any time, without notice, for conduct that we believe
    violates these Terms and Conditions or is harmful to other users of
    the software, us, or third parties.<br><br>
    We reserve the right to modify these Terms and Conditions at any time.
    Any changes will be effective immediately upon posting on our website.
    Your continued use of the software following any such modifications
    constitutes your acceptance of the revised terms.
  `;

  const privacyPolicyContent = `
    Advoref values your privacy. This policy explains how we handle and protect your personal data. We collect, use, and store your information to provide and improve our services.
    We are committed to safeguarding the information that you share with us. For more details, please read our complete privacy policy.
    <br><br>
          We collect information that you provide directly to us, such as when
          you create an account, input client or property data, or communicate
          with us. This information may include personal details, property
          information, and any other data necessary for the operation of the
          software.
    <br><br>
          The information collected is used to provide, maintain, and improve
          our services, including processing transactions, sending
          notifications, and generating reports. We may also use your
          information for internal analytics purposes.
<br><br>
          We take reasonable measures to protect your data from unauthorized
          access, use, or disclosure. However, no internet-based service can be
          completely secure, and we cannot guarantee the absolute security of
          your information.
<br><br>
          We do not share your personal information with third parties except as
          necessary to provide our services, comply with the law, or protect our
          rights. We may share non-personally identifiable information with our
          partners for business or research purposes.
<br><br>
          We will retain your personal information for as long as your account
          is active or as needed to provide you with our services. We may retain
          and use your information to comply with our legal obligations, resolve
          disputes, and enforce our agreements.
<br><br>
          You have the right to access, correct, or delete your personal
          information stored in our software. Please note that some information may be retained for
          legal or operational reasons.
<br><br>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page, and your continued use of the software after
          the changes are posted will signify your acceptance of the revised
          terms.

  `;

  const refundAndCancellationContent = `
    Refund Policy: We offer a full refund within 7 days of purchase if you are not satisfied with the product. After 7 days, no refunds will be issued.<br>
    Cancellation Policy: You can cancel your subscription at any time. The cancellation will take effect at the end of your current billing cycle.
  `;

  const inputFieldStyles = {
    width: "80%", // adjust the width as needed
    fontSize: "14px", // smaller font size for input text
    "& .MuiInputBase-root": {
      height: "35px", // adjust the height of the input field
      padding: "0 0px", // adjust padding inside the input field
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)", // default border color
    },
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <PrimarySearchAppBar />
      <Container component="main" maxWidth="xs" sx={{ flexGrow: 1 }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="60vh"
        >
          <Typography
            sx={{
              fontSize: "25px",
              fontWeight: 600,
              mb: 3,
              mt:5,
              lineHeight: "50px",
              marginLeft: "-50px",
              "@media (max-width: 425px)": {
                marginLeft: "20px",
              },
            }}
          >
            Get started with Advoref
          </Typography>

          <Box sx={{ width: "100%", mb: 2, marginLeft: "40px" }}>
            <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
              Email Address
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!errors.email}
              helperText={errors.email}
              sx={inputFieldStyles}
            />
          </Box>

          <Box sx={{ width: "100%", mb: 2, marginLeft: "40px" }}>
            <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
              Password
            </Typography>
            <TextField
              fullWidth
              type={showPassword ? "text" : "password"}
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!errors.password}
              helperText={errors.password}
              sx={inputFieldStyles}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                    aria-label="toggle password visibility"
                    style={{ marginRight: "2px" }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
          </Box>

          <Box sx={{ width: "100%", textAlign: "left", marginLeft: "40px" }}>
            <Button
              variant="contained"
              sx={{
                width: "140px",
                height: "44px",
                borderRadius: "6px",
                mt: 2,
                mb: 2,
                background: "#0E1030",
                "&:hover": {
                  backgroundColor: "#0E1030",
                },
              }}
              onClick={handleSubmit}
            >
              Sign Up
            </Button>

            <Typography
              variant="body2"
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                mt: 2,
                mb: 2,
                textAlign: "left",
              }}
            >
              By clicking Sign Up, I agree to Advoref{" "}
              <Box component="span" sx={{ mr: 0.5 }}>
                <Button
                  variant="text"
                  sx={{
                    textDecoration: "underline",
                    padding: 0,
                    minWidth: "auto",
                    textTransform: "none",
                  }}
                  onClick={handleOpenModal}
                >
                  Terms
                </Button>
              </Box>
              <Box component="span" sx={{ mx: 0.5 }}>
                and
              </Box>
              <Box component="span">
                <Button
                  variant="text"
                  sx={{
                    textDecoration: "underline",
                    padding: 0,
                    minWidth: "auto",
                    textTransform: "none",
                  }}
                  onClick={handleOpenModal2}
                >
                  Privacy Policy
                </Button>
              </Box>
              .
            </Typography>

            {/* Modal for Terms */}
            <Modal open={open} onClose={handleCloseModal}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 1000,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: 2,
                }}
              >
                <Typography
                  style={{ fontSize: "18px", fontWeight: 700 }}
                  component="h2"
                >
                  Terms and Conditions
                </Typography>
                <Typography
                  sx={{ mt: 2, fontSize: "14px" }}
                  dangerouslySetInnerHTML={{
                    __html: termsAndConditionsContent,
                  }}
                />

                <Button
                  variant="contained"
                  sx={{
                    width: "100px",
                    height: "40px",
                    borderRadius: "6px",
                    mt: 2,
                    background: "#0E1030",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#0E1030",
                    },
                  }}
                  onClick={handleCloseModal}
                >
                  Close
                </Button>
              </Box>
            </Modal>

            {/* Modal for Privacy */}
            <Modal open={open2} onClose={handleCloseModal2}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 1000,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: 2,
                }}
              >
                <Typography
                  style={{ fontSize: "18px", fontWeight: 700 }}
                  component="h2"
                >
                  Privacy Policy
                </Typography>
                <Typography
                  sx={{ mt: 2, fontSize: "14px" }}
                  dangerouslySetInnerHTML={{
                    __html: privacyPolicyContent,
                  }}
                />

                <Button
                  variant="contained"
                  sx={{
                    width: "100px",
                    height: "40px",
                    borderRadius: "6px",
                    mt: 2,
                    background: "#0E1030",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#0E1030",
                    },
                  }}
                  onClick={handleCloseModal2}
                >
                  Close
                </Button>
              </Box>
            </Modal>

            <Typography sx={{ fontSize: "12px", fontWeight: 400, mt: 2 }}>
              Already have an account?
              <Link
                // href="/login"
                onClick={handleLogin}
                sx={{
                  fontSize: "12px",
                  fontWeight: 600,
                  ml: 1,
                  cursor: "pointer",
                }}
              >
                Log In
              </Link>
            </Typography>
          </Box>
        </Box>
      </Container>

      <Footer />

      {/* <Modal
        open={otpModalOpen}
        onClose={() => {}}
        aria-labelledby="otp-modal-title"
        aria-describedby="otp-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
            textAlign: "center",
          }}
        >
          <Typography
            id="otp-modal-title"
            variant="h5"
            component="h2"
            sx={{
              color: "#0E1030",
              mb: 2,
              fontSize: "28px",
              fontWeight: "600",
              textTransform: "none",
            }}
          >
            Enter OTP
          </Typography>
          <Typography id="otp-modal-description" sx={{ mb: 3 }}>
            Please enter the 6-digit code sent to your email address
          </Typography>
          <Grid container spacing={1} justifyContent="center">
            {otp.map((digit, index) => (
              <Grid item key={index}>
                <TextField
                  variant="outlined"
                  value={digit}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: "center" },
                  }}
                  inputRef={(el) => (otpRefs.current[index] = el)}
                  sx={{ width: 50, height: 50 }}
                />
              </Grid>
            ))}
          </Grid>
          <Typography sx={{ mt: 2, mb: 3 }} variant="body2">
            Resend OTP: {" "}
            {countdown > 0 ? (
              `${countdown} seconds`
            ) : (
              <Link onClick={handleResendOtp} sx={{cursor:"pointer"}}>click here</Link>
            )}
          </Typography>
          <Button
            variant="contained"
            sx={{ bgcolor: "#0E1030", "&:hover": { bgcolor: "#0E1030" } }}
            onClick={handleVerifyOtp}
          >
            Enter
          </Button>
        </Box>
      </Modal> */}

{/* <Modal
      open={otpModalOpen}
      onClose={handleClose} // Closes the modal when called
      aria-labelledby="otp-modal-title"
      aria-describedby="otp-modal-description"
      disableBackdropClick={true}  // Prevents closing on backdrop click
      disableEscapeKeyDown={true}  // Prevents closing on pressing Escape key
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 5,
          textAlign: "center",
          position: "relative",
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "#0E1030",
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography
          id="otp-modal-title"
          variant="h5"
          component="h2"
          sx={{
            color: "#0E1030",
            mb: 2,
            fontSize: "28px",
            fontWeight: "600",
            textTransform: "none",
          }}
        >
          Enter OTP
        </Typography>
        <Typography id="otp-modal-description" sx={{ mb: 3 }}>
          Please enter the 6-digit code sent to your email address
        </Typography>
        <Grid container spacing={1} justifyContent="center">
          {otp.map((digit, index) => (
            <Grid item key={index}>
              <TextField
                variant="outlined"
                value={digit}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                inputProps={{
                  maxLength: 1,
                  style: { textAlign: "center" },
                }}
                inputRef={(el) => (otpRefs.current[index] = el)}
                sx={{ width: 50, height: 50 }}
              />
            </Grid>
          ))}
        </Grid>
        <Typography sx={{ mt: 2, mb: 3 }} variant="body2">
  Resend OTP:{" "}
  {countdown > 0 ? (
    `${countdown} seconds`
  ) : isResendingOtp ? (
    <CircularProgress size={20} /> // Show loading spinner when OTP is being resent
  ) : (
    <Link onClick={handleResendOtp} sx={{ cursor: "pointer" }}>
      click here
    </Link>
  )}
</Typography>

        <Button
          variant="contained"
          sx={{ bgcolor: "#0E1030", "&:hover": { bgcolor: "#0E1030" } }}
          onClick={handleVerifyOtp}
        >
          Enter
        </Button>
      </Box>
    </Modal> */}

<Modal
        open={otpModalOpen}
        onClose={handleClose}
        aria-labelledby="otp-modal-title"
        aria-describedby="otp-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
            textAlign: "center",
            position: "relative",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "#0E1030",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            id="otp-modal-title"
            variant="h5"
            component="h2"
            sx={{
              color: "#0E1030",
              mb: 2,
              fontSize: "28px",
              fontWeight: "600",
              textTransform: "none",
            }}
          >
            Enter OTP
          </Typography>
          <Typography id="otp-modal-description" sx={{ mb: 3 }}>
            Please enter the 6-digit code sent to your email address
          </Typography>
          <Grid container spacing={1} justifyContent="center">
            {otp.map((digit, index) => (
              <Grid item key={index}>
                <TextField
                  variant="outlined"
                  value={digit}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: "center" },
                  }}
                  inputRef={(el) => (otpRefs.current[index] = el)}
                  sx={{ width: 50, height: 50 }}
                />
              </Grid>
            ))}
          </Grid>
          <Typography sx={{ mt: 2, mb: 3 }} variant="body2">
            Resend OTP:{" "}
            {countdown > 0 ? (
              `${countdown} seconds`
            ) : (
              <Link onClick={handleResendOtp} sx={{ cursor: "pointer" }}>
                click here
              </Link>
            )}
          </Typography>
          <Button
            variant="contained"
            sx={{ bgcolor: "#0E1030", textTransform:"none", "&:hover": { bgcolor: "#0E1030" } }}
            onClick={handleVerifyOtp}
          >
            Verify OTP
          </Button>
        </Box>
      </Modal>

      {/* Full-screen loading overlay with circular progress */}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={isVerifyingOtp || isResendingOtp}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default SignUpPage;
