
import React from 'react';
import { Box, Skeleton } from '@mui/material';

const ShimmerLoading = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Skeleton variant="rectangular" width="100%" height={56} />
      <Skeleton variant="text" width="60%" />
      <Skeleton variant="text" width="80%" />
      <Skeleton variant="text" width="40%" />
      <Skeleton variant="rectangular" width="100%" height={150} />
    </Box>
  );
};

export default ShimmerLoading;