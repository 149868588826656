// import React, { useState } from "react";
// import {
//   Container,
//   Typography,
//   TextField,
//   Button,
//   Box,
//   CircularProgress,
// } from "@mui/material";
// import PrimarySearchAppBar from "../NavBar2/NavBar2";
// import Footer from "../Footer/Footer2";
// import { toast } from "react-toastify";
// import axios from "axios";
// import servicePath from "../Constant/defaultvalues";
// import { useNavigate } from "react-router-dom";

// // Forgot Password component 

// function ForgotPass() {
//   const [email, setEmail] = useState("");
//   const [code, setCode] = useState("");
//   const [isGetCodeVisible, setIsGetCodeVisible] = useState(false);
//   const [isCodeVerified, setIsCodeVerified] = useState(false);
//   const [isEmailErr, setIsEmailErr] = useState(false);
//   const [isCodeErr, setIsCodeErr] = useState(false);
//   const [isLoading, setIsLoading] = useState(false); // Add isLoading state
//   const navigate = useNavigate();
//   const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

//   const handleGetCode = async () => {
//     setIsEmailErr(false);
//     if (!email || !isValidEmail.test(email)) {
//       toast.error("Enter valid email", {
//         position: "top-right",
//         autoClose: 2000,
//       });
//       setIsEmailErr(true);
//       return;
//     }
    
//     try {
//       setIsLoading(true); // Start loading spinner
//       const response = await axios.post(`${servicePath}/advorefforgot-password`, {
//         email,
//       });
      
//       // Move these inside the try block, after the axios call
//       setIsGetCodeVisible(true);
//       toast.success("Password reset code sent to your email.", {
//         position: "top-right",
//         autoClose: 2000,
//       });
//     } catch (error) {
//       if (error.response && error.response.status === 404) {
//         toast.error("Email not found", {
//           position: "top-right",
//           autoClose: 2000,
//         });
//       } else {
//         toast.error("Error sending password reset code.", {
//           position: "top-right",
//           autoClose: 2000,
//         });
//       }
//     } finally {
//       setIsLoading(false); // Stop loading spinner
//     }
//   };
  
//   const handleVerifyCode = async () => {
//     setIsCodeErr(false);
//     if (!code) {
//       toast.error("Enter Code", {
//         position: "top-right",
//         autoClose: 2000,
//       });
//       setIsCodeErr(true);
//       return;
//     }

//     try {
//       setIsLoading(true); // Start loading spinner

//       const response = await axios.post(`${servicePath}/advorefverify-code`, {
//         email,
//         code,
//       });

//       if (response.status === 200) {
//         setIsCodeVerified(true);
//         toast.success("Code Verified Successfully!", {
//           position: "top-right",
//           autoClose: 2000,
//         });
//         // Save email and code to local storage
//         localStorage.setItem("resetEmail", email);
//         localStorage.setItem("resetCode", code);
//         navigate("/resetpass");
//       } else {
//         throw new Error("Invalid code");
//       }
//     } catch (error) {
//       console.error("Error verifying code:", error);
//       toast.error("Enter Valid code", {
//         position: "top-right",
//         autoClose: 2000,
//       });
//       setIsCodeErr(true);
//       setIsCodeVerified(false);
//     } finally {
//       setIsLoading(false); // Stop loading spinner
//     }
//   };

//   const inputFieldStyles = {
//     width: "80%", // adjust the width as needed
//     fontSize: "14px", // smaller font size for input text
//     "& .MuiInputBase-root": {
//       height: "35px", // adjust the height of the input field
//       padding: "0 0px", // adjust padding inside the input field
//     },
//     "& .MuiOutlinedInput-notchedOutline": {
//       borderColor: "rgba(0, 0, 0, 0.23)", // default border color
//     },
//   };

//   return (
//     <Box display="flex" flexDirection="column" minHeight="100vh">
//       <PrimarySearchAppBar />
//       <Container maxWidth="xs" sx={{ flexGrow: 1 }}>
//         <Box
//           display="flex"
//           flexDirection="column"
//           alignItems="center"
//           justifyContent="center"
//           minHeight="60vh"
//         >
//           <Typography
//             sx={{
//               fontSize: "25px",
//               fontWeight: 600,
//               mb: 1,
//               lineHeight: "80px",
//             }}
//           >
//             Reset Password
//           </Typography>

//           <Box sx={{ width: "100%", mb: 2 }}>
//             <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
//               Email Address
//             </Typography>
//             <TextField
//               fullWidth
//               variant="outlined"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               error={isEmailErr}
//               helperText={isEmailErr ? "Please enter a valid email" : ""}
//               sx={inputFieldStyles}
//             />
//           </Box>

//           {isGetCodeVisible && (
//             <Box sx={{ width: "100%", mb: 2 }}>
//               <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
//                 Verification Code
//               </Typography>
//               <TextField
//                 fullWidth
//                 variant="outlined"
//                 value={code}
//                 onChange={(e) => {
//                   const input = e.target.value;
//                   // Only allow digits and ensure the length is 6 or less
//                   if (/^\d{0,6}$/.test(input)) {
//                     setCode(input);
//                   }
//                 }}
//                 inputProps={{
//                   maxLength: 6, // Limit the input length to 6 digits
//                 }}
//                 error={isCodeErr}
//                 helperText={isCodeErr ? "Please enter the correct code" : ""}
//               />
//             </Box>
//           )}

//           <Box sx={{ width: "100%", textAlign: "left" }}>
//             {isGetCodeVisible ? (
//               <Button
//                 variant="contained"
//                 sx={{
//                   width: "140px",
//                   height: "44px",
//                   borderRadius: "6px",
//                   mt: 3,
//                   mb: 2,
//                   background: "#0E1030",
//                   "&:hover": {
//                     backgroundColor: "#0E1030",
//                   },
//                 }}
//                 onClick={handleVerifyCode}
//                 disabled={isLoading} // Disable button when loading
//               >
//                 Verify Code
//               </Button>
//             ) : (
//               <Button
//                 variant="contained"
//                 sx={{
//                   width: "140px",
//                   height: "44px",
//                   borderRadius: "6px",
//                   mt: 3,
//                   mb: 2,
//                   background: "#0E1030",
//                   "&:hover": {
//                     backgroundColor: "#0E1030",
//                   },
//                 }}
//                 onClick={handleGetCode}
//                 disabled={isLoading} // Disable button when loading
//               >
//                 Get Code
//               </Button>
//             )}
//           </Box>
//         </Box>
//       </Container>
//       <Footer />

//       {isLoading && (
//         <Box
//           sx={{
//             position: "fixed",
//             top: 0,
//             left: 0,
//             width: "100%",
//             height: "100%",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             backgroundColor: "rgba(255, 255, 255, 0.7)",
//             zIndex: 1000,
//           }}
//         >
//           <CircularProgress size={80} />
//         </Box>
//       )}
//     </Box>
//   );
// }

// export default ForgotPass;


import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import PrimarySearchAppBar from "../NavBar2/NavBar2";
import Footer from "../Footer/Footer2";
import { toast } from "react-toastify";
import axios from "axios";
import servicePath from "../Constant/defaultvalues";
import { useNavigate } from "react-router-dom";

// Forgot Password component 

function ForgotPass() {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [isGetCodeVisible, setIsGetCodeVisible] = useState(false);
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const [isEmailErr, setIsEmailErr] = useState(false);
  const [isCodeErr, setIsCodeErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const navigate = useNavigate();
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const handleGetCode = async () => {
    // Reset the email error state to false initially
    setIsEmailErr(false);
  
    // Check if the email is not entered or invalid
    if (!email || !isValidEmail.test(email)) {
      // Show a toast notification to alert the user to enter a valid email
      toast.error("Enter valid email", {
        position: "top-right",
        autoClose: 2000,
      });
  
      // Set the email error state to true to highlight the email field
      setIsEmailErr(true);
      return; // Stop further execution if the email is invalid
    }
    
    try {
      // Set the loading state to true to show a loading spinner
      setIsLoading(true);
  
      // Send a POST request to the server to send a password reset code to the entered email
      const response = await axios.post(`${servicePath}/advorefforgot-password`, { email });
      
      // If successful, show the code input field and notify the user
      setIsGetCodeVisible(true); // Make the "Enter code" input field visible
      toast.success("Password reset code sent to your email.", {
        position: "top-right",
        autoClose: 2000,
      });
    } catch (error) {
      // If the server returns a 404 error, email not found in the system
      if (error.response && error.response.status === 404) {
        toast.error("Email not found", {
          position: "top-right",
          autoClose: 2000,
        });
      } else {
        // Handle any other errors (network issues, etc.)
        toast.error("Error sending password reset code.", {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } finally {
      // Set loading state to false to stop showing the loading spinner
      setIsLoading(false);
    }
  };
  
  // const handleVerifyCode = async () => {
  //   // Reset the code error state to false initially
  //   setIsCodeErr(false);
  
  //   // Check if the user has entered a code
  //   if (!code) {
  //     // Notify the user to enter a code
  //     toast.error("Enter Code", {
  //       position: "top-right",
  //       autoClose: 2000,
  //     });
  
  //     // Set the code error state to true to highlight the code input field
  //     setIsCodeErr(true);
  //     return; // Stop further execution if no code is entered
  //   }
  
  //   try {
  //     // Set the loading state to true to show a loading spinner
  //     setIsLoading(true);
  
  //     // Send a POST request to verify the entered code and email
  //     const response = await axios.post(`${servicePath}/advorefverify-code`, {
  //       email,  // Include the email address in the request payload
  //       code,   // Include the reset code in the request payload
  //     });
  
  //     // If the server returns a 200 status, code verification is successful
  //     if (response.status === 200) {
  //       setIsCodeVerified(true); // Mark the code as verified
  //       toast.success("Code Verified Successfully!", {
  //         position: "top-right",
  //         autoClose: 2000,
  //       });
  
  //       // Save the email and code to local storage for later use
  //       localStorage.setItem("resetEmail", email);
  //       localStorage.setItem("resetCode", code);
  
  //       // Redirect the user to the password reset page
  //       navigate("/resetpass");
  //     } else {
  //       // If the status is not 200, assume the code is invalid
  //       throw new Error("Invalid code");
  //     }
  //   } catch (error) {
  //     console.error("Error verifying code:", error); // Log the error to the console
  //     // Notify the user that the code is invalid
  //     toast.error("Enter Valid code", {
  //       position: "top-right",
  //       autoClose: 2000,
  //     });
  
  //     // Set the code error state to true to indicate an invalid code
  //     setIsCodeErr(true);
  //     setIsCodeVerified(false); // Mark the code as not verified
  //   } finally {
  //     // Set loading state to false to stop showing the loading spinner
  //     setIsLoading(false);
  //   }
  // };
  
  const handleVerifyCode = async () => {
    setIsCodeErr(false);

    // Check if the user has entered a code
    if (!code) {
        toast.error("Enter Code", {
            position: "top-right",
            autoClose: 2000,
        });

        setIsCodeErr(true);
        return;
    }

    try {
        setIsLoading(true);

        console.log("Verifying code for email:", email, "with code:", code);

        // Send a POST request to verify the entered code and email
        const response = await axios.post(`${servicePath}/advorefverify-code`, {
            email,  // Ensure email is properly set
            code,   // Ensure code is properly set
        });

        if (response.status === 200) {
            setIsCodeVerified(true);
            toast.success("Code Verified Successfully!", {
                position: "top-right",
                autoClose: 2000,
            });

            localStorage.setItem("resetEmail", email);
            localStorage.setItem("resetCode", code);
            navigate("/resetpass");
        } else {
            throw new Error("Invalid code");
        }
    } catch (error) {
        console.error("Error verifying code:", error);
        toast.error("Enter Valid code", {
            position: "top-right",
            autoClose: 2000,
        });

        setIsCodeErr(true);
        setIsCodeVerified(false);
    } finally {
        setIsLoading(false);
    }
};


  const inputFieldStyles = {
    width: "80%", // adjust the width as needed
    fontSize: "14px", // smaller font size for input text
    "& .MuiInputBase-root": {
      height: "35px", // adjust the height of the input field
      padding: "0 0px", // adjust padding inside the input field
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)", // default border color
    },
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <PrimarySearchAppBar />
      <Container maxWidth="xs" sx={{ flexGrow: 1 }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="60vh"
        >
          <Typography
            sx={{
              fontSize: "25px",
              fontWeight: 600,
              mb: 1,
              lineHeight: "80px",
            }}
          >
            Reset Password
          </Typography>

          <Box sx={{ width: "100%", mb: 2 }}>
            <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
              Email Address
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={isEmailErr}
              helperText={isEmailErr ? "Please enter a valid email" : ""}
              sx={inputFieldStyles}
            />
          </Box>

          {isGetCodeVisible && (
            <Box sx={{ width: "100%", mb: 2 }}>
              <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
                Verification Code
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                value={code}
                sx={inputFieldStyles}
                onChange={(e) => {
                  const input = e.target.value;
                  // Only allow digits and ensure the length is 6 or less
                  if (/^\d{0,6}$/.test(input)) {
                    setCode(input);
                  }
                }}
                inputProps={{
                  maxLength: 6, // Limit the input length to 6 digits
                }}
                error={isCodeErr}
                helperText={isCodeErr ? "Please enter the correct code" : ""}
              />
            </Box>
          )}

          <Box sx={{ width: "100%", textAlign: "left" }}>
            {isGetCodeVisible ? (
              <Button
                variant="contained"
                sx={{
                  width: "140px",
                  height: "44px",
                  borderRadius: "6px",
                  mt: 3,
                  mb: 2,
                  background: "#0E1030",
                  "&:hover": {
                    backgroundColor: "#0E1030",
                  },
                }}
                onClick={handleVerifyCode}
                disabled={isLoading} // Disable button when loading
              >
                Verify Code
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{
                  width: "140px",
                  height: "44px",
                  borderRadius: "6px",
                  mt: 3,
                  mb: 2,
                  background: "#0E1030",
                  "&:hover": {
                    backgroundColor: "#0E1030",
                  },
                }}
                onClick={handleGetCode}
                disabled={isLoading} // Disable button when loading
              >
                Get Code
              </Button>
            )}
          </Box>
        </Box>
      </Container>
      <Footer />

      {isLoading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 1000,
          }}
        >
          <CircularProgress size={80} />
        </Box>
      )}
    </Box>
  );
}

export default ForgotPass;

