
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Main from "./Components/Main/Main";
import Footer from "./Components/Footer/Footer";
import SignUpPage from "./Components/SignUp/SignUp";
import Login from "./Components/LogIn/Login";
import Cart from "./Components/Cart/Cart";
import Pcart from "./Components/Cart/Pcart";
import PPcart from "./Components/Cart/PPcart";
import Checkout from "./Components/Checkout/Checkout";
import Card from "./Components/Card/Card";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import Org from "./Components/Org/Org";
import GetStarted from "./Components/GetStarted/GetStarted";
import ForgotPass from "./Components/ForgotPassword/ForgotPass";
import ResetPass from "./Components/ForgotPassword/ResetPass";
import Profile from "./Components/Profile/Profile";
import EditProfile from "./Components/Profile/EditProfile";
import TrialForm from "./Components/Trial/Form";
import StartTrail from "./Components/Trial/StratTrail";
import ProfileForm from "./Components/Profile/ProfileForm";
import Terms from "./Components/Terms/Terms";
import Contact from "./Components/Contact/Contact";
import Privacy from "./Components/Terms/Privacy";
import TimeOut from "./TimeOut";
import { useNavigate } from "react-router-dom";
import Knowmore from "./Components/Knowmore/Knowmore";
import Discover from "./Components/Discover/Discover";
import Pricing from "./Components/Details/PricingPolicy";
import Cancellation from "./Components/Details/Cancellation"
import AboutUs from "./Components/Details/AboutUs";
import ThankYou from "./Components/Contact/ThankYou";

const protectedRoute = ({ Component, path }) => {
  const subscription_token = localStorage.getItem("subscriptionToken");

  return (
    <Route
      // path={path}
      // element={
      //   subscription_token !== null ? <Component /> : <Navigate to="/login" 
      //   />
      // }
    />
  );
};

function App() {

  return (
    <Router>
      <ScrollToTop />
      <TimeOut />
      <div>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/home" element={<Main />} />
          <Route path="/footer" element={<Footer />} />
          {/* <Route path="/navbar" element={<NavBar />} /> */}
          {/* <Route path="/navbar2" element={<NavBar2 />} /> */}
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/profileform" element={<ProfileForm />} />
          <Route path="/login" element={<Login />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/pcart" element={<Pcart />} />
          <Route path="/ppcart" element={<PPcart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/card" element={<Card />} />

          {/* {protectedRoute({ Component: Cart, path: "/cart" })}
          {protectedRoute({ Component: Card, path: "/card" })}
          {protectedRoute({ Component: Checkout, path: "/checkout" })}
          {protectedRoute({ Component: Org, path: "/org" })}
          {protectedRoute({ Component: GetStarted, path: "/getstarted" })}
          {protectedRoute({ Component: Profile, path: "/Profile" })}
          {protectedRoute({ Component: EditProfile, path: "/editprofile" })} */}

          <Route path="/org" element={<Org />} />

          <Route path="/knowmore" element={<Knowmore />} />
          <Route path="/discover" element={<Discover />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/cancellation" element={<Cancellation />} />
          <Route path="/aboutus" element={<AboutUs />} />

          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/thank-you" element={<ThankYou />} />

          <Route path="/trialform" element={<TrialForm />} />
          <Route path="/getstarted" element={<GetStarted />} />
          <Route path="/starttrail" element={<StartTrail />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/editprofile" element={<EditProfile />} />
          <Route path="/forgotpass" element={<ForgotPass />} />
          <Route path="/resetpass" element={<ResetPass />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
