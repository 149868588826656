

import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Link as ScrollLink } from 'react-scroll';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import './NavBar.css';
import Logobluegray from '../../assets/Logobluegray.png'
import Adovref from "../../assets/Advoref.png"

// NavBar component for Landing page

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:435px)');
  const [activeSection, setActiveSection] = useState('home');

  useEffect(() => {
    const token = localStorage.getItem('subscriptionToken');
    setIsLoggedIn(!!token);
  }, []);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    navigate('/login');      // Redirect the user to the login page
    handleMenuClose();        // Close any open menus ( dropdowns or side menu)
    setDrawerOpen(false);     // Close the navigation drawer
  };
  

  const handleSignUp = () => {
    navigate('/signup');      // Redirect the user to the signup page
    handleMenuClose();        // Close any open menus ( dropdowns or side menus)
    setDrawerOpen(false);     // Close the navigation drawer (if open)
  };
  

  const handleProfile = () => {
    navigate('/profile');
    handleMenuClose();
    setDrawerOpen(false);
  };

  const handleLogout = () => {
    setOpenLogoutDialog(true);  // Show a confirmation dialog for logout
    handleMenuClose();          // Close any open menus ( dropdowns or side menus)
  };
  

  const handleLogoutConfirm = () => {
    localStorage.removeItem('subscriptionToken'); // Remove subscription token from localStorage
    localStorage.removeItem('admin_id');          // Remove admin ID from localStorage
    localStorage.removeItem('tabId');             // Remove tab ID from localStorage
    localStorage.removeItem('org_id');            // Remove organization ID from localStorage
    localStorage.removeItem('userDetails');       // Remove user details from localStorage
    console.log('User logged out');               // Log a message to confirm logout
    setOpenLogoutDialog(false);                   // Close the logout confirmation dialog
    setIsLoggedIn(false);                         // Update the state to mark the user as logged out
    navigate('/login');                           // Redirect the user to the login page after logout
    setDrawerOpen(false);                         // Close the navigation drawer (if open)
  };
  

  const handleLogoutCancel = () => {
    setOpenLogoutDialog(false);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const menuItems = (
    <>
      <ScrollLink to="home" smooth={true} duration={500} offset={-70}>
        <ListItem button onClick={() => setDrawerOpen(false)}>
          <ListItemText primary="Home" />
        </ListItem>
      </ScrollLink>
      <ScrollLink to="how-it-works" smooth={true} duration={500} offset={-70}>
        <ListItem button onClick={() => setDrawerOpen(false)}>
          <ListItemText primary="How it Works" />
        </ListItem>
      </ScrollLink>
      <ScrollLink to="subscription" smooth={true} duration={500} offset={-70}>
        <ListItem button onClick={() => setDrawerOpen(false)}>
          <ListItemText primary="Subscription" />
        </ListItem>
      </ScrollLink>
      <ListItem button>
        <NotificationsIcon />
      </ListItem>
      <ListItem button>
        <HelpOutlineIcon />
      </ListItem>
      {!isLoggedIn ? (
        <>
          <ListItem button onClick={handleLogin}>
            <ListItemText primary="Login" />
          </ListItem>
          <ListItem button onClick={handleSignUp}>
            <ListItemText primary="Sign Up" />
          </ListItem>
        </>
      ) : (
        <>
          <ListItem button onClick={handleProfile}>
            <ListItemText primary="Profile" />
          </ListItem>
          <ListItem button onClick={handleLogout}>
            <ListItemText primary="Logout" />
          </ListItem>
        </>
      )}
    </>
  );

  useEffect(() => {
    const handleScroll = () => {
      const sections = ['home', 'how-it-works', 'subscription'];
      const offsets = sections.map(section => {
        const element = document.getElementById(section);
        return element ? element.offsetTop : 0;
      });

      const scrollPosition = window.pageYOffset + 100; // Adjust offset as needed

      for (let i = sections.length - 1; i >= 0; i--) {
        if (scrollPosition >= offsets[i]) {
          setActiveSection(sections[i]);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <AppBar position="fixed"
      //  color="transparent" elevation={0}
      color="default"
      sx={{ backgroundColor: 'white', boxShadow: '0px 4px 2px -2px gray' }}
      >
        <Toolbar disableGutters>
          {isMobile && (
            <Button onClick={toggleDrawer(true)}>
              <MenuIcon sx={{ color: 'black' }} />
            </Button>
          )}
          <div style={{ margin:'10px', marginLeft:'20px'}}>
            <Button sx={{margin:"-10px"}}>

          <img src={Adovref} alt="Adovref" style={{ height: '40px'}} />
            </Button>
          </div>
          {!isMobile && (
            <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', marginRight: '20px' }}>
              <ScrollLink to="home" smooth={true} duration={500} offset={-70}>
        <Button
          sx={{
            textTransform: 'none',
            fontSize: '14px',
            color:'#0E1030',
            fontWeight: activeSection === 'home' ? 'bold' : 'normal',
            textDecoration: activeSection === 'home' ? 'underline' : 'none',
          }}
          color="inherit"
        >
          Home
        </Button>
      </ScrollLink>

      <ScrollLink to="how-it-works" smooth={true} duration={500} offset={-70}>
        <Button
          sx={{
            textTransform: 'none',
            fontWeight: activeSection === 'how-it-works' ? 'bold' : 'normal',
            color:'#0E1030',
            textDecoration: activeSection === 'how-it-works' ? 'underline' : 'none',
          }}
          color="inherit"
        >
          How it Works
        </Button>
      </ScrollLink>

      <ScrollLink to="subscription" smooth={true} duration={500} offset={-70}>
        <Button
          sx={{
            textTransform: 'none',
            fontWeight: activeSection === 'subscription' ? 'bold' : 'normal',
            color:'#0E1030',
            textDecoration: activeSection === 'subscription' ? 'underline' : 'none',
          }}
          color="inherit"
        >
          Subscription
        </Button>
      </ScrollLink>
              {/* <button className="iconButton">
                <NotificationsIcon />
              </button>
              <button className="iconButton">
                <HelpOutlineIcon />
              </button> */}
              <button className="iconButton" onClick={handleMenuClick}>
                <AccountCircleIcon sx={{color:'#0E1030',}}/>
              </button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {!isLoggedIn ? (
                  <>
                    <MenuItem onClick={handleLogin}>Login</MenuItem>
                    <MenuItem onClick={handleSignUp}>Sign Up</MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem onClick={handleProfile}>Profile</MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </>
                )}
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>
          {menuItems}
        </List>
      </Drawer>

      <Dialog
        open={openLogoutDialog}
        onClose={handleLogoutCancel}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
      >
        <DialogTitle id="logout-dialog-title">Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-dialog-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogoutCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogoutConfirm} color="primary" autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Navbar;









