import React from "react";
import { Container, Typography, Box } from "@mui/material";
import PrimarySearchAppBar from "../NavBar2/NavBar2"; 
import Footer2 from "../Footer/Footer2"; 

const ThankYou = () => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <PrimarySearchAppBar />
      <Container sx={{ flexGrow: 1, marginTop: "50px", textAlign: "center" }}>
        <Typography variant="h4" gutterBottom>
          Thank You!
        </Typography>
        <Typography variant="h6" gutterBottom>
          Your message has been recorded.
        </Typography>
        <Typography variant="body1" gutterBottom>
          You will receive an email soon from our support team.
        </Typography>
      </Container>
      <Footer2 />
    </Box>
  );
};

export default ThankYou;