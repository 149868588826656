import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// ScrollToTop component scrolls the page to the top whenever the route changes
const ScrollToTop = () => {
  // Get the current pathname (URL) using the 'useLocation' hook
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to the top of the page whenever the pathname changes
    window.scrollTo(0, 0);
  }, [pathname]); 

  return null; 
};

export default ScrollToTop;
