import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const TimeOut = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('subscriptionToken');
    localStorage.removeItem('admin_id');
    localStorage.removeItem('tabId');
    localStorage.removeItem('org_id');
    localStorage.removeItem('userDetails');
    console.log('User logged out');
    navigate('/home'); // Redirect to the login page
  };

  useEffect(() => {
    // let timer = setTimeout(handleLogout, 300000); // 5 minutes = 300000 ms
    let timer = setTimeout(handleLogout, 600000); // 10 minutes = 600000 ms

    const resetTimer = () => {
      clearTimeout(timer);
      // timer = setTimeout(handleLogout, 300000);
      timer = setTimeout(handleLogout, 600000);
    };

    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('scroll', resetTimer);
    window.addEventListener('click', resetTimer);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
      window.removeEventListener('scroll', resetTimer);
      window.removeEventListener('click', resetTimer);
    };
  }, []);

  return null;
};

export default TimeOut;
