import React, { useState, useEffect } from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import PrimarySearchAppBar from "../NavBar2/NavBar2";
import Footer2 from "../Footer/Footer2";
import gettingstarted from "../../assets/gettingstarted.png";
import { useNavigate } from 'react-router-dom';

// Get Started page: to be shown when payment is successfull 

function GetStarted() {

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('subscriptionToken');
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <PrimarySearchAppBar />
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "50vh",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: 700,
              textAlign: "center",
              marginTop: "32px",
            }}
            gutterBottom
          >
            Thank you for choosing Advoref
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              marginTop: "15px",
              marginBottom: "50px",
            }}
          >
            From adding client details and property records to ensuring seamless
            fee payments, our software offers a hassle-free experience. Access
            all the information you need, anytime, anywhere.
          </Typography>
          <img
            src={gettingstarted}
            alt="IMG"
            style={{ width: "60%", height: "auto" }}
          />
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0E1030",
              marginTop: "20px",
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: "#0E1030",
              },
            }}
            href="#"
          >
            <Typography
              sx={{ fontSize: "16px", fontWeight: 600, padding: "6px" }}
            >
              Get Started
            </Typography>
          </Button>
        </Box>
      </Container>
      <Footer2 />
    </div>
  );
}

export default GetStarted;
