import React, { useState } from "react";
import axios from "axios";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Box,
} from "@mui/material";
import PrimarySearchAppBar from "../NavBar2/NavBar2";
import Footer2 from "../Footer/Footer2";
import { useNavigate } from "react-router-dom";
import office from "../../assets/office.webp";
import servicePath from "../Constant/defaultvalues";

function Contact() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear error message when user starts typing
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = { name: "", email: "", message: "" };

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      valid = false;
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is not valid";
      valid = false;
    }
    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await axios.post(`${servicePath}/inquiry`, formData);
        console.log(response.data);
        alert("Your message has been sent successfully!");
        navigate("/thank-you");
      } catch (error) {
        console.error("There was an error sending the inquiry:", error);
        alert("There was an error sending your message. Please try again later.");
      }
    }
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <PrimarySearchAppBar />
      <Container sx={{ flexGrow: 1, marginTop: "50px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <img
              src={office}
              alt="Office Building"
              style={{
                width: "100%",
                height: "auto",
                marginTop: "60px",
              }}
            />
            <Typography mt={2}><b>Address:</b> Mohan Nagar, Nagpur, Maharashtra 440001</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              sx={{
                borderLeft: "4px solid #0E1030",
                paddingLeft: "10px",
                color: "#0E1030",
              }}
            >
              CONTACT <strong>US</strong>
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Name"
                name="name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.name}
                onChange={handleChange}
                error={Boolean(errors.name)}
                helperText={errors.name}
              />
              <TextField
                label="Email"
                name="email"
                type="email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.email}
                onChange={handleChange}
                error={Boolean(errors.email)}
                helperText={errors.email}
              />
              <TextField
                label="Message"
                name="message"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                margin="normal"
                value={formData.message}
                onChange={handleChange}
                error={Boolean(errors.message)}
                helperText={errors.message}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "#0E1030",
                  color: "white",
                  borderRadius: "5px",
                  marginTop: "20px",
                  padding: "10px 20px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#0E1030",
                  },
                }}
              >
                Send Message
              </Button>
            </form>
          </Grid>
        </Grid>
      </Container>
      <Footer2 />
    </Box>
  );
}

export default Contact;