// import React from "react";
// import { Grid, Button, Typography, Link, useMediaQuery } from "@mui/material";
// import { styled } from "@mui/system";
// import { useTheme } from "@mui/material/styles";
// import { useNavigate } from "react-router-dom";
// import "./Footer.css";

// const FooterContainer = styled("footer")({
//   width: "100%",
// });

// const BottomSection = styled(Grid)(({ theme }) => ({
//   backgroundColor: "#444444",
//   color: "#FFFFFF",
//   textAlign: "center",
//   padding: "16px",
//   [theme.breakpoints.down("sm")]: {
//     textAlign: "center",
//   },
// }));

// const FooterText = styled(Typography)(({ theme }) => ({
//   fontSize: "26px",
//   fontWeight: 600,
//   [theme.breakpoints.down("sm")]: {
//     fontSize: "24px",
//   },
// }));

// const FooterButton = styled(Button)({
//   marginTop: "32px",
//   width: "200px",
//   borderRadius: "5px",
//   fontSize: "18px",
//   fontWeight: 500,
//   background: "#FCFCFE",
//   color: "black",
//   "&:hover": {
//     backgroundColor: "#FCFCFE",
//     color: "black",
//     boxShadow: "none",
//   },
// });

// const FooterLink = styled(Link)(({ theme }) => ({
//   color: "#FFFFFF",
//   fontSize: "14px",
//   fontWeight: 400,
//   margin: "0 8px",
//   [theme.breakpoints.down("sm")]: {
//     display: "block",
//     margin: "8px 0",
//   },
// }));

// const Footer = () => {
//   const navigate = useNavigate();

//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   const handleTerms = () => {
//     navigate("/terms");
//   };

//   const handlePrivacy = () => {
//     navigate("/privacy");
//   };

//   const handleContact = () => {
//     navigate("/contact");
//   };

//   return (
//     <FooterContainer sx={{ marginTop: "20px", lineHeight: "40px" }}>
//       <BottomSection
//         container
//         direction={isMobile ? "column" : "row"}
//         spacing={2}
//       >
//         <Grid
//           item
//           xs={12}
//           md={6}
//           style={{ textAlign: isMobile ? "center" : "left", marginTop: "10px" }}
//         >
//           <Typography variant="body2">
//             © Altum Infotech | All rights reserved. Altum Infotech Confidential.
//             For internal use only.
//           </Typography>
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           md={6}
//           style={{ textAlign: isMobile ? "center" : "right" }}
//         >
//           <FooterLink onClick={handleTerms} style={{ cursor: "pointer" }}>
//             Terms of Use
//           </FooterLink>
//           <FooterLink onClick={handlePrivacy} style={{ cursor: "pointer" }}>
//             Data Privacy Statement
//           </FooterLink>
//           <FooterLink onClick={handleContact} style={{ cursor: "pointer" }}>
//             Contact Us
//           </FooterLink>
//         </Grid>
//       </BottomSection>
//     </FooterContainer>
//   );
// };

// export default Footer;

import React from "react";
import { Grid, Button, Typography, Link, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import "./Footer.css";

// Footer for other pages

const FooterContainer = styled("footer")({
  width: "100%",
});

const BottomSection = styled(Grid)(({ theme }) => ({
  backgroundColor: "#444444",
  color: "#FFFFFF",
  textAlign: "center",
  padding: "16px",
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  },
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: "14px",
  fontWeight: 400,
  margin: "0 8px",
  [theme.breakpoints.down("sm")]: {
    display: "block",
    margin: "8px 0",
  },
}));

const Footer = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleTerms = () => {
    navigate("/terms");
  };

  const handlePrivacy = () => {
    navigate("/privacy");
  };

  const handleContact = () => {
    navigate("/contact");
  };

  const handlePrice = () => {
    navigate("/pricing");
  };
  const handleRefund = () => {
    navigate("/cancellation");
  };
  const handleAbout = () => {
    navigate("/aboutus");
  };

  const footerLinkStyles = {
    cursor: "pointer",
    textDecoration: "underline",
    textUnderlineOffset: "3px",
    marginRight: "17px",
  };

  return (
    <FooterContainer sx={{ marginTop: "30px",  }}>
      <BottomSection container spacing={2}>
  <Grid
    container
    item
    xs={12}
    md={6}
    direction="column"
    alignItems="flex-start"
  >
    <Typography variant="body2" sx={{ marginBottom: "10px" }}>
      © Altum Infotech | All rights reserved.
    </Typography>
    <Typography variant="body2">
      Altum Infotech Confidential. For internal use only.
    </Typography>
  </Grid>
  
  <Grid
    container
    item
    xs={12}
    md={6}
    direction="column"
    alignItems={isMobile ? "center" : "flex-end"}
  >
    {/* First Row of Links */}
    <Grid item sx={{ marginBottom: "10px" }}>
      <FooterLink onClick={handleTerms} sx={footerLinkStyles}>
        Terms of Use
      </FooterLink>
      <FooterLink onClick={handlePrivacy} sx={footerLinkStyles} style={{ marginLeft: "15px" }}>
        Privacy Policy
      </FooterLink>
      <FooterLink onClick={handleContact} sx={footerLinkStyles} style={{ marginLeft: "15px" }}>
        Contact Us
      </FooterLink>
    </Grid>
    
    {/* Second Row of Links */}
    <Grid item sx={{ marginTop: "10px" }}>
      <FooterLink onClick={handlePrice} sx={footerLinkStyles}>
        Pricing Policy
      </FooterLink>
      <FooterLink onClick={handleRefund} sx={footerLinkStyles} style={{ marginLeft: "15px" }}>
        Refund Policy
      </FooterLink>
      <FooterLink onClick={handleAbout} sx={footerLinkStyles} style={{ marginLeft: "15px", marginRight: "29px" }}>
        About Us
      </FooterLink>
    </Grid>
  </Grid>
</BottomSection>
    </FooterContainer>
  );
};

export default Footer;

