
import React from "react";
import { Container, Typography, Box } from "@mui/material";
import PrimarySearchAppBar from "../NavBar2/NavBar2";
import Footer2 from "../Footer/Footer2";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from "react-router-dom";

// Pricing Policy component / Webpage

function Pricing() {

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <PrimarySearchAppBar />
      <Container
        sx={{
          flexGrow: 1,
          padding: { xs: 2, sm: 4, md: 6 },
          maxWidth: { xs: "100%", sm: "90%", md: "90%" },
          textAlign: "justify",
        }}
      >
        <Typography style={{fontSize:"18px", fontWeight: 700}} gutterBottom>
        Pricing Policy
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        At Advoref, we believe in transparency and fairness when it comes to pricing. 
        Our subscription-based software for advocates is designed to offer value at every level, with clearly defined plans and no hidden fees. 
        Please take a moment to review our pricing policy to understand how our system works.
        </Typography>
        <hr />
        <Typography style={{fontSize:"14px"}} paragraph>
        <b>1. Subscription Plans</b>
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        We offer three distinct subscription plans to accommodate the varying needs of legal professionals:
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          <ul>
          <li><b>Standard Plan:</b> ₹6,000/year or ₹2,000/quarter</li>
            <li><b>Premium Plan:</b> ₹10,000/year or ₹3,000/quarter</li>
            <li><b>Premium Pro Plan:</b> ₹12,000/year or ₹4,000/quarter</li>
          </ul>
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        Each plan comes with a 15-day free trial, allowing you to experience the software and determine if it meets your needs before committing to a subscription.
        </Typography>

        <hr />

        <Typography style={{fontSize:"14px"}} paragraph>
        <b>2. Payment Terms</b>
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          <ul>
            <li><b>Annual Subscriptions:</b> Payments for yearly plans are to be made in advance, granting you access to the selected plan’s features for a full 12 months.</li>
            <li><b>Quarterly Subscriptions:</b> Payments for quarterly plans are also made in advance, covering a 3-month period.</li>
            <li><b>Payment Methods:</b> We accept a range of secure payment options, including credit/debit cards, net banking, and UPI.</li>
          </ul>
        </Typography>

        <hr />

        <Typography style={{fontSize:"14px"}} paragraph>
        <b>3. Renewal Policy</b>
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        Subscriptions renew automatically at the end of each billing cycle (yearly or quarterly), ensuring uninterrupted access to the software’s features. 
        Renewal reminders will be sent to your registered email before the renewal date.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          <ul>
            <li><b>Annual Plans:</b> Renew on a yearly basis at the listed price.</li>
            <li><b>Quarterly Plans:</b> Renew every 3 months at the listed price.</li>
          </ul>
        </Typography>
        <Typography style={{fontSize:"14px"}}>You can cancel the auto-renewal at any time through your account settings.</Typography>
        
        <hr />

        <Typography style={{fontSize:"14px"}} paragraph>
        <b>4. Upgrade/Downgrade Policy</b>
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        We understand your business needs may evolve. That's why we allow you to upgrade or downgrade your plan at any time:
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          <ul>
            <li><b>Upgrades: </b> When upgrading to a higher-tier plan (e.g., from Standard to Premium), the new pricing will apply immediately, and you will receive prorated credit for the unused portion of your current plan.</li>
            <li><b>Downgrades: </b> Downgrades take effect at the start of your next billing cycle, and the lower pricing will be applied upon renewal.</li>
          </ul>
        </Typography>
        <hr />

        <Typography style={{fontSize:"14px"}} paragraph>
        <b>Need Help?</b>
        </Typography>
        <Typography style={{fontSize:"14px"}}>If you have any questions or concerns regarding our pricing, subscriptions, or billing, please feel free to contact our support team at support@advoref.com. We're here to help!</Typography>

      </Container>
      <Footer2 />
    </Box>
  );
}

export default Pricing;

