
import React from "react";
import { Container, Typography, Box } from "@mui/material";
import PrimarySearchAppBar from "../NavBar2/NavBar2";
import Footer2 from "../Footer/Footer2";
import { useNavigate } from "react-router-dom";

// This component displayes all the Terms and conditions

function Terms() {
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <PrimarySearchAppBar />
      <Container
        sx={{
          flexGrow: 1,
          padding: { xs: 2, sm: 4, md: 6 },
          maxWidth: { xs: "100%", sm: "90%", md: "90%" },
          textAlign: "justify",
        }}
      >
        <Typography style={{fontSize:"18px", fontWeight: 700}} gutterBottom>
          Terms and Conditions
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          By accessing or using our software, you agree to be bound by these
          Terms and Conditions. If you do not agree with these terms, you should
          not use our software.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          Our software is designed for advocates to manage and store Real
          Estate-related data, including creating client profiles, managing
          properties, calculating charges, and generating reports. You agree to
          use the software only for lawful purposes.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          You are responsible for maintaining the confidentiality of your
          account and password and for restricting access to your computer. You
          agree to accept responsibility for all activities that occur under
          your account or password.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          You retain all rights to the data you input into the software.
          However, you grant us a license to use, copy, transmit, store, and
          back up your data for the purposes of enabling you to use the software
          and for any other purpose related to the provision of services to you.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          All content included in this software, such as text, graphics, logos,
          and software, is the property of Altum Infotech and is protected
          by intellectual property laws.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          We shall not be liable for any direct, indirect, incidental, special,
          or consequential damages resulting from the use or inability to use
          the software, including but not limited to damages for loss of
          profits, use, data, or other intangibles.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          We reserve the right to terminate or suspend your access to the
          software at any time, without notice, for conduct that we believe
          violates these Terms and Conditions or is harmful to other users of
          the software, us, or third parties.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          We reserve the right to modify these Terms and Conditions at any time.
          Any changes will be effective immediately upon posting on our website.
          Your continued use of the software following any such modifications
          constitutes your acceptance of the revised terms.
        </Typography>

        {/* <Typography style={{fontSize:"18px", fontWeight: 700}} gutterBottom mt={4}>
          Refund and Cancellation Policy
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          Refunds for our software will be issued only under exceptional
          circumstances, such as technical issues that prevent the software from
          being used as intended, and after our support team has been given a
          chance to resolve the issue. Requests for refunds must be made within
          7 days of purchase.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          You may cancel your subscription to our software at any time. Upon
          cancellation, you will continue to have access to the software until
          the end of your current billing period. No refunds will be provided
          for unused portions of your subscription.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          We reserve the right to terminate your access to the software if you
          breach any of the Terms and Conditions. In such cases, no refunds will
          be provided.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          If you have any questions about our Refund and Cancellation Policy,
          please contact us at support@advoref.com.
        </Typography> */}
      </Container>
      <Footer2 />
    </Box>
  );
}

export default Terms;

