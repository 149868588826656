import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  Switch,
  FormControlLabel,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from "@mui/material";
import PrimarySearchAppBar from "../NavBar2/NavBar2";
import Footer from "../Footer/Footer2";
// import profilebg from "../../assets/profilebg.png";
import { Backdrop, CircularProgress } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import servicePath from "../Constant/defaultvalues";
import { State, City } from "country-state-city";

// Profile form component comes immiditaly after signup.

function ProfileForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [registeredName, setRegisteredName] = useState("");
  const [gstin, setGstin] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [inputErrFirstName, setInputErrFirstName] = useState(false);
  const [inputErrLastName, setInputErrLastName] = useState(false);
  const [inputErrEmail, setInputErrEmail] = useState(false);
  const [inputErrMobile, setInputErrMobile] = useState(false);
  const [inputErrOrg, setInputErrOrg] = useState(false);
  const [inputErrAdd, setInputErrAdd] = useState(false);
  const [inputErrState, setInputErrState] = useState(false);
  const [inputErrCity, setInputErrCity] = useState(false);
  const [inputErrPinCode, setInputErrPinCode] = useState(false);
  const [inputErrRegisteredName, setInputErrRegisteredName] = useState(false);
  const [inputErrGstin, setInputErrGstin] = useState(false);

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value) {
      setInputErrEmail(true);
      setErrorMessage("Email is required");
    } else if (!emailRegex.test(value)) {
      setInputErrEmail(true);
      setErrorMessage("Invalid email address");
    } else {
      setInputErrEmail(false);
      setErrorMessage("");
    }
  };

  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    setMobileNumber(value);

    // Validate mobile number to be exactly 10 digits
    const isValidNumber = /^\d{10}$/.test(value);
    if (value.length === 0) {
      setInputErrMobile(true);
      setErrorMessage("Mobile number is required");
    } else if (!isValidNumber) {
      setInputErrMobile(true);
      setErrorMessage("Mobile number must be exactly 10 digits");
    } else {
      setInputErrMobile(false);
      setErrorMessage("");
    }
  };

  const [showFields, setShowFields] = useState(false);

  const handleSwitchChange = (event) => {
    setShowFields(event.target.checked);
  };

  useEffect(() => {
    const fetchedStates = State.getStatesOfCountry("IN");
    setStates(fetchedStates);
  }, []);

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setState(selectedState);

    const fetchedCities = City.getCitiesOfState("IN", selectedState);
    setCities(fetchedCities);
    setCity(""); // Reset city field when state changes
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const navigate = useNavigate();

  const admin_id = localStorage.getItem("admin_id");
  console.log("admin_id: ", admin_id);

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   setLoading(true); // Show loading spinner

  //   setInputErrFirstName(false);
  //   setInputErrLastName(false);
  //   // setInputErrEmail(false);
  //   setInputErrMobile(false);
  //   setInputErrOrg(false);
  //   setInputErrAdd(false);
  //   setInputErrState(false);
  //   setInputErrCity(false);
  //   setInputErrPinCode(false);
  //   setInputErrRegisteredName(false);
  //   setInputErrGstin(false);

  //   const admin_id = localStorage.getItem("admin_id");
  //   console.log("admin_id: ", admin_id);
  //   // const admin_id = localStorage.getItem("usr_id");

  //   if (!firstName) setInputErrFirstName(true);
  //   if (!lastName) setInputErrLastName(true);
  //   // if (!email) setInputErrEmail(true);
  //   if (!mobileNumber) setInputErrMobile(true);
  //   if (!organizationName) setInputErrOrg(true);
  //   if (!address) setInputErrAdd(true);
  //   if (!state) setInputErrState(true);
  //   if (!city) setInputErrCity(true);
  //   if (!pinCode) setInputErrPinCode(true);

  //   if (showFields) {
  //     if (!registeredName) setInputErrRegisteredName(true);
  //     if (!gstin) setInputErrGstin(true);
  //   }

  //   if (
  //     !firstName ||
  //     !lastName ||
  //     !mobileNumber ||
  //     !organizationName ||
  //     !address ||
  //     !state ||
  //     !city ||
  //     !pinCode ||
  //     (showFields && (!registeredName || !gstin))
  //   ) {
  //     toast.error("Please enter all the mandatory (*) fields", {
  //       position: "top-right",
  //       autoClose: 2000,
  //     });
  //     setLoading(false); // Stop loading spinner
  //     return;
  //   }

  //   try {
  //     const requestData = {
  //       first_name: firstName,
  //       last_name: lastName,
  //       mobile: mobileNumber,
  //       org_name: organizationName,
  //       address,
  //       state,
  //       city,
  //       pincode: pinCode,
  //       reg_name: registeredName,
  //       gstin,
  //       admin_id,
  //     };

  //     const response = await axios.post(
  //       `${servicePath}/createProfile`,
  //       requestData
  //     );

  //     if (response.data.statusCode === "00") {
  //       setFirstName("");
  //       setLastName("");
  //       setEmail("");
  //       setMobileNumber("");
  //       setOrganizationName("");
  //       setAddress("");
  //       setState("");
  //       setCity("");
  //       setPinCode("");
  //       setRegisteredName("");
  //       setGstin("");

  //       navigate("/home");

  //       // toast.success("Profile updated successfully", {
  //       //   position: "top-right",
  //       //   autoClose: 2000,
  //       // });
  //     } else {
  //       toast.error("Failed! - " + response.data.message, {
  //         position: "top-right",
  //         autoClose: 2000,
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error updating profile:", error);
  //     toast.error("Failed to update profile", {
  //       position: "top-right",
  //       autoClose: 2000,
  //     });
      
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Show loading spinner
    
    // Reset errors
    setInputErrFirstName(false);
    setInputErrLastName(false);
    // setInputErrEmail(false);
    setInputErrMobile(false);
    setInputErrOrg(false);
    setInputErrAdd(false);
    setInputErrState(false);
    setInputErrCity(false);
    setInputErrPinCode(false);
    setInputErrRegisteredName(false);
    setInputErrGstin(false);
  
    const admin_id = localStorage.getItem("admin_id");
  
// const admin_id = localStorage.getItem("usr_id");

    if (!firstName) setInputErrFirstName(true);
    if (!lastName) setInputErrLastName(true);
  // if (!email) setInputErrEmail(true);
    if (!mobileNumber) setInputErrMobile(true);
    if (!organizationName) setInputErrOrg(true);
    if (!address) setInputErrAdd(true);
    if (!state) setInputErrState(true);
    if (!city) setInputErrCity(true);
    if (!pinCode) setInputErrPinCode(true);
  
    if (showFields) {
      if (!registeredName) setInputErrRegisteredName(true);
      if (!gstin) setInputErrGstin(true);
    }
  
    if (
      !firstName ||
      !lastName ||
      !mobileNumber ||
      !organizationName ||
      !address ||
      !state ||
      !city ||
      !pinCode ||
      (showFields && (!registeredName || !gstin))
    ) {
      toast.error("Please enter all the mandatory (*) fields", {
        position: "top-right",
        autoClose: 2000,
      });
      setLoading(false); // Stop loading spinner
      return;
    }
  
    try {
      const requestData = {
        first_name: firstName,
        last_name: lastName,
        mobile: mobileNumber,
        org_name: organizationName,
        address,
        state,
        city,
        pincode: pinCode,
        reg_name: registeredName,
        gstin,
        admin_id,
      };
  
      const response = await axios.post(`${servicePath}/createProfile`, requestData);
  
      if (response.data.statusCode === "00") {
        setFirstName("");
        setLastName("");
        setEmail("");
        setMobileNumber("");
        setOrganizationName("");
        setAddress("");
        setState("");
        setCity("");
        setPinCode("");
        setRegisteredName("");
        setGstin("");
  
        navigate("/home");
      } else {
        toast.error("Failed! - " + response.data.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };
  

  const textFieldStyles = {
    // border: "1px solid #E0E0E0",
    // borderRadius: "6px",
    // backgroundColor: "#FFFFFF",
    // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
    // width: "100%",
    // fontSize: "14px",
    // "& .MuiInputBase-root": {
    //   height: "45px", // adjust the height of the input field
    //   padding: "0 0px", // adjust padding inside the input field
    // },
  };

  return (
    // <Box
    //   display="flex"
    //   flexDirection="column"
    //   minHeight="100vh"
    //   sx={{
    //     // backgroundImage: `url(${profilebg})`,
    //     backgroundSize: "cover",
    //     backgroundRepeat: "no-repeat",
    //     backgroundPosition: "center",
    //     justifyContent: "center",
    //     // justifyContent: "space-between",
    //   }}
    // >
    //   <PrimarySearchAppBar />
    //   <Box py={2} px={4} component="main" sx={{ flexGrow: 1 }}>
    //     <form style={{ padding: 20 }} onSubmit={handleSubmit}>
    //       <Typography
    //         variant="h5"
    //         gutterBottom
    //         sx={{
    //           fontSize: "26px",
    //           fontWeight: 700,
    //           color: "#111439",
    //         }}
    //       >
    //         User Profile
    //       </Typography>
    //       <Grid container spacing={3}>
    //         <Grid item xs={12} sm={6} md={3}>
    //           <Typography variant="subtitle1">First Name</Typography>
    //           <TextField
    //             required
    //             id="firstName"
    //             name="firstName"
    //             value={firstName}
    //             onChange={(e) => setFirstName(e.target.value)}
    //             error={inputErrFirstName}
    //             helperText={inputErrFirstName && "First Name is required"}
    //             fullWidth
    //             variant="outlined"
    //             sx={textFieldStyles}
    //           />
    //         </Grid>
    //         <Grid item xs={12} sm={6} md={3}>
    //           <Typography variant="subtitle1">Last Name</Typography>
    //           <TextField
    //             required
    //             id="lastName"
    //             name="lastName"
    //             value={lastName}
    //             onChange={(e) => setLastName(e.target.value)}
    //             error={inputErrLastName}
    //             helperText={inputErrLastName && "Last Name is required"}
    //             fullWidth
    //             variant="outlined"
    //             sx={textFieldStyles}
    //           />
    //         </Grid>
    //         <Grid item xs={12} md={6}></Grid>
    //         {/* <Grid item xs={12} sm={6} md={3}>
    //           <Typography variant="subtitle1">Email Address</Typography>
    //           <TextField
    //             required
    //             id="email"
    //             name="email"
    //             value={email}
    //             onChange={handleEmailChange}
    //             error={inputErrEmail}
    //             helperText={inputErrEmail && "Email Address is required"}
    //             fullWidth
    //             variant="outlined"
    //             sx={textFieldStyles}
    //           />
    //         </Grid> */}
    //         <Grid item xs={12} sm={6} md={3}>
    //           <Typography variant="subtitle1">Mobile Number</Typography>
    //           <TextField
    //             id="mobileNumber"
    //             name="mobileNumber"
    //             value={mobileNumber}
    //             onChange={handleMobileNumberChange}
    //             error={inputErrMobile}
    //             helperText={inputErrMobile && "Mobile Number is required"}
    //             inputProps={{
    //               maxLength: 10,
    //             }}
    //             fullWidth
    //             variant="outlined"
    //             sx={textFieldStyles}
    //           />
    //         </Grid>

    //         <Grid item xs={12} sm={6} md={3}>
    //           <Typography variant="subtitle1">Organisation Name</Typography>
    //           <TextField
    //             id="organizationName"
    //             name="organizationName"
    //             value={organizationName}
    //             onChange={(e) => setOrganizationName(e.target.value)}
    //             error={inputErrOrg}
    //             helperText={inputErrOrg && "Organization Name is required"}
    //             fullWidth
    //             variant="outlined"
    //             sx={textFieldStyles}
    //           />
    //         </Grid>
    //         <Grid item xs={12} md={6}></Grid>
    //         <Grid item xs={12} sm={6} md={6}>
    //           <Typography variant="subtitle1">Address</Typography>
    //           <TextField
    //             id="address"
    //             name="address"
    //             value={address}
    //             onChange={(e) => setAddress(e.target.value)}
    //             error={inputErrAdd}
    //             helperText={inputErrAdd && "Address is required"}
    //             fullWidth
    //             variant="outlined"
    //             sx={textFieldStyles}
    //           />
    //         </Grid>
    //         <Grid item xs={12} md={6}></Grid>
    //         <Grid item xs={12} sm={6} md={2}>
    //           <Typography variant="subtitle1">State</Typography>
    //           <FormControl
    //             fullWidth
    //             variant="outlined"
    //             error={inputErrState}
    //             sx={textFieldStyles}
    //           >
    //             <Select
    //               id="state"
    //               name="state"
    //               value={state}
    //               onChange={handleStateChange}
    //               displayEmpty
    //               renderValue={(value) => value || "Select State"}
    //             >
    //               <MenuItem value="" disabled>
    //                 Select State
    //               </MenuItem>
    //               {states.map((stateObj) => (
    //                 <MenuItem key={stateObj.isoCode} value={stateObj.isoCode}>
    //                   {stateObj.name}
    //                 </MenuItem>
    //               ))}
    //             </Select>
    //             {inputErrState && (
    //               <FormHelperText>State is required</FormHelperText>
    //             )}
    //           </FormControl>
    //         </Grid>

    //         <Grid item xs={12} sm={6} md={2}>
    //           <Typography variant="subtitle1">City</Typography>
    //           <FormControl
    //             fullWidth
    //             variant="outlined"
    //             error={inputErrCity}
    //             sx={textFieldStyles}
    //           >
    //             <Select
    //               id="city"
    //               name="city"
    //               value={city}
    //               onChange={handleCityChange}
    //               displayEmpty
    //               renderValue={(value) => value || "Select City"}
    //               disabled={!state} // Disable city dropdown if no state is selected
    //             >
    //               <MenuItem value="" disabled>
    //                 Select City
    //               </MenuItem>
    //               {cities.map((cityObj) => (
    //                 <MenuItem key={cityObj.name} value={cityObj.name}>
    //                   {cityObj.name}
    //                 </MenuItem>
    //               ))}
    //             </Select>
    //             {inputErrCity && (
    //               <FormHelperText>City is required</FormHelperText>
    //             )}
    //           </FormControl>
    //         </Grid>
    //         <Grid item xs={12} sm={6} md={2}>
    //           <Typography variant="subtitle1">Pin Code</Typography>
    //           <TextField
    //             required
    //             id="pinCode"
    //             name="pinCode"
    //             value={pinCode}
    //             onChange={(e) => setPinCode(e.target.value)}
    //             error={inputErrPinCode}
    //             helperText={inputErrPinCode && "Pin Code is required"}
    //             fullWidth
    //             variant="outlined"
    //             sx={textFieldStyles}
    //           />
    //         </Grid>
    //         <Grid item xs={12} md={12}>
    //           <Typography variant="subtitle1">Do you have GSTIN?</Typography>
    //           <Grid container alignItems="center">
    //             <Typography>No</Typography>
    //             <FormControlLabel
    //               control={
    //                 <Switch
    //                   checked={showFields}
    //                   onChange={handleSwitchChange}
    //                 />
    //               }
    //               labelPlacement="end"
    //               label={<Typography>Yes</Typography>}
    //               style={{ marginLeft: 8 }}
    //             />
    //           </Grid>
    //         </Grid>

    //         {showFields && (
    //           <>
    //             <Grid item xs={12} sm={6} md={3}>
    //               <Typography variant="subtitle1">Registered Name</Typography>
    //               <TextField
    //                 id="registeredName"
    //                 name="registeredName"
    //                 value={registeredName}
    //                 onChange={(e) => setRegisteredName(e.target.value)}
    //                 error={inputErrRegisteredName}
    //                 helperText={
    //                   inputErrRegisteredName && "Registered Name is required"
    //                 }
    //                 fullWidth
    //                 variant="outlined"
    //                 sx={textFieldStyles}
    //               />
    //             </Grid>

    //             <Grid item xs={12} sm={6} md={3}>
    //               <Typography variant="subtitle1">GSTIN</Typography>
    //               <TextField
    //                 id="gstin"
    //                 name="gstin"
    //                 value={gstin}
    //                 onChange={(e) => setGstin(e.target.value)}
    //                 error={inputErrGstin}
    //                 helperText={inputErrGstin && "GSTIN is required"}
    //                 fullWidth
    //                 variant="outlined"
    //                 sx={textFieldStyles}
    //               />
    //             </Grid>
    //           </>
    //         )}

    //         <Grid item xs={12}>
    //           <Button
    //             variant="contained"
    //             color="primary"
    //             type="submit"
    //             sx={{
    //               backgroundColor: "#0E1030",
    //               color: "white",
    //               borderRadius: "5px",
    //               marginTop: "20px",
    //               padding: "10px 20px",
    //               textTransform: "none",
    //               "&:hover": {
    //                 backgroundColor: "#0E1030",
    //               },
    //             }}
    //             onClick={handleSubmit}
    //           >
    //             Save
    //           </Button>
    //         </Grid>
    //       </Grid>
    //     </form>
    //   </Box>
    //   <Footer />
    // </Box>

    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      sx={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        justifyContent: "center",
      }}
    >
      <PrimarySearchAppBar />
      <Box py={2} px={4} component="main" sx={{ flexGrow: 1 }}>
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={8} md={4}>
            <form style={{ padding: 20 }} onSubmit={handleSubmit}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontSize: "26px",
                  fontWeight: 700,
                  color: "#111439",
                }}
              >
                Create Your Profile
              </Typography>
              <hr />

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    error={inputErrFirstName}
                    helperText={inputErrFirstName && "First Name is required"}
                    fullWidth
                    variant="outlined"
                    sx={textFieldStyles}
                    style={{marginTop:"8px"}}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    error={inputErrLastName}
                    helperText={inputErrLastName && "Last Name is required"}
                    fullWidth
                    variant="outlined"
                    sx={textFieldStyles}
                    style={{marginTop:"8px"}}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="mobileNumber"
                    name="mobileNumber"
                    label="Mobile Number"
                    value={mobileNumber}
                    onChange={handleMobileNumberChange}
                    error={inputErrMobile}
                    helperText={inputErrMobile && "Mobile Number is required"}
                    inputProps={{
                      maxLength: 10,
                    }}
                    fullWidth
                    variant="outlined"
                    sx={textFieldStyles}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="organizationName"
                    name="organizationName"
                    label="Organization Name"
                    value={organizationName}
                    onChange={(e) => setOrganizationName(e.target.value)}
                    error={inputErrOrg}
                    helperText={inputErrOrg && "Organization Name is required"}
                    fullWidth
                    variant="outlined"
                    sx={textFieldStyles}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="address"
                    name="address"
                    label="Address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    error={inputErrAdd}
                    helperText={inputErrAdd && "Address is required"}
                    fullWidth
                    variant="outlined"
                    sx={textFieldStyles}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={inputErrState}
                    sx={textFieldStyles}
                  >
                    {/* <InputLabel>State</InputLabel> */}
                    <Select
                      id="state"
                      name="state"
                      value={state}
                      onChange={handleStateChange}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select State
                      </MenuItem>
                      {states.map((stateObj) => (
                        <MenuItem
                          key={stateObj.isoCode}
                          value={stateObj.isoCode}
                        >
                          {stateObj.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {inputErrState && (
                      <FormHelperText>State is required</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={inputErrCity}
                    sx={textFieldStyles}
                  >
                    {/* <InputLabel>City</InputLabel> */}
                    <Select
                      id="city"
                      name="city"
                      value={city}
                      onChange={handleCityChange}
                      displayEmpty
                      disabled={!state} // Disable city dropdown if no state is selected
                    >
                      <MenuItem value="" disabled>
                        Select City
                      </MenuItem>
                      {cities.map((cityObj) => (
                        <MenuItem key={cityObj.name} value={cityObj.name}>
                          {cityObj.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {inputErrCity && (
                      <FormHelperText>City is required</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="pinCode"
                    name="pinCode"
                    label="Pin Code"
                    value={pinCode}
                    onChange={(e) => setPinCode(e.target.value)}
                    error={inputErrPinCode}
                    helperText={inputErrPinCode && "Pin Code is required"}
                    fullWidth
                    variant="outlined"
                    sx={textFieldStyles}
                  />
                </Grid>

                <Grid item xs={12}>
                  <hr />
                  <Typography variant="subtitle1">
                    Do you have GSTIN?
                  </Typography>
                  <Grid container alignItems="center">
                    <Typography>No</Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showFields}
                          onChange={handleSwitchChange}
                        />
                      }
                      labelPlacement="end"
                      label={<Typography>Yes</Typography>}
                      style={{ marginLeft: 8 }}
                    />
                  </Grid>
                </Grid>

                {showFields && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        id="registeredName"
                        name="registeredName"
                        label="Registered Name"
                        value={registeredName}
                        onChange={(e) => setRegisteredName(e.target.value)}
                        error={inputErrRegisteredName}
                        helperText={
                          inputErrRegisteredName &&
                          "Registered Name is required"
                        }
                        fullWidth
                        variant="outlined"
                        sx={textFieldStyles}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        id="gstin"
                        name="gstin"
                        label="GSTIN"
                        value={gstin}
                        onChange={(e) => setGstin(e.target.value)}
                        error={inputErrGstin}
                        helperText={inputErrGstin && "GSTIN is required"}
                        fullWidth
                        variant="outlined"
                        sx={textFieldStyles}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{
                      backgroundColor: "#0E1030",
                      color: "white",
                      borderRadius: "5px",
                      // marginTop: "5px",
                      padding: "10px 20px",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#0E1030",
                      },
                    }}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>

      <Footer />
    </Box>
  );
}

export default ProfileForm;
