import React, { useState, useEffect } from "react";
import Navbar from "../NavBar/NavBar";
import "../Main/Main.css";
import {
  Button,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  Box,
  Switch,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { useNavigate } from "react-router-dom";
import bulbicon from "../../assets/bulbicon.png";
import secondicon from "../../assets/secondicon.png";
import thirdicon from "../../assets/thirdicon.png";
import Screen from "../../assets/Screen.png";
import hiw from "../../assets/hiw.png";
import Footer from "../Footer/Footer";
import check from "../../assets/check.png";
import cross from "../../assets/cross.png";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";
import servicePath from "../Constant/defaultvalues";
import axios from "axios";


// Home page / Landing page: Contains Home, How it works and Subscription tabs.


function Main() {
  const [selectedPlan, setSelectedPlan] = useState("");
  const [isYearly, setIsYearly] = useState(false);
  const [planExpiry, setPlanExpiry] = useState(null);

  const handleToggle = () => {
    setIsYearly(!isYearly);
  };

  const handleKnowmore = () => {
    navigate("/knowmore");
  };

  const admin_id = localStorage.getItem("admin_id");
  console.log("admin_id: ", admin_id);

  const plans = [
    {
      yearlyPrice: "₹ 6,000",
      quarterlyPrice: "₹ 2,000",
      name: "Standard",
      subtext: "Get started",
      value: "standard",
      backgroundColor: "#ffffff",
      buttonBackground: "#0E1030",
      features: [
        { text: "Manage Clients", available: true },
        { text: "Manage Property", available: true },
        { text: "1 Additional User (Free)", available: true },
        { text: "Save Documents", available: false },
        { text: "Software Customizable", available: false },
      ],
      buttonText: "Start 15 Days Free Trial",
      buyButtonText: "Buy Now",
      navigateTo: "/cart",
    },
    {
      yearlyPrice: "₹ 10,000",
      quarterlyPrice: "₹ 3,000",
      name: "Premium",
      subtext: "Get started",
      value: "premium",
      backgroundColor: "#ffffff",
      buttonBackground: "#0E1030",
      features: [
        { text: "Manage Clients", available: true },
        { text: "Manage Property", available: true },
        { text: "3 Additional User (Free)", available: true },
        { text: "Save Documents", available: false },
        { text: "Software Customizable", available: false },
      ],
      buttonText: "Start 15 Days Free Trial",
      buyButtonText: "Buy Now",
      navigateTo: "/pcart",
    },
    {
      yearlyPrice: "₹ 12,000",
      quarterlyPrice: "₹ 4,000",
      name: "Premium-Pro",
      subtext: "For best experience",
      value: "premium-pro",
      backgroundColor: "linear-gradient(to bottom, #0E1030, #2C3296)",
      buttonBackground: "#ffffff", // Set background color for "Buy Now" button
      color: "#ffffff",
      features: [
        { text: "Manage Clients", available: true },
        { text: "Manage Property", available: true },
        { text: "5 Additional User (Free)", available: true },
        { text: "Save Documents", available: true },
        { text: "Software Customizable", available: true },
      ],
      buttonText: "Contact Us",
      buyButtonText: "Buy Now",
      navigateTo: "/ppcart",
    },
  ];

  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    // Retrieve admin_id from local storage
    const admin_id = localStorage.getItem("admin_id");
    console.log("admin_id: ", admin_id);
  
    // Check if admin_id exists in local storage
    if (!admin_id) {
      // Log an error if admin_id is not found
      console.error("admin_id is not found in localStorage.");
      return; // Exit the effect early if no admin_id is found
    }
  
    // Make an API call to get profile details based on admin_id
    axios
      .get(`${servicePath}/profiledetails`, {
        headers: {
          // Pass admin_id in the headers for authentication or data fetching
          admin_id: admin_id,
        },
      })
      .then((response) => {
        // Store the response data from the API call
        const profileData = response.data;
  
        // Check if the profile data is an empty array
        if (Array.isArray(profileData) && profileData.length === 0) {
          // If profile data is empty, navigate the user to the profile form page
          navigate("/profileform");
          return; // Exit after navigating to avoid further execution
        }
  
        // Handle the case where profileData is not empty
        const data = profileData[0]; // Assuming the first element of the response array contains relevant profile data
        console.log("Profile Data:", data);
  
        // Store org_id from the profile data in local storage
        localStorage.setItem("org_id", data.org_id);
        console.log("Org_id: ", data.org_id);
  
        // Check if the plan expiry date exists in the profile data and set it in the state
        if (data.plan_expiry) {
          setPlanExpiry(data.plan_expiry); // Update the state with the plan expiry date
        }
      })
      .catch((error) => {
        console.error("Error fetching profile details:", error);
      });
  }, [navigate]); 
  

  const handleCardSelect = (event) => {
    setSelectedPlan(event.target.value);
  };

  const handleDiscover = () => {
    navigate("/discover");
  };

  const startTrial = () => {
    const token = localStorage.getItem('subscriptionToken');
    if (!token) {
      navigate('/signup');
    }else
    navigate("/trialform");
  };

  return (
    <div className="App">
      <Navbar />

      <section id="home" className="section">
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12} md={5}>
            <Box className="titlebox">
              <Typography variant="h1" id="title">
                Advoref
              </Typography>
              <Typography id="title-text">
                Simplifying property transactions for Legal Professionals with
                Advoref, a comprehensive SaaS solution designed specifically for
                advocates.
              </Typography>
              <Button
                // href="/profileform"
                // href="/profile"
                id="knowmore"
                variant="contained"
                style={{ borderRadius: "5px", width: "202px", height: "52px" }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textTransform: "none",
                }}
                onClick={handleKnowmore}
              >
                Know More <ArrowCircleRightIcon sx={{ marginLeft: "10px" }} />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="images-container">
              <img src={Screen} alt="IMG" className="homeimg1" />
            </Box>
          </Grid>
        </Grid>
      </section>

      <Container sx={{ marginTop: "100px" }} maxWidth="lg">
        <Box py={0} className="container">
          <Typography
            py={2}
            variant="h4"
            align="center"
            id="how-it-works-title"
          >
            Innovative Client Management System Software
          </Typography>
          <Typography
            pt={1}
            pb={3}
            variant="body1"
            align="center"
            sx={{ width: "100%", maxWidth: "565px", mx: "auto" }}
            id="how-it-works-text"
          >
            Streamline your operations with our comprehensive software solution
            designed to simplify tasks.
          </Typography>
          <Grid
            py={3}
            container
            spacing={0}
            align="center"
            justifyContent="center"
          >
            {[
              { icon: bulbicon, text: "Easy To Record Client Details" },
              { icon: secondicon, text: "Property Detail managment" },
              { icon: thirdicon, text: "Hassle-free fee payments" },
            ].map((item, index) => (
              <Grid item xs={12} sm={3} key={index} className="icon-column">
                <img
                  src={item.icon}
                  alt="icon"
                  style={
                    index === 0
                      ? { width: "70px", height: "90px", marginBottom: "10px" }
                      : index === 1
                      ? {
                          width: "80px",
                          height: "80px",
                          marginBottom: "20px",
                        }
                      : {
                          width: "80px",
                          height: "80px",
                          marginBottom: "20px",
                        }
                  }
                />
                <Typography variant="body1">{item.text}</Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>

      <section id="how-it-works" className="section">
        <Box className="container">
          <Typography
            mb={0}
            variant="h4"
            align="center"
            id="how-it-works-title2"
          >
            How it works?
          </Typography>
          <Typography
            mb={5}
            variant="body1"
            align="center"
            id="how-it-works-text2"
          >
            Discover the seamless efficiency of Advoref operations.
          </Typography>
        </Box>

        <Box className="works">
          <Grid mt={0} container spacing={3} alignItems="center">
            <Grid item xs={12} md={6} className="left">
              <img
                id="dashboard2"
                // src={homeimg3}
                src={hiw}
                alt="img"
                style={{ width: "100%", height: "auto" }}
              />
            </Grid>
            <Grid item xs={12} md={6} className="right">
              <Box id="Effortless">
                <Typography variant="h6" id="Effortless-title">
                  Effortless Client Detail Management
                </Typography>
                <Typography variant="body1" id="Effortless-text">
                  Add and manage Client information with ease.
                </Typography>
              </Box>
              <Box id="Accordion">
  {[
    "Comprehensive property details recording",
    "Instant access to all details",
    "Hassle-free fee payment processing",
  ].map((title, index) => (
    <Accordion key={index}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${index + 1}-content`}
        id={`panel${index + 1}-header`}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography style={{ fontSize: '12px' }}>
          {index === 0 && 
            "Our system allows you to record and store detailed property information, including ownership history, legal descriptions, and zoning details. With easy-to-use fields, all data is structured and securely stored for future reference."
          }
          {index === 1 && 
            "Gain instant access to all stored property details with a user-friendly search function. Whether it's for quick reference or detailed analysis, all information is just a click away, allowing for efficient data retrieval anytime."
          }
          {index === 2 && 
            "Our integrated fee payment processing system ensures seamless and hassle-free transactions. Clients can pay their fees online, with automated reminders, tracking, and receipt generation for easy financial management."
          }
        </Typography>
      </AccordionDetails>
    </Accordion>
  ))}
</Box>

            </Grid>
          </Grid>
        </Box>
      </section>

      <Container style={{ marginBottom: "100px" }} id="subscription">
        <Typography align="center" id="subscription-title">
          Subscription
        </Typography>
        <Typography
          mb={1}
          variant="body1"
          align="center"
          id="subscription-text"
        >
          Discover the seamless efficiency of Advoref operations.
        </Typography>

        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            padding="10px"
            backgroundColor="gray.100"
          >
            <Typography
              marginRight="10px"
              color={!isYearly ? "blue.500" : "gray.500"}
              fontWeight={!isYearly ? "bold" : "normal"}
            >
              Quarterly
            </Typography>
            <Switch
              size="md"
              variant="solid"
              isChecked={isYearly}
              onChange={handleToggle}
              colorScheme="blue"
            />
            <Typography
              marginLeft="10px"
              color={isYearly ? "blue.500" : "gray.500"}
              fontWeight={isYearly ? "bold" : "normal"}
            >
              Yearly
            </Typography>
          </Box>

          <Grid container spacing={4} justifyContent="center">
            {plans.map((plan) => (
              <Grid item xs={12} sm={6} md={4} key={plan.value}>
                <Card
                  style={{
                    border: "2px solid #D9D9D9",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    backgroundImage:
                      plan.value === "premium-pro"
                        ? "linear-gradient(to bottom, #0E1030, #2C3296)"
                        : plan.backgroundColor,
                    color:
                      plan.value === "premium-pro" ||
                      plan.backgroundColor === "#0E1030"
                        ? "#ffffff"
                        : "#000000",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <CardContent>
                    <Box textAlign="center">
                      <Typography
                        variant="h5"
                        style={{ fontSize: "18px", fontWeight: 600 }}
                        gutterBottom
                      >
                        {isYearly ? plan.yearlyPrice : plan.quarterlyPrice} /{" "}
                        {isYearly ? "Yearly" : "Quarterly"}
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{ fontSize: "26px", fontWeight: 700 }}
                        gutterBottom
                      >
                        {plan.name}
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{ fontSize: "18px", fontWeight: 300 }}
                        gutterBottom
                      >
                        {plan.subtext}
                      </Typography>
                      <hr />
                    </Box>
                    <List>
                      {plan.features.map((feature, index) => (
                        <ListItem key={index}>
                          <ListItemIcon>
                            {feature.available ? (
                              <img
                                src={check}
                                alt="Check"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            ) : (
                              <img
                                src={cross}
                                alt="Cross"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            )}
                          </ListItemIcon>

                          <ListItemText primary={feature.text} />
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    pb={2}
                  >
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: plan.buttonBackground,
                        color:
                          plan.buttonBackground === "#ffffff"
                            ? "#0E1030"
                            : "#ffffff",
                        borderRadius: "5px",
                        padding: "10px 20px",
                        textTransform: "none",
                        marginBottom: "10px",
                        display: "block",
                      }}
                      onClick={() =>
                        navigate(plan.navigateTo, {
                          state: {
                            planName: plan.name,
                            planType: isYearly ? "Yearly" : "Quarterly",
                            amount: isYearly
                              ? plan.yearlyPrice
                              : plan.quarterlyPrice,
                          },
                        })
                      }
                    >
                      {plan.buyButtonText}
                    </Button>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

{/* <Box>
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    width="100%"
    padding="10px"
    backgroundColor="gray.100"
  >
    <Typography
      marginRight="10px"
      color={!isYearly ? "blue.500" : "gray.500"}
      fontWeight={!isYearly ? "bold" : "normal"}
    >
      Quarterly
    </Typography>
    <Switch
      size="md"
      variant="solid"
      isChecked={isYearly}
      onChange={handleToggle}
      colorScheme="blue"
    />
    <Typography
      marginLeft="10px"
      color={isYearly ? "blue.500" : "gray.500"}
      fontWeight={isYearly ? "bold" : "normal"}
    >
      Yearly
    </Typography>
  </Box>

  <Grid container spacing={4} justifyContent="center">

    <Grid item xs={12} sm={6} md={4}>
      <Card
        style={{
          border: "2px solid #D9D9D9",
          borderRadius: "8px",
          boxShadow: "0px 4px 4px 0px #00000040",
          backgroundColor: "#ffffff",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <CardContent>
          <Box textAlign="center">
            <Typography
              variant="h5"
              style={{ fontSize: "18px", fontWeight: 600 }}
              gutterBottom
            >
              {isYearly ? "₹ 6,000" : "₹ 2,000"} / {isYearly ? "Yearly" : "Quarterly"}
            </Typography>
            <Typography
              variant="h6"
              style={{ fontSize: "26px", fontWeight: 700 }}
              gutterBottom
            >
              Standard
            </Typography>
            <Typography
              variant="h6"
              style={{ fontSize: "18px", fontWeight: 300 }}
              gutterBottom
            >
              Get started
            </Typography>
            <hr />
          </Box>
          <List>
            <ListItem><ListItemText primary="Manage Clients" /></ListItem>
            <ListItem><ListItemText primary="Manage Property" /></ListItem>
            <ListItem><ListItemText primary="1 Additional User (Free)" /></ListItem>
            <ListItem><ListItemText primary="Save Documents (Unavailable)" /></ListItem>
            <ListItem><ListItemText primary="Software Customizable (Unavailable)" /></ListItem>
          </List>
        </CardContent>
        <Box display="flex" justifyContent="center" pb={2}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#0E1030",
              color: "#ffffff",
              borderRadius: "5px",
              padding: "10px 20px",
              textTransform: "none",
              marginRight: "10px",
            }}
            onClick={() => navigate("/cart")}
          >
            Buy Now
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#ffffff",
              color: "#0E1030",
              borderRadius: "5px",
              padding: "10px 20px",
              textTransform: "none",
            }}
            onClick={() => console.log("Free Trial Started")}
          >
            Free Trial
          </Button>
        </Box>
      </Card>
    </Grid>


    <Grid item xs={12} sm={6} md={4}>
      <Card
        style={{
          border: "2px solid #D9D9D9",
          borderRadius: "8px",
          boxShadow: "0px 4px 4px 0px #00000040",
          backgroundColor: "#ffffff",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <CardContent>
          <Box textAlign="center">
            <Typography
              variant="h5"
              style={{ fontSize: "18px", fontWeight: 600 }}
              gutterBottom
            >
              {isYearly ? "₹ 10,000" : "₹ 3,000"} / {isYearly ? "Yearly" : "Quarterly"}
            </Typography>
            <Typography
              variant="h6"
              style={{ fontSize: "26px", fontWeight: 700 }}
              gutterBottom
            >
              Premium
            </Typography>
            <Typography
              variant="h6"
              style={{ fontSize: "18px", fontWeight: 300 }}
              gutterBottom
            >
              Get started
            </Typography>
            <hr />
          </Box>
          <List>
            <ListItem><ListItemText primary="Manage Clients" /></ListItem>
            <ListItem><ListItemText primary="Manage Property" /></ListItem>
            <ListItem><ListItemText primary="3 Additional Users (Free)" /></ListItem>
            <ListItem><ListItemText primary="Save Documents (Unavailable)" /></ListItem>
            <ListItem><ListItemText primary="Software Customizable (Unavailable)" /></ListItem>
          </List>
        </CardContent>
        <Box display="flex" justifyContent="center" pb={2}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#0E1030",
              color: "#ffffff",
              borderRadius: "5px",
              padding: "10px 20px",
              textTransform: "none",
              marginRight: "10px",
            }}
            onClick={handleS}
          >
            Buy Now
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#ffffff",
              color: "#0E1030",
              borderRadius: "5px",
              padding: "10px 20px",
              textTransform: "none",
            }}
            onClick={() => console.log("Free Trial Started")}
          >
            Free Trial
          </Button>
        </Box>
      </Card>
    </Grid>


    <Grid item xs={12} sm={6} md={4}>
      <Card
        style={{
          border: "2px solid #D9D9D9",
          borderRadius: "8px",
          boxShadow: "0px 4px 4px 0px #00000040",
          backgroundImage: "linear-gradient(to bottom, #0E1030, #2C3296)",
          color: "#ffffff",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <CardContent>
          <Box textAlign="center">
            <Typography
              variant="h5"
              style={{ fontSize: "18px", fontWeight: 600 }}
              gutterBottom
            >
              {isYearly ? "₹ 12,000" : "₹ 4,000"} / {isYearly ? "Yearly" : "Quarterly"}
            </Typography>
            <Typography
              variant="h6"
              style={{ fontSize: "26px", fontWeight: 700 }}
              gutterBottom
            >
              Premium-Pro
            </Typography>
            <Typography
              variant="h6"
              style={{ fontSize: "18px", fontWeight: 300 }}
              gutterBottom
            >
              For best experience
            </Typography>
            <hr />
          </Box>
          <List>
            <ListItem><ListItemText primary="Manage Clients" /></ListItem>
            <ListItem><ListItemText primary="Manage Property" /></ListItem>
            <ListItem><ListItemText primary="5 Additional Users (Free)" /></ListItem>
            <ListItem><ListItemText primary="Save Documents" /></ListItem>
            <ListItem><ListItemText primary="Software Customizable" /></ListItem>
          </List>
        </CardContent>
        <Box display="flex" justifyContent="center" pb={2}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#ffffff",
              color: "#0E1030",
              borderRadius: "5px",
              padding: "10px 20px",
              textTransform: "none",
              marginRight: "10px",
            }}
            onClick={() => navigate("/ppcart")}
          >
            Buy Now
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#ffffff",
              color: "#0E1030",
              borderRadius: "5px",
              padding: "10px 20px",
              textTransform: "none",
            }}
            onClick={() => console.log("Free Trial Started")}
          >
            Free Trial
          </Button>
        </Box>
      </Card>
    </Grid>
  </Grid>
</Box> */}


        <Box textAlign="center" my={4}>
          <Button
            variant="text"
            style={{
              textTransform: "none",
              border: "none",
              backgroundColor: "transparent",
              color: "#EE3A78",
              fontSize: "18px",
              fontWeight: 500,
              textDecoration: "underline",
              textDecorationColor: "#EE3A78",
              padding: "8px 16px",
            }}
            onClick={handleDiscover}
          >
            Discover more
          </Button>
        </Box>

        <Card
          sx={{
            height: "130px",
            borderRadius: "5px",
            border: "1px solid #F1F1F1",
            padding: "20px",
            display: "flex",
            alignItems: "center",
            boxShadow: "0px 4px 4px 0px #00000040",
            justifyContent: "space-between",
            "@media (max-width: 425px)": {
              height: "auto",
              flexDirection: "column",
              alignItems: "flex-start",
            },
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: "100%",
              "@media (max-width: 430px)": {
                flexDirection: "column",
              },
            }}
          >
            <CardContent sx={{ padding: 0, flexGrow: 1 }}>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  fontWeight: 600,
                  fontSize: "26px",
                  color: "#0E1030",
                  margin: "5px",
                }}
              >
                {planExpiry
                  ? "Your 15 Days Trial Period is Active"
                  : "Start 15-day free trial"}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 400,
                  fontSize: "18px",
                  color: "#0E1030",
                  margin: "5px",
                }}
              >
                {/* {planExpiry ? `Trial period expires on ${planExpiry}` : "Try first and decide later, no payment required"} */}
                {planExpiry
                  ? `Trial period expires on ${new Date(
                      planExpiry
                    ).toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}`
                  : "Try first and decide later, no payment required"}
              </Typography>
            </CardContent>
            {!planExpiry && (
              <Box
                sx={{
                  "@media (max-width: 425px)": {
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "16px",
                  },
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#0E1030",
                    color: "white",
                    borderRadius: "5px",
                    marginTop: "20px",
                    padding: "10px 20px",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#0E1030",
                    },
                    "@media (max-width: 430px)": {
                      width: "100%",
                      marginTop: "16px",
                    },
                  }}
                  onClick={startTrial}
                >
                  Start Trial
                </Button>
              </Box>
            )}
          </Stack>
        </Card>
      </Container>

      <Footer />
    </div>
  );
}

export default Main;
