

import React, { useState, useEffect } from 'react';
import { Card } from "@mui/material";

const NumberInput = () => {
  // Retrieve count from local storage or set to 1 if not available
  const initialCount = localStorage.getItem('count') ? parseInt(localStorage.getItem('count'), 10) : 1;
  const [count, setCount] = useState(initialCount);

  const handleIncrement = () => {
    setCount(count + 1);
  };

  const handleDecrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  useEffect(() => {
    localStorage.setItem('count', count);
  }, [count]);

  return (
    <Card>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <button onClick={handleDecrement} style={buttonStyle}>-</button>
        <span style={{ margin: '0 10px' }}>{count}</span>
        <button onClick={handleIncrement} style={buttonStyle}>+</button>
      </div>
    </Card>
  );
};

const buttonStyle = {
  border: '1px solid #ffff',
  background: 'none',
  padding: '5px 10px',
  cursor: 'pointer',
  fontSize: '16px'
};

export default NumberInput;

