import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { Link as ScrollLink } from "react-scroll";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./NavBar.css";
import Logobluegray from "../../assets/Logobluegray.png";
import Adovref from "../../assets/Advoref.png";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:435px)");

  useEffect(() => {
    const token = localStorage.getItem("subscriptionToken");
    setIsLoggedIn(!!token);
  }, []);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    navigate("/login"); // Redirect the user to the login page
    handleMenuClose(); // Close any open menus ( dropdowns or side menu)
    setDrawerOpen(false); // Close the navigation drawer
  };

  const handleSignUp = () => {
    navigate("/signup"); // Redirect the user to the signup page
    handleMenuClose(); // Close any open menus ( dropdowns or side menus)
    setDrawerOpen(false); // Close the navigation drawer (if open)
  };

  const handleProfile = () => {
    navigate("/profile");
    handleMenuClose();
    setDrawerOpen(false);
  };

  const handleLogout = () => {
    setOpenLogoutDialog(true);
    handleMenuClose();
  };

  const handleLogoutConfirm = () => {
    localStorage.removeItem("subscriptionToken"); // Remove subscription token from localStorage
    localStorage.removeItem("admin_id"); // Remove admin ID from localStorage
    localStorage.removeItem("tabId"); // Remove tab ID from localStorage
    localStorage.removeItem("org_id"); // Remove organization ID from localStorage
    localStorage.removeItem("userDetails"); // Remove user details from localStorage
    console.log("User logged out"); // Log a message to confirm logout
    setOpenLogoutDialog(false); // Close the logout confirmation dialog
    setIsLoggedIn(false); // Update the state to mark the user as logged out
    navigate("/login"); // Redirect the user to the login page after logout
    setDrawerOpen(false); // Close the navigation drawer (if open)
  };

  const handleLogoutCancel = () => {
    setOpenLogoutDialog(false);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleHome = () => {
    navigate("/home");
  };

  const handleNavigateToSection = (section) => {
    navigate(`/home#${section}`); // Navigate to the '/home' page and scroll to the section with the corresponding ID
    setDrawerOpen(false); // Close the navigation drawer (if open)
  };

  const menuItems = (
    <>
      {/* Menu item for navigating to the 'home' section */}
      <ListItem button onClick={() => handleNavigateToSection("home")}>
        <ListItemText primary="Home" />
      </ListItem>

      {/* Menu item for navigating to the 'how-it-works' section */}
      <ListItem button onClick={() => handleNavigateToSection("how-it-works")}>
        <ListItemText primary="How it Works" />
      </ListItem>

      {/* Menu item for navigating to the 'subscription' section */}
      <ListItem button onClick={() => handleNavigateToSection("subscription")}>
        <ListItemText primary="Subscription" />
      </ListItem>

      {/* Menu item for notifications */}
      <ListItem button>
        <NotificationsIcon /> {/* Notification icon without any action */}
      </ListItem>

      {/* Menu item for help or support */}
      <ListItem button>
        <HelpOutlineIcon /> {/* Help icon without any action */}
      </ListItem>

      {/* Conditionally rendering login or profile/logout based on login status */}
      {!isLoggedIn ? (
        <>
          {/* Login button */}
          <ListItem button onClick={handleLogin}>
            <ListItemText primary="Login" />
          </ListItem>

          {/* Sign Up button */}
          <ListItem button onClick={handleSignUp}>
            <ListItemText primary="Sign Up" />
          </ListItem>
        </>
      ) : (
        <>
          {/* Profile button (visible if the user is logged in) */}
          <ListItem button onClick={handleProfile}>
            <ListItemText primary="Profile" />
          </ListItem>

          {/* Logout button (visible if the user is logged in) */}
          <ListItem button onClick={handleLogout}>
            <ListItemText primary="Logout" />
          </ListItem>
        </>
      )}
    </>
  );

  return (
    <>
      <AppBar
        position="sticky"
        //  color="transparent" elevation={0}
        color="default"
        sx={{ backgroundColor: "white", boxShadow: "0px 4px 2px -2px gray" }}
      >
        <Toolbar disableGutters>
          {isMobile && (
            <Button onClick={toggleDrawer(true)}>
              <MenuIcon sx={{ color: "black" }} />
            </Button>
          )}
          <div style={{ margin: "10px", marginLeft: "20px" }}>
            <Button onClick={handleHome} sx={{ margin: "-10px" }}>
              <img src={Adovref} alt="Adovref" style={{ height: "40px" }} />
            </Button>
          </div>
          {!isMobile && (
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
                marginRight: "20px",
              }}
            >
              <Button
                sx={{ textTransform: "none", fontSize: "14px" }}
                onClick={handleHome}
                color="inherit"
              >
                Home
              </Button>
              <Button
                sx={{ textTransform: "none" }}
                onClick={() => handleNavigateToSection("how-it-works")}
                color="inherit"
              >
                How it Works
              </Button>
              <Button
                sx={{ textTransform: "none" }}
                onClick={() => handleNavigateToSection("subscription")}
                color="inherit"
              >
                Subscription
              </Button>
              <button className="iconButton" onClick={handleMenuClick}>
                <AccountCircleIcon />
              </button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {!isLoggedIn ? (
                  <>
                    <MenuItem onClick={handleLogin}>Login</MenuItem>
                    <MenuItem onClick={handleSignUp}>Sign Up</MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem onClick={handleProfile}>Profile</MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </>
                )}
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>{menuItems}</List>
      </Drawer>

      <Dialog
        open={openLogoutDialog} // Controls whether the dialog is open
        onClose={handleLogoutCancel} // Callback when the dialog is closed
        aria-labelledby="logout-dialog-title" // Accessibility label for the title
        aria-describedby="logout-dialog-description" // Accessibility description for the content
      >
        {/* Dialog Title */}
        <DialogTitle id="logout-dialog-title">Confirm Logout</DialogTitle>

        {/* Dialog Content */}
        <DialogContent>
          <DialogContentText id="logout-dialog-description">
            Are you sure you want to logout? {/* Message prompting the user */}
          </DialogContentText>
        </DialogContent>

        {/* Dialog Actions (Buttons) */}
        <DialogActions>
          {/* Cancel button to close the dialog without logging out */}
          <Button onClick={handleLogoutCancel} color="primary">
            Cancel
          </Button>

          {/* Confirm Logout button to proceed with logging out */}
          <Button onClick={handleLogoutConfirm} color="primary" autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Navbar;
