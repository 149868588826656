
import React from "react";
import { Container, Typography, Box } from "@mui/material";
import PrimarySearchAppBar from "../NavBar2/NavBar2";
import Footer2 from "../Footer/Footer2";
import { useNavigate } from "react-router-dom";

// Know more page: On Landing page under home section "Know More" button.

function knowmore() {

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <PrimarySearchAppBar />
      <Container
        sx={{
          flexGrow: 1,
          padding: { xs: 2, sm: 4, md: 6 },
          maxWidth: { xs: "100%", sm: "90%", md: "90%" },
          textAlign: "justify",
        }}
      >
        <Typography style={{fontSize:"18px", fontWeight: 600}} gutterBottom>
        Welcome to Advoref!
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        Adovref is committed to providing smart and efficient solutions for legal professionals. 
        Our dedicated team, Team Altum, has developed a comprehensive software tailored specifically for Advocates. 
        This cloud-based solution simplifies the management of property-related documents, enabling legal practitioners to focus more on their clients and less on paperwork.
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        Our software offers the following key features:
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          <ul>
            <li><b>Document Management:</b> Securely store, organize, and process all property-related documentation.</li>
            <li><b>Automated Client Billing:</b> Calculate fees based on property details, ensuring accurate and hassle-free client invoicing.</li>
            <li><b>Advanced Analytics:</b> Access insightful data to make informed decisions and track client information efficiently.</li>
          </ul>
        </Typography>
        <Typography style={{fontSize:"18px", fontWeight: 600}} gutterBottom>
        Flexible Subscription Plans
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        We offer three flexible plans to meet your specific needs:
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          <ul>
            <li><b>Standard Plan</b> <br />Perfect for small practices, providing essential features to manage your property-related cases.</li>
            <li><b>Premium Plan</b> <br />Ideal for growing law firms, this plan offers additional tools and analytics for enhanced performance.</li>
            <li><b>Premium Pro Plan</b> <br />Designed for large-scale operations, providing full access to advanced features, analytics, and customizations.</li>
          </ul>
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        Each plan comes with quarterly and yearly subscription options, and to ensure it’s the right fit for you, 
        we offer a 15-day free trial—no commitment required.
        </Typography>
        <Typography style={{fontSize:"18px", fontWeight: 600}} gutterBottom>
        Why Choose Us?
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
        With Adovref software, you’ll enjoy:
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>
          <ul>
            <li><b>Ease of Use:</b> A user-friendly interface that streamlines document management and billing.</li>
            <li><b>Security:</b> Your data is stored securely in the cloud, ensuring confidentiality and easy access anytime, anywhere.</li>
            <li><b>Scalability:</b> Whether you’re a solo practitioner or part of a large law firm, our plans adapt to your needs.</li>
          </ul>
        </Typography>
        <Typography style={{fontSize:"14px"}} paragraph>Experience the future of legal tech with Adovref, and elevate your practice to new heights.</Typography>

      </Container>
      <Footer2 />
    </Box>
  );
}

export default knowmore;

