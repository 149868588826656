import React, { useState } from 'react';
import PrimarySearchAppBar from '../NavBar2/NavBar2';
import { Container, Card,  CardContent, Typography, TextField, Button, Link, Box } from '@mui/material';
import { Grid } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Footer2 from '../Footer/Footer2';

//  Form component to add Debit/Credit card info

function AddCard() {
  return (
    <div>
        <PrimarySearchAppBar />
        <Container>
        <Grid mt={2} container spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography sx={{ fontSize: "22px", fontWeight: 700 }}>
              Add Card Details
            </Typography>
            <Box sx={{ width: "100%", mb: 2, mt: 2 }}>
              <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
                Card Number
              </Typography>
              <TextField fullWidth variant="outlined" />
            </Box>
            <Box sx={{ width: "100%", mb: 2, mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
                  Valid Through (MM/YY)
                  </Typography>
                  <TextField fullWidth variant="outlined" />
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
                    CVV
                  </Typography>
                  <TextField fullWidth variant="outlined" />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ width: "100%", mb: 2, mt: 2 }}>
              <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
                Name on Card
              </Typography>
              <TextField fullWidth variant="outlined" />
            </Box>
            <Box sx={{ width: "100%", mb: 2, mt: 2 }}>
              <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
              Card Nickname (for easy identification)
              </Typography>
              <TextField fullWidth variant="outlined" />
            </Box>

          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "50px",
          }}
        >
          <Button
            sx={{
              background: "#632BF0",
              color: "#ffff",
              borderRadius:'6px',
              "&:hover": {
                background: "#632BF0", // Change this to your desired hover color
                color: "#ffffff", // Change this to your desired hover text color
              },
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: 600, padding:'6px'}}>Get OTP</Typography>
          </Button>
        </div>
        </Container>
        <Footer2 />
    </div>
  )
}

export default AddCard