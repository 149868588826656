import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Grid,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  CircularProgress 
} from "@mui/material";
import PrimarySearchAppBar from "../NavBar2/NavBar2";
import Footer from "../Footer/Footer2";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import servicePath from "../Constant/defaultvalues";
import { State, City } from "country-state-city";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

// Edit profile component

function TrialForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [registeredName, setRegisteredName] = useState("");
  const [gstin, setGstin] = useState("");

  const [inputErrFirstName, setInputErrFirstName] = useState(false);
  const [inputErrLastName, setInputErrLastName] = useState(false);
  const [inputErrEmail, setInputErrEmail] = useState(false);
  const [inputErrMobile, setInputErrMobile] = useState(false);
  const [inputErrOrg, setInputErrOrg] = useState(false);
  const [inputErrAdd, setInputErrAdd] = useState(false);
  const [inputErrState, setInputErrState] = useState(false);
  const [inputErrCity, setInputErrCity] = useState(false);
  const [inputErrPinCode, setInputErrPinCode] = useState(false);
  const [inputErrRegisteredName, setInputErrRegisteredName] = useState(false);
  const [inputErrGstin, setInputErrGstin] = useState(false);

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  // useEffect(() => {
  //   const token = localStorage.getItem('subscriptionToken');
  //   if (!token) {
  //     navigate('/signup');
  //   }
  // }, [navigate]);

  const handleBack = () => {
    navigate("/profile");
  };

  // Handler for email input change and validation
const handleEmailChange = (e) => {
  const value = e.target.value;    // Get the email input value
  setEmail(value);                 // Update email state with the new value

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;   // Basic regex for validating email format

  // If the email field is empty
  if (!value) {
    setInputErrEmail(true);        // Set error flag to true
    setErrorMessage("Email is required");  // Display error message
  } 
  // If the email does not match the regex pattern
  else if (!emailRegex.test(value)) {
    setInputErrEmail(true);        // Set error flag to true
    setErrorMessage("Invalid email address");  // Display error message for invalid email
  } 
  // If the email is valid
  else {
    setInputErrEmail(false);       // Set error flag to false (no error)
    setErrorMessage("");           // Clear any error message
  }
};

// Handler for mobile number input change and validation
const handleMobileNumberChange = (e) => {
  const value = e.target.value;    // Get the mobile number input value
  setMobileNumber(value);          // Update mobile number state with the new value

  const isValidNumber = /^\d{10}$/.test(value);   // Regular expression to check if the value is exactly 10 digits

  // If the mobile number field is empty
  if (value.length === 0) {
    setInputErrMobile(true);       // Set error flag to true
    setErrorMessage("Mobile number is required");  // Display error message for missing mobile number
  } 
  // If the mobile number is not exactly 10 digits
  else if (!isValidNumber) {
    setInputErrMobile(true);       // Set error flag to true
    setErrorMessage("Mobile number must be exactly 10 digits");  // Display error message for incorrect format
  } 
  // If the mobile number is valid
  else {
    setInputErrMobile(false);      // Set error flag to false (no error)
    setErrorMessage("");           // Clear any error message
  }
};


  useEffect(() => {
    const fetchedStates = State.getStatesOfCountry("IN");
    setStates(fetchedStates);
  }, []);

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setState(selectedState);

    const fetchedCities = City.getCitiesOfState("IN", selectedState);
    setCities(fetchedCities);
    setCity("");
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const navigate = useNavigate();

  // const handleSubmit = async (event) => {
  //   event.preventDefault();  // Prevents the default form submission behavior
  
  //   // Reset all error states to false before validation
  //   setInputErrFirstName(false);
  //   setInputErrLastName(false);
  //   setInputErrEmail(false);
  //   setInputErrMobile(false);
  //   setInputErrOrg(false);
  //   setInputErrAdd(false);
  //   setInputErrState(false);
  //   setInputErrCity(false);
  //   setInputErrPinCode(false);
  
  //   // Perform validation: check if any required fields are empty
  //   if (!firstName) setInputErrFirstName(true);       // Mark first name as an error if it's empty
  //   if (!lastName) setInputErrLastName(true);         // Mark last name as an error if it's empty
  //   if (!email) setInputErrEmail(true);               // Mark email as an error if it's empty
  //   if (!mobileNumber) setInputErrMobile(true);       // Mark mobile number as an error if it's empty
  //   if (!organizationName) setInputErrOrg(true);      // Mark organization name as an error if it's empty
  //   if (!address) setInputErrAdd(true);               // Mark address as an error if it's empty
  //   if (!state) setInputErrState(true);               // Mark state as an error if it's empty
  //   if (!city) setInputErrCity(true);                 // Mark city as an error if it's empty
  //   if (!pinCode) setInputErrPinCode(true);           // Mark pincode as an error if it's empty
  
  //   // If any required field is missing, show an error toast and stop form submission
  //   if (
  //     !firstName ||
  //     !lastName ||
  //     !email ||
  //     !mobileNumber ||
  //     !organizationName ||
  //     !address ||
  //     !state ||
  //     !city ||
  //     !pinCode
  //   ) {
  //     toast.error("Please enter all the mandatory (*) fields", {  // Show error message
  //       position: "top-right",
  //       autoClose: 2000,
  //     });
  //     return; // Stop further execution if validation fails
  //   }
  
  //   try {
  //     // Create the request payload with form data
  //     const requestData = {
  //       first_name: firstName,
  //       last_name: lastName,
  //       email: email,
  //       mobile: mobileNumber,
  //       org_name: organizationName,
  //       reg_name: registeredName,    // Registered Name (optional)
  //       gstin,                       // GSTIN (optional)
  //       address,
  //       state,
  //       city,
  //       pincode: pinCode,
  //       org_id: localStorage.getItem("org_id"),  // Retrieve org_id from localStorage
  //     };
  
  //     // Send a POST request to update the profile
  //     const response = await axios.post(
  //       `${servicePath}/updateProfile`,
  //       requestData
  //     );
  
  //     // Check if the response status code is success ("00")
  //     if (response.data.statusCode === "00") {
  //       // Reset the form fields after a successful update
  //       setFirstName("");
  //       setLastName("");
  //       setEmail("");
  //       setMobileNumber("");
  //       setOrganizationName("");
  //       setRegisteredName("");
  //       setGstin("");
  //       setAddress("");
  //       setState("");
  //       setCity("");
  //       setPinCode("");
  
  //       navigate("/profile");  // Redirect the user to the profile page after updating
  
  //       toast.success("Profile updated successfully!", {  // Show success message
  //         position: "top-right",
  //         autoClose: 2000,
  //       });
  //     } else {
  //       // Show an error toast if the profile update fails with a specific message
  //       toast.error("Failed to update profile! - " + response.data.message, {
  //         position: "top-right",
  //         autoClose: 2000,
  //       });
  //     }
  //   } catch (error) {
  //     // Log the error and show a toast for general failure in profile update
  //     console.error("Error in Updating Profile:", error);
  //     toast.error("Failed to update profile!", {
  //       position: "top-right",
  //       autoClose: 2000,
  //     });
  //   }
  // };
  
  const handleSubmit = async (event) => {
    event.preventDefault();  // Prevents the default form submission behavior

    // Reset all error states to false before validation
    setInputErrFirstName(false);
    setInputErrLastName(false);
    setInputErrEmail(false);
    setInputErrMobile(false);
    setInputErrOrg(false);
    setInputErrAdd(false);
    setInputErrState(false);
    setInputErrCity(false);
    setInputErrPinCode(false);

    // Perform validation: check if any required fields are empty
    if (!firstName) setInputErrFirstName(true);       // Mark first name as an error if it's empty
    if (!lastName) setInputErrLastName(true);         // Mark last name as an error if it's empty
    if (!email) setInputErrEmail(true);               // Mark email as an error if it's empty
    if (!mobileNumber) setInputErrMobile(true);       // Mark mobile number as an error if it's empty
    if (!organizationName) setInputErrOrg(true);      // Mark organization name as an error if it's empty
    if (!address) setInputErrAdd(true);               // Mark address as an error if it's empty
    if (!state) setInputErrState(true);               // Mark state as an error if it's empty
    if (!city) setInputErrCity(true);                 // Mark city as an error if it's empty
    if (!pinCode) setInputErrPinCode(true);           // Mark pincode as an error if it's empty

    // If any required field is missing, show an error toast and stop form submission
    if (
      !firstName ||
      !lastName ||
      !email ||
      !mobileNumber ||
      !organizationName ||
      !address ||
      !state ||
      !city ||
      !pinCode
    ) {
      toast.error("Please enter all the mandatory (*) fields", {  // Show error message
        position: "top-right",
        autoClose: 2000,
      });
      return; // Stop further execution if validation fails
    }

    setLoading(true); // Show loader

    try {
      // Create the request payload with form data
      const requestData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        mobile: mobileNumber,
        org_name: organizationName,
        reg_name: registeredName,    // Registered Name (optional)
        gstin,                       // GSTIN (optional)
        address,
        state,
        city,
        pincode: pinCode,
        org_id: localStorage.getItem("org_id"),  // Retrieve org_id from localStorage
      };

      // Send a POST request to update the profile
      const response = await axios.post(
        `${servicePath}/updateProfile`,
        requestData
      );

      // Check if the response status code is success ("00")
      if (response.data.statusCode === "00") {
        // Reset the form fields after a successful update
        setFirstName("");
        setLastName("");
        setEmail("");
        setMobileNumber("");
        setOrganizationName("");
        setRegisteredName("");
        setGstin("");
        setAddress("");
        setState("");
        setCity("");
        setPinCode("");

        navigate("/profile");  // Redirect the user to the profile page after updating

        toast.success("Profile updated successfully!", {  // Show success message
          position: "top-right",
          autoClose: 2000,
        });
      } else {
        // Show an error toast if the profile update fails with a specific message
        toast.error("Failed to update profile! - " + response.data.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } catch (error) {
      // Log the error and show a toast for general failure in profile update
      console.error("Error in Updating Profile:", error);
      toast.error("Failed to update profile!", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      setLoading(false); // Hide loader after operation
    }
  };

  useEffect(() => {
    // Fetch the 'admin_id' from localStorage
    const admin_id = localStorage.getItem("admin_id");
  
    // If 'admin_id' is not found, log an error and exit the function
    if (!admin_id) {
      console.error("admin_id is not found in localStorage.");
      return;
    }
  
    // Make an API call to fetch the profile details using the stored 'admin_id'
    axios
      .get(`${servicePath}/profiledetails`, {
        headers: {
          admin_id: admin_id, // Set the 'admin_id' in the headers
        },
      })
      .then((response) => {
        // Assuming the first result from the response is the needed data
        const data = response.data[0]; 
  
        // Extract the first name and last name from 'poc' (point of contact)
        setFirstName(data.poc.split(" ")[0] || ""); 
        setLastName(data.poc.split(" ")[1] || ""); 
  
        // Set other profile-related information
        setEmail(data.email || ""); 
        setMobileNumber(data.mobile || ""); 
        setOrganizationName(data.org_name || ""); 
        setRegisteredName(data.reg_name || ""); 
        setGstin(data.gstin || ""); 
        setAddress(data.address || ""); 
        setState(data.state || ""); 
        setCity(data.city || ""); 
        setPinCode(data.pincode || ""); 
  
        // If the state is available, fetch the corresponding cities
        if (data.state) {
          // Load cities for the selected state using the 'City' library
          const fetchedCities = City.getCitiesOfState("IN", data.state); 
          setCities(fetchedCities); // Update the cities state with fetched cities
        }
      })
      .catch((error) => {
        // Log any error that occurs during the API call
        console.error("Error fetching profile details:", error); 
      });
  }, []); // Empty dependency array ensures the effect runs only once on mount
  

  const textFieldStyles = {
    // border: "1px solid #E0E0E0",
    // borderRadius: "6px",
    // backgroundColor: "#FFFFFF",
    // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
    // fontSize: "14px",
    // "& .MuiInputBase-root": {
    //   height: "40px", // adjust the height of the input field
    //   padding: "0 0px", // adjust padding inside the input field
    // },
  };

  return (

    
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      sx={{
        // backgroundImage: `url(${profilebg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        justifyContent: "center",
      }}
    >
      {loading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1200, // Ensure the loader is on top of other elements
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      
      <PrimarySearchAppBar />
      <Box py={2} px={4} component="main" sx={{ flexGrow: 1 }}>
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={8} md={4}>
            <form style={{ padding: 20 }} onSubmit={handleSubmit}>
              <Box textAlign="left" my={2}>
                <Button
                  variant="text"
                  style={{
                    textTransform: "none",
                    border: "none",
                    backgroundColor: "transparent",
                    color: "#0E1030",
                    fontSize: "26px",
                    fontWeight: 600,
           
                    padding: "0px 0px",
                  }}
                  onClick={handleBack}
                >
                  <ArrowCircleLeftIcon sx={{ marginRight: "10px" }} /> Edit
                  Profile
                </Button>
              </Box>
              <hr />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    error={inputErrFirstName}
                    helperText={inputErrFirstName && "First Name is required"}
                    fullWidth
                    variant="outlined"
                    sx={textFieldStyles}
                    style={{marginTop:"15px"}}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    error={inputErrLastName}
                    helperText={inputErrLastName && "Last Name is required"}
                    fullWidth
                    variant="outlined"
                    sx={textFieldStyles}
                    style={{marginTop:"15px"}}

                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    id="email"
                    name="email"
                    value={email}
                    label="Email Address"
                    onChange={handleEmailChange}
                    error={inputErrEmail}
                    helperText={inputErrEmail && errorMessage}
                    fullWidth
                    variant="outlined"
                    sx={textFieldStyles}
                    inputProps={{
                      maxLength: 10,
                    }}
                    disabled 
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="mobileNumber"
                    name="mobileNumber"
                    label="Mobile Number"
                    value={mobileNumber}
                    onChange={handleMobileNumberChange}
                    error={inputErrMobile}
                    helperText={inputErrMobile && errorMessage}
                    fullWidth
                    variant="outlined"
                    sx={textFieldStyles}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="organizationName"
                    name="organizationName"
                    label="Organization Name"
                    value={organizationName}
                    onChange={(e) => setOrganizationName(e.target.value)}
                    error={inputErrOrg}
                    helperText={inputErrOrg && "Organization Name is required"}
                    fullWidth
                    variant="outlined"
                    sx={textFieldStyles}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    // required
                    id="registeredName"
                    name="registeredName"
                    label="Registered Name"
                    value={registeredName}
                    onChange={(e) => setRegisteredName(e.target.value)}
                    // error={inputErrOrg}
                    // helperText={inputErrOrg && "Registered Name is required"}
                    fullWidth
                    variant="outlined"
                    sx={textFieldStyles}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    // required
                    id="gstin"
                    name="gstin"
                    label="GSTIN"
                    value={gstin}
                    onChange={(e) => setGstin(e.target.value)}
                    // error={inputErrGstin}
                    // helperText={inputErrGstin && "GSTIN Name is required"}
                    fullWidth
                    variant="outlined"
                    sx={textFieldStyles}
                  />
                </Grid>
 
                <Grid item xs={12} sm={12} >
                  <TextField
                    required
                    id="address"
                    name="address"
                    label="Address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    error={inputErrAdd}
                    helperText={inputErrAdd && "Address is required"}
                    fullWidth
                    variant="outlined"
                    sx={textFieldStyles}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined" required>
                    <Select
                      id="state"
                      name="state"
                      label="State"
                      value={state}
                      onChange={handleStateChange}
                      error={inputErrState}
                      sx={textFieldStyles}
                    >
                      {states.map((state) => (
                        <MenuItem key={state.isoCode} value={state.isoCode}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {inputErrState && (
                      <FormHelperText error>State is required</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined" required>
                    <Select
                      id="city"
                      name="city"
                      label="City"
                      value={city}
                      onChange={handleCityChange}
                      error={inputErrCity}
                      sx={textFieldStyles}
                    >
                      {cities.map((city) => (
                        <MenuItem key={city.name} value={city.name}>
                          {city.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {inputErrCity && (
                      <FormHelperText error>City is required</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="pinCode"
                    name="pinCode"
                    label="Pin Code"
                    value={pinCode}
                    onChange={(e) => setPinCode(e.target.value)}
                    error={inputErrPinCode}
                    helperText={inputErrPinCode && "Pin Code is required"}
                    fullWidth
                    variant="outlined"
                    sx={textFieldStyles}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box textAlign="center" mt={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{
                        backgroundColor: "#1E1E2D",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#0E1030",
                        },
                      }}
                    >
                      Update Profile
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
}

export default TrialForm;
