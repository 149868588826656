import React, { useEffect } from 'react';
import PrimarySearchAppBar from '../NavBar2/NavBar2';
import { Container, Card, Typography, TextField, Button, Link, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Grid } from "@mui/material";
import NumberInput from './NumberInput';
import Footer2 from '../Footer/Footer2';
import { useNavigate } from 'react-router-dom';
import "../Cart/Cart.css";
import { useLocation  } from 'react-router-dom';

function Pcart() {

  const location = useLocation();
  // const { selectedPlan } = location.state || { selectedPlan: { name: '', price: '' } };

  const { planName, planType, amount } = location.state || {};

  const handleCheckout = () => {
    const selectedPlan = {
      name: planName, // Or retrieve it from location.state
      price: amount,
      planType: planType,
    };

    navigate('/checkout', { state: { selectedPlan } });
  };

  const navigate = useNavigate();
  
  useEffect(() => {
    const token = localStorage.getItem('subscriptionToken');
    if (!token) {
      navigate('/signup');
    }
  }, [navigate]);

  // const handleCheckout = (event) => {
  //   navigate('/checkout', { state: { selectedPlan } });
  // };
  
  return (
    <div>
      <PrimarySearchAppBar />
      <Typography
        sx={{
          fontSize: "22px",
          fontWeight: 700,
          textAlign: "center",
          marginTop: "32px",
        }}
      >
        Cart
      </Typography>
      <Container>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="pricing table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: "18px", fontWeight: 600 }}>
                  Product
                </TableCell>
                <TableCell
                  sx={{ fontSize: "18px", fontWeight: 600 }}
                  align="left"
                >
                  Price
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {/* <TableCell
                  sx={{ fontSize: "16px", fontWeight: 500 }}
                  component="th"
                  scope="row"
                >
                   Advoref Premium Plan - 4 License
                </TableCell>
                <TableCell
                  sx={{ fontSize: "16px", fontWeight: 500 }}
                  align="left"
                >
                   ₹ 15000 / year
                </TableCell> */}
                <TableCell sx={{ fontSize: "16px", fontWeight: 500 }} component="th" scope="row">
            Advoref {planName} {planType} Plan - 4 License
          </TableCell>
          <TableCell sx={{ fontSize: "16px", fontWeight: 500 }} align="left">
            {amount} / {planType}
          </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ fontSize: "16px", fontWeight: 500 }}
                  component="th"
                  scope="row"
                >
                  Discount
                </TableCell>
                <TableCell
                  sx={{ fontSize: "16px", fontWeight: 500 }}
                  align="left"
                >
                  - ₹ ****
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ fontSize: "16px", fontWeight: 500 }}
                  component="th"
                  scope="row"
                >
                  Total
                </TableCell>
                <TableCell
                  sx={{ fontSize: "16px", fontWeight: 500 }}
                  align="left"
                >
                  ₹ ****
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Container>
        <Grid mt={5} container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography sx={{ fontSize: "22px", fontWeight: 700 }}>
              Here’s what you get
            </Typography>
            <Typography
              sx={{ fontSize: "16px", fontWeight: 600, marginTop: "20px" }}
            >
              Advoref Premium Plan
            </Typography>
            <Typography
              sx={{ fontSize: "16px", fontWeight: 500, marginTop: "20px" }}
            >
              Price: {amount} / {planType}
            </Typography>
            <Typography
              sx={{ fontSize: "16px", fontWeight: 500, marginTop: "15px" }}
            >
              Features:
            </Typography>
            <Typography>
              <div className="list">
                <ul>
                  <li className="list-item">Add up to 10 Client Profiles</li>
                  <li className="list-item">
                    Manage up to 10 Property Records
                  </li>
                  <li className="list-item">Priority Support</li>
                  <li className="list-item">
                    3 User Licenses with the subscription
                  </li>
                  <li className="list-item">
                    Advanced Reporting and Analytics
                  </li>
                  <li className="list-item">All Features in Basic Plan</li>
                  <li className="list-item">Enhanced Security Features</li>
                </ul>
              </div>
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card
              sx={{
                marginTop: "50px",
                padding: "30px",
                border: "1px solid #C0B2B2",
              }}
            >
              <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                Additional Licenses
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 500, marginTop: "20px" }}
              >
                Need More User Licenses?
              </Typography>
              <Typography>
                <div className="list">
                  <ul>
                    <li className="list-item">
                      Additional licenses can be purchased for (rupees) per user
                      per month.
                    </li>
                    <li className="list-item">
                      Easily expand your team’s access to the software.
                    </li>
                    <li className="list-item">
                      Manage licenses directly from your account settings.
                    </li>
                  </ul>
                </div>
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    marginTop: "20px",
                    marginRight: "30px",
                    color: "#DF3879",
                  }}
                >
                  Add license to cart
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 600, marginTop: "20px" }}
                >
                  <NumberInput />
                </Typography>
              </div>
            </Card>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <Button
            sx={{
              background: "#0E1030",
              color: "#ffff",
              borderRadius:'6px',
              "&:hover": {
                background: "#0E1030", // Change this to your desired hover color
                color: "#ffffff", // Change this to your desired hover text color
              },
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: 600, padding:'6px'}} onClick={handleCheckout}>Proceed</Typography>
          </Button>
        </div>
      </Container>
      <Footer2 />
    </div>
  );
}

export default Pcart