import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  CircularProgress
} from "@mui/material";
import PrimarySearchAppBar from "../NavBar2/NavBar2";
import Footer from "../Footer/Footer2";
import profilebg from "../../assets/profilebg.png";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import servicePath from "../Constant/defaultvalues";
import { State, City } from "country-state-city";

// This component is used to confirm user details before starting the free trail! 

function TrialForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [loading, setLoading] = useState(false);

  const [inputErrFirstName, setInputErrFirstName] = useState(false);
  const [inputErrLastName, setInputErrLastName] = useState(false);
  const [inputErrEmail, setInputErrEmail] = useState(false);
  const [inputErrMobile, setInputErrMobile] = useState(false);
  const [inputErrOrg, setInputErrOrg] = useState(false);
  const [inputErrAdd, setInputErrAdd] = useState(false);
  const [inputErrState, setInputErrState] = useState(false);
  const [inputErrCity, setInputErrCity] = useState(false);
  const [inputErrPinCode, setInputErrPinCode] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value) {
      setInputErrEmail(true);
      setErrorMessage("Email is required");
    } else if (!emailRegex.test(value)) {
      setInputErrEmail(true);
      setErrorMessage("Invalid email address");
    } else {
      setInputErrEmail(false);
      setErrorMessage("");
    }
  };

  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    setMobileNumber(value);

    const isValidNumber = /^\d{10}$/.test(value);
    if (value.length === 0) {
      setInputErrMobile(true);
      setErrorMessage("Mobile number is required");
    } else if (!isValidNumber) {
      setInputErrMobile(true);
      setErrorMessage("Mobile number must be exactly 10 digits");
    } else {
      setInputErrMobile(false);
      setErrorMessage("");
    }
  };

  useEffect(() => {
    const fetchedStates = State.getStatesOfCountry("IN");
    setStates(fetchedStates);
  }, []);

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setState(selectedState);

    const fetchedCities = City.getCitiesOfState("IN", selectedState);
    setCities(fetchedCities);
    setCity("");
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    setInputErrFirstName(false);
    setInputErrLastName(false);
    setInputErrEmail(false);
    setInputErrMobile(false);
    setInputErrOrg(false);
    setInputErrAdd(false);
    setInputErrState(false);
    setInputErrCity(false);
    setInputErrPinCode(false);

    if (!firstName) setInputErrFirstName(true);
    if (!lastName) setInputErrLastName(true);
    if (!email) setInputErrEmail(true);
    if (!mobileNumber) setInputErrMobile(true);
    if (!organizationName) setInputErrOrg(true);
    if (!address) setInputErrAdd(true);
    if (!state) setInputErrState(true);
    if (!city) setInputErrCity(true);
    if (!pinCode) setInputErrPinCode(true);

    if (
      !firstName ||
      !lastName ||
      !email ||
      !mobileNumber ||
      !organizationName ||
      !address ||
      !state ||
      !city ||
      !pinCode
    ) {
      toast.error("Please enter all the mandatory (*) fields", {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }

    setLoading(true);

    try {
      const requestData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        mobile: mobileNumber,
        org_name: organizationName,
        address,
        state,
        city,
        pincode: pinCode,
        admin_id: localStorage.getItem("admin_id"),
        org_id: localStorage.getItem("org_id"),
      };

      const response = await axios.post(
        `${servicePath}/startTrial`,
        requestData
      );

      if (response.data.statusCode === "00") {
        setFirstName("");
        setLastName("");
        setEmail("");
        setMobileNumber("");
        setOrganizationName("");
        setAddress("");
        setState("");
        setCity("");
        setPinCode("");

        navigate("/starttrail");

        toast.success("15 Days Free Trial is Active!", {
          position: "top-right",
          autoClose: 2000,
        });
      } else {
        toast.error("Error! - " + response.data.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error! - ", error);
      toast.error("FError! - ", {
        position: "top-right",
        autoClose: 2000,
      });
    }
    finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    const admin_id = localStorage.getItem("admin_id");

    if (!admin_id) {
      console.error("admin_id is not found in localStorage.");
      return;
    }

    axios
      .get(`${servicePath}/profiledetails`, {
        headers: {
          admin_id: admin_id,
        },
      })
      .then((response) => {
        const data = response.data[0]; // Assuming the first result is needed
        setFirstName(data.poc.split(" ")[0] || "");
        setLastName(data.poc.split(" ")[1] || "");
        setEmail(data.email || "");
        setMobileNumber(data.mobile || "");
        setOrganizationName(data.org_name || "");
        setAddress(data.address || "");
        setState(data.state || "");
        setCity(data.city || "");
        setPinCode(data.pincode || "");

        // Load cities for the selected state
        if (data.state) {
          const fetchedCities = City.getCitiesOfState("IN", data.state);
          setCities(fetchedCities);
        }
      })
      .catch((error) => {
        console.error("Error fetching profile details:", error);
      });
  }, []);

  const textFieldStyles = {
    // border: "1px solid #E0E0E0",
    // borderRadius: "6px",
    // backgroundColor: "#FFFFFF",
    // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
    // width: "100%",
    // fontSize: "14px",
    // "& .MuiInputBase-root": {
    //   height: "45px", // adjust the height of the input field
    //   padding: "0 0px", // adjust padding inside the input field
    // },

  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      sx={{
        // backgroundImage: `url(${profilebg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        justifyContent: "center",
      }}
    >
      <PrimarySearchAppBar />
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)",  // Semi-transparent background
            zIndex: 9999,  // Ensure it's on top of other elements
          }}
        >
          <CircularProgress size={80} />
        </Box>
      )}
      
      <Box py={2} px={4} component="main" sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={8} md={4}>
      <form style={{ padding: 20 }} onSubmit={handleSubmit}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontSize: "26px",
              fontWeight: 700,
              color: "#111439",
            }}
          >
            Confirm your details for Free Trial
          </Typography>
          <hr />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="firstName"
                name="firstName"
                label="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                error={inputErrFirstName}
                helperText={inputErrFirstName && "First Name is required"}
                fullWidth
                variant="outlined"
                sx={textFieldStyles}
                style={{marginTop:"15px"}}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="lastName"
                name="lastName"
                label="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                error={inputErrLastName}
                helperText={inputErrLastName && "Last Name is required"}
                fullWidth
                variant="outlined"
                sx={textFieldStyles}
                style={{marginTop:"15px"}}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="subtitle1">Email Address</Typography>
              <TextField
                required
                id="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                error={inputErrEmail}
                helperText={inputErrEmail && errorMessage}
                fullWidth
                variant="outlined"
                sx={textFieldStyles}
                inputProps={{
                  maxLength: 10,
                }}
                disabled 
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="mobileNumber"
                name="mobileNumber"
                label="Mobile Number"
                value={mobileNumber}
                onChange={handleMobileNumberChange}
                error={inputErrMobile}
                helperText={inputErrMobile && errorMessage}
                fullWidth
                variant="outlined"
                sx={textFieldStyles}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="organizationName"
                name="organizationName"
                label="Organization Name"
                value={organizationName}
                onChange={(e) => setOrganizationName(e.target.value)}
                error={inputErrOrg}
                helperText={inputErrOrg && "Organization Name is required"}
                fullWidth
                variant="outlined"
                sx={textFieldStyles}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                required
                id="address"
                name="address"
                label="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                error={inputErrAdd}
                helperText={inputErrAdd && "Address is required"}
                fullWidth
                variant="outlined"
                sx={textFieldStyles}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" required>
                <Select
                  id="state"
                  name="state"
                  label="State"
                  value={state}
                  onChange={handleStateChange}
                  error={inputErrState}
                  sx={textFieldStyles}
                >
                  {states.map((state) => (
                    <MenuItem key={state.isoCode} value={state.isoCode}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
                {inputErrState && (
                  <FormHelperText error>State is required</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" required>
                <Select
                  id="city"
                  name="city"
                  label="City"
                  value={city}
                  onChange={handleCityChange}
                  error={inputErrCity}
                  sx={textFieldStyles}
                >
                  {cities.map((city) => (
                    <MenuItem key={city.name} value={city.name}>
                      {city.name}
                    </MenuItem>
                  ))}
                </Select>
                {inputErrCity && (
                  <FormHelperText error>City is required</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="pinCode"
                name="pinCode"
                value={pinCode}
                onChange={(e) => setPinCode(e.target.value)}
                error={inputErrPinCode}
                helperText={inputErrPinCode && "Pin Code is required"}
                fullWidth
                variant="outlined"
                sx={textFieldStyles}
              />
            </Grid>
            <Grid item xs={12}>
              <Box textAlign="center" mt={3}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: "#1E1E2D",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#0E1030",
                    },
                  }}
                >
                  Start Trial
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Grid>
      </Grid>
        
      </Box>
      <Footer />
    </Box>
  );
}

export default TrialForm;
