import React, { useState, useEffect } from 'react';
import PrimarySearchAppBar from '../NavBar2/NavBar2';
import { Container, Card,  CardContent, Typography, TextField, Button, Link, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import { Grid } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Footer2 from '../Footer/Footer2';
import visa from '../../assets/visa.png';
import masretcard from '../../assets/mastercard.png';
import paytm from '../../assets/paytm.png'
import gpay from '../../assets/gpay.png'
import phonepay from '../../assets/phonepay.png'
import { useLocation } from 'react-router-dom';


function Checkout() {

  const location = useLocation();
  const { selectedPlan } = location.state || {};
  // const { selectedPlan } = location.state || { selectedPlan: { name: '', price: '' } };

  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [firstName, setFirstName] = useState(localStorage.getItem('firstName') || '');
  const [lastName, setLastName] = useState(localStorage.getItem('lastName') || '');
  const [password, setPassword] = useState(localStorage.getItem('password') || '');

  const [emailError, setEmailError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [passwordError, setPasswordError] = useState('');

    const [selectedOption, setSelectedOption] = useState('');
    const navigate = useNavigate();

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    const token = localStorage.getItem('subscriptionToken');
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);


  const handleCheckout = (event) => {
    // navigate('/org');
  };

  // useEffect(() => {
  //   localStorage.setItem('email', email);
  // }, [email]);

  // useEffect(() => {
  //   localStorage.setItem('firstName', firstName);
  // }, [firstName]);

  // useEffect(() => {
  //   localStorage.setItem('lastName', lastName);
  // }, [lastName]);

  // useEffect(() => {
  //   localStorage.setItem('password', password);
  // }, [password]);

  const validatePassword = (password) => {
    return password.length >= 6; // Example validation: password should be at least 6 characters long
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (!validateEmail(newEmail)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
    }
  };

  const handleFirstNameChange = (e) => {
    const newFirstName = e.target.value;
    setFirstName(newFirstName);
    if (!newFirstName) {
      setFirstNameError('First name is required');
    } else {
      setFirstNameError('');
    }
  };

  const handleLastNameChange = (e) => {
    const newLastName = e.target.value;
    setLastName(newLastName);
    if (!newLastName) {
      setLastNameError('Last name is required');
    } else {
      setLastNameError('');
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (!validatePassword(newPassword)) {
      setPasswordError('Password must be at least 6 characters long');
    } else {
      setPasswordError('');
    }
  };

  return (
    <div>
      <PrimarySearchAppBar />
      <Typography
        sx={{
          fontSize: "22px",
          fontWeight: 700,
          textAlign: "center",
          marginTop: "32px",
        }}
      >
        Checkout
      </Typography>
      <Container>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="pricing table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: "18px", fontWeight: 600 }}>
                  Product
                </TableCell>
                <TableCell
                  sx={{ fontSize: "18px", fontWeight: 600 }}
                  align="left"
                >
                  Price
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
              <TableCell
            sx={{ fontSize: "16px", fontWeight: 500 }}
            component="th"
            scope="row"
          >
            Advoref {selectedPlan?.name} Plan - 4 License
          </TableCell>
          <TableCell
            sx={{ fontSize: "16px", fontWeight: 500 }}
            align="left"
          >
            {selectedPlan?.price} / {selectedPlan?.planType}
          </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ fontSize: "16px", fontWeight: 500 }}
                  component="th"
                  scope="row"
                >
                  Discount
                </TableCell>
                <TableCell
                  sx={{ fontSize: "16px", fontWeight: 500 }}
                  align="left"
                >
                  - ₹ ****
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ fontSize: "16px", fontWeight: 500 }}
                  component="th"
                  scope="row"
                >
                  Total
                </TableCell>
                <TableCell
                  sx={{ fontSize: "16px", fontWeight: 500 }}
                  align="left"
                >
                  ₹ ****
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Container>
        <Grid mt={5} container spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography sx={{ fontSize: "22px", fontWeight: 700 }}>
              Account Information
            </Typography>
            <Box sx={{ width: "100%", mb: 2, mt: 4 }}>
        <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
          Email Address
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          value={email}
          onChange={handleEmailChange}
          error={!!emailError}
          helperText={emailError}
        />
      </Box>
      <Box sx={{ width: "100%", mb: 2, mt: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
              First Name
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={firstName}
              onChange={handleFirstNameChange}
              error={!!firstNameError}
              helperText={firstNameError}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
              Last Name
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={lastName}
              onChange={handleLastNameChange}
              error={!!lastNameError}
              helperText={lastNameError}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: "100%", mb: 2, mt: 4 }}>
        <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 1 }}>
          Password
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          type="password"
          value={password}
          onChange={handlePasswordChange}
          error={!!passwordError}
          helperText={passwordError}
        />
      </Box>

            <Typography
              sx={{ fontSize: "22px", fontWeight: 700, marginTop: "50px" }}
            >
              Payment Options
            </Typography>
            <Typography
              sx={{ fontSize: "20px", fontWeight: 500, marginTop: "20px" }}
            >
              Card Payment
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="payment method"
                name="paymentMethod"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <FormControlLabel
                  value="debit"
                  control={<Radio />}
                  label={
                    <Box display="flex" alignItems="center">
                      <img
                        src={visa}
                        alt="Debit Card"
                        style={{ marginRight: 8, width: '50px', height: '50px' }}
                      />
                      <Typography>Debit card</Typography>
                    </Box>
                  }
                />
                <FormControlLabel
                  value="credit"
                  control={<Radio />}
                  label={
                    <Box display="flex" alignItems="center">
                      <img
                        src={masretcard}
                        alt="Credit Card"
                        style={{ marginRight: 8, width: '50px', height: '50px' }}
                      />
                      <Typography>Credit card</Typography>
                    </Box>
                  }
                />
              {/* </RadioGroup> */}
              <Typography
              sx={{ fontSize: "20px", fontWeight: 500, marginTop: "10px" }}
            >
              UPI
            </Typography>
            <FormControlLabel
                  value="paytm"
                  control={<Radio />}
                  label={
                    <Box display="flex" alignItems="center">
                      <img
                        src={paytm}
                        alt="Debit Card"
                        style={{ marginRight: 8,width: '30px', height: '30px', marginBottom:10 }}
                      />
                      <Typography>Paytm UPI</Typography> 
                    </Box>
                  }
                />
                <FormControlLabel
                  value="google"
                  control={<Radio />}
                  label={
                    <Box display="flex" alignItems="center">
                      <img
                        src={gpay}
                        alt="Debit Card"
                        style={{ marginRight: 8, width: '30px', height: '30px' }}
                      />
                      <Typography>Google Pay</Typography>
                    </Box>
                  }
                />
                <FormControlLabel
                  value="phone"
                  control={<Radio />}
                  label={
                    <Box display="flex" alignItems="center">
                      <img
                        src={phonepay}
                        alt="Debit Card"
                        style={{ marginRight: 8, width: '30px', height: '30px' }}
                      />
                      <Typography>Phone Pay</Typography>
                    </Box>
                  }
                />
                </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "50px",
          }}
        >
          <Button
            sx={{
              background: "#0E1030",
              color: "#ffff",
              borderRadius:'6px',
              "&:hover": {
                background: "#0E1030", // Change this to your desired hover color
                color: "#ffffff", // Change this to your desired hover text color
              },
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: 600, padding:'6px'}} onClick={handleCheckout}>Proceed</Typography>
          </Button>
        </div>
      </Container>
      <Footer2 />
    </div>
  );
}

export default Checkout