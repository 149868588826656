// import React from "react";
// import { Grid, Button, Typography, Link, useMediaQuery } from "@mui/material";
// import { styled } from "@mui/system";
// import { useTheme } from "@mui/material/styles";
// import { useNavigate } from "react-router-dom";
// import "./Footer.css";

// const FooterContainer = styled("footer")({
//   width: "100%",
// });

// const TopSection = styled(Grid)(({ theme }) => ({
//   backgroundColor: "#EBEEF3",
//   textAlign: "center",
//   padding: "32px 16px",
//   minHeight: "220px",
//   [theme.breakpoints.down("sm")]: {
//     padding: "24px 16px",
//   },
// }));

// const BottomSection = styled(Grid)(({ theme }) => ({
//   backgroundColor: "#444444",
//   color: "#FFFFFF",
//   textAlign: "center",
//   padding: "16px",
//   [theme.breakpoints.down("sm")]: {
//     textAlign: "center",
//   },
// }));

// const FooterText = styled(Typography)(({ theme }) => ({
//   fontSize: "26px",
//   fontWeight: 600,
//   [theme.breakpoints.down("sm")]: {
//     fontSize: "24px",
//   },
// }));

// const FooterButton = styled(Button)({
//   marginTop: "32px",
//   width: "200px",
//   borderRadius: "5px",
//   fontSize: "18px",
//   fontWeight: 500,
//   background: "#FCFCFE",
//   color: "black",
//   "&:hover": {
//     backgroundColor: "#FCFCFE",
//     color: "black",
//     boxShadow: "none",
//   },
// });

// const FooterLink = styled(Link)(({ theme }) => ({
//   color: "#FFFFFF",
//   fontSize: "14px",
//   fontWeight: 400,
//   margin: "0 8px",
//   [theme.breakpoints.down("sm")]: {
//     display: "block",
//     margin: "8px 0",
//   },
// }));

// const Footer = () => {

//   const navigate = useNavigate();

//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   const handleTerms = () => {
//     navigate("/terms");
//   };

//   const handlePrivacy = () => {
//     navigate("/privacy");
//   };

//   const handleContact = () => {
//     navigate("/contact");
//   };

//   return (
//     <FooterContainer>
//       <TopSection
//         container
//         justifyContent="center"
//         alignItems="center"
//         direction="column"
//       >
//         <FooterText variant="h2" id="footer-main-title">
//           Get your Client Solutions built with Advoref
//         </FooterText>
//         <FooterButton variant="contained">Get Started</FooterButton>
//       </TopSection>
//       <BottomSection
//         container
//         direction={isMobile ? "column" : "row"}
//         spacing={2}
//       >
//         <Grid
//           item
//           xs={12}
//           md={6}
//           style={{ textAlign: isMobile ? "center" : "left" }}
//         >
//           <Typography variant="body2"sx={{ marginBottom:"10px"}}>
//             © Altum Infotech | All rights reserved. Altum Infotech Confidential.
//             For internal use only.
//           </Typography>
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           md={6}
//           style={{ textAlign: isMobile ? "center" : "right" }}
//         >
//           <FooterLink onClick={handleTerms} style={{ cursor: "pointer" }}>
//             Terms of Use
//           </FooterLink>
//           <FooterLink onClick={handlePrivacy} style={{ cursor: "pointer" }}>
//             Data Privacy Statement
//           </FooterLink>
//           <FooterLink onClick={handleContact} style={{ cursor: "pointer" }}>
//             Contact Us
//           </FooterLink>
//         </Grid>
//       </BottomSection>
//     </FooterContainer>
//   );
// };

// export default Footer;

import React, { useState } from 'react';
import {
  Grid,
  Button,
  Typography,
  Link,
  useMediaQuery,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import { Modal, Card, CardContent, IconButton } from '@mui/material';
import { Close as CloseIcon, CheckCircleOutline, StarOutline } from '@mui/icons-material';
import "./Footer.css";

// Footer for Landing page

const FooterContainer = styled("footer")({
  width: "100%",
});

const TopSection = styled(Grid)(({ theme }) => ({
  backgroundColor: "#EBEEF3",
  textAlign: "center",
  padding: "32px 16px",
  minHeight: "220px",
  [theme.breakpoints.down("sm")]: {
    padding: "24px 16px",
  },
}));

const BottomSection = styled(Grid)(({ theme }) => ({
  backgroundColor: "#444444",
  color: "#FFFFFF",
  padding: "16px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "nowrap", // Prevent wrapping
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column", // Stack on smaller screens
    alignItems: "center",
  },
}));

const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: "26px",
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    fontSize: "24px",
  },
}));

const FooterButton = styled(Button)({
  marginTop: "32px",
  width: "200px",
  borderRadius: "5px",
  fontSize: "18px",
  fontWeight: 500,
  background: "#FCFCFE",
  color: "black",
  "&:hover": {
    backgroundColor: "#FCFCFE",
    color: "black",
    boxShadow: "none",
  },
});

const FooterLink = styled(Link)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: "14px",
  fontWeight: 400,
  margin: "0 8px",
  display: "inline-block", // Ensure links are inline
  [theme.breakpoints.down("sm")]: {
    margin: "8px 0", // Stack links on smaller screens
  },
}));

const Footer = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleTerms = () => {
    navigate("/terms");
  };

  const handlePrivacy = () => {
    navigate("/privacy");
  };

  const handleContact = () => {
    navigate("/contact");
  };

  const handlePrice = () => {
    navigate("/pricing");
  };
  const handleRefund = () => {
    navigate("/cancellation");
  };
  const handleAbout = () => {
    navigate("/aboutus");
  };

  const footerLinkStyles = {
    cursor: "pointer",
    textDecoration: "underline",
    textUnderlineOffset: "3px",
    marginRight: "17px",
  };

  return (
    <FooterContainer>
      <TopSection
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <FooterText variant="h2" id="footer-main-title">
          Get your Client Solutions built with Advoref
        </FooterText>
        <FooterButton  onClick={handleOpen} sx={{ textTransform: "none" }} variant="contained">
          Get Started
        </FooterButton>

        <Box mt={2}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {/* Row for Phone Icon and Phone Number */}
            <Grid
              mx={2}
              item
              xs={12}
              sm="auto"
              container
              justifyContent="center"
              alignItems="center"
            >
              <PhoneIcon />
              <Typography ml={1}>+91-7875246300</Typography>
            </Grid>

            {/* Row for Mail Icon and Email */}
            <Grid
              mx={2}
              item
              xs={12}
              sm="auto"
              container
              justifyContent="center"
              alignItems="center"
            >
              <MailIcon />
              <Typography ml={1}>support@advoref.com</Typography>
            </Grid>
          </Grid>
        </Box>
      </TopSection>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '650px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: 5, right: 0 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              gap: 3,
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            {/* Left Card - Free Trial */}
            <Card
              sx={{
                flex: 1,
                bgcolor: '#f0f4f7',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CardContent>
                <CheckCircleOutline sx={{ fontSize: 40, color: '#00796b' }} />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Start with 15 Days Free Trial
                </Typography>
                <Typography variant="body2" sx={{ mt: 1, textAlign: 'center' }}>
                  Experience all the features with no cost for the first 15 days.
                </Typography>
              </CardContent>
              <Button variant="contained" color="primary" sx={{ mt: 2, mb: 2, textTransform:"none", }}>
                Start Free Trial
              </Button>
            </Card>

            {/* Right Card - Recommended Plan */}
            <Card
              sx={{
                flex: 1,
                bgcolor: '#f9fbe7',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CardContent>
                <StarOutline sx={{ fontSize: 40, color: '#f57f17' }} />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Buy our Recommended Plan
                </Typography>
                <Typography variant="body2" sx={{ mt: 1, textAlign: 'center' }}>
                  Get the best value for your money and start using Advoref today.
                </Typography>
              </CardContent>
              <Button variant="contained" color="secondary" sx={{ mt: 2, mb: 2, textTransform:"none" }}>
                Buy Plan
              </Button>
            </Card>
          </Box>
        </Box>
      </Modal>

      <BottomSection container spacing={2}>
  <Grid
    container
    item
    xs={12}
    md={6}
    direction="column"
    alignItems="flex-start"
  >
    <Typography variant="body2" sx={{ marginBottom: "10px" }}>
      © Altum Infotech | All rights reserved.
    </Typography>
    <Typography variant="body2">
      Altum Infotech Confidential. For internal use only.
    </Typography>
  </Grid>
  
  <Grid
    container
    item
    xs={12}
    md={6}
    direction="column"
    alignItems={isMobile ? "center" : "flex-end"}
  >
    {/* First Row of Links */}
    <Grid item sx={{ marginBottom: "5px" }}>
      <FooterLink onClick={handleTerms} sx={footerLinkStyles}>
        Terms of Use
      </FooterLink>
      <FooterLink onClick={handlePrivacy} sx={footerLinkStyles} style={{ marginLeft: "15px" }}>
        Privacy Policy
      </FooterLink>
      <FooterLink onClick={handleContact} sx={footerLinkStyles} style={{ marginLeft: "15px" }}>
        Contact Us
      </FooterLink>
    </Grid>
    
    {/* Second Row of Links */}
    <Grid item sx={{ marginTop: "10px" }}>
      <FooterLink onClick={handlePrice} sx={footerLinkStyles}>
        Pricing Policy
      </FooterLink>
      <FooterLink onClick={handleRefund} sx={footerLinkStyles} style={{ marginLeft: "15px" }}>
        Refund Policy
      </FooterLink>
      <FooterLink onClick={handleAbout} sx={footerLinkStyles} style={{ marginLeft: "15px", marginRight: "29px" }}>
        About Us
      </FooterLink>
    </Grid>
  </Grid>
</BottomSection>

    </FooterContainer>
  );
};

export default Footer;
